import React, { Component } from 'react';
import { connect } from 'react-redux'
import damacai from '../../Images/damacai.png';
import LiveLogo from '../../Images/Live.gif';
import { language } from '../../language';
import Moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { characterList } from '../../static'
import { filterJackpotNumber } from '../../utility/filterJackpotNumber';

class DamacaiCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const damacaiSpecial = [1,2,3,4,5,6,7,8,9,10]
    const damacaiconsolation = [14,15,16,17,18,19,20,21,22,23]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="damacaiborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={damacai} alt="Logo" className={`logomagnumimages ${isMobile && 'mobileCardLogo2'}`}/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white', position: 'relative' }}>
              <b>
                {language[this.props.currentLanguage].damacai} 4D
                {Moment().hours() >= 19 && <Link target="_blank" to={{ pathname: "http://livedraw.damacai.com.my/"}} >
                  <img src={LiveLogo} style={{ position: 'absolute', top: -25, right: -30, width: 60, height: 30}} alt="Live" />
                </Link>}
              </b>
            </div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.dmc.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10, color: 'white' }}>
          <div className="dcc damacaiborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].first}
          </div>
          <div className="dcc damacaiborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].second}
          </div>
          <div className="dcc damacaiborderclass" style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].third}
          </div>
        </div>
        <div className="numberborder" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none' }} to={{ pathname: "/FourDHistory", data: this.props.dmc.P1 || '----' }} >{this.props.dmc.P1 || '----'} </Link>
            <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none' }} to={{ pathname: "/FourDHistory", data: this.props.dmc.P2 || '----' }} >{this.props.dmc.P2 || '----'} </Link>
            <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none' }} to={{ pathname: "/FourDHistory", data: this.props.dmc.P3 || '----' }} >{this.props.dmc.P3 || '----'} </Link>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc damacaiborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].special}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              damacaiSpecial
                ? damacaiSpecial.map((item, index) => <Link key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}
                  to={{ pathname: "/FourDHistory", data: this.props.dmc[item] || '----' }} >
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                  {this.props.dmc[item] || '----' }
                </Link>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc damacaiborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].consolation}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              damacaiconsolation
                ? damacaiconsolation.map((item, index) => <Link key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}
                  to={{ pathname: "/FourDHistory", data: this.props.dmc[item] || '----' }} >
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                  {this.props.dmc[item] || '----' }
                </Link>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc damacaiborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].JEstimated}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 30, fontWeight: 'bold' }}>
          <div style={{ display: 'flex', marginBottom: 2 }}>
            <div style={{ width: 'calc(100% / 2)', borderradius: '5px' }}>{language[this.props.currentLanguage].Jackpot} 1</div>
            <div style={{ width: 'calc(100% / 1)', borderradius: '5px' }}>RM {filterJackpotNumber(this.props.dmc.jackpot1) || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 2)', borderradius: '5px' }}>{language[this.props.currentLanguage].Jackpot} 2</div>
            <div style={{ width: 'calc(100% / 1)', borderradius: '5px' }}>RM {filterJackpotNumber(this.props.dmc.jackpot2) || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 2)', borderradius: '5px' }}>{language[this.props.currentLanguage].Jackpot} 3</div>
            <div style={{ width: 'calc(100% / 1)', borderradius: '5px' }}>RM {filterJackpotNumber(this.props.dmc.jackpot3) || '----'}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(DamacaiCard)
