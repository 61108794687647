import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language';
import Banner from '../Images/Banner.png';
import Button from 'react-bootstrap/Button';

class SpecialDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textToCopy: `Special Draw 2024
FEB: 13,20
MAR: 5
MAY: 28
JUN: 25
AUG: 13
OCT: 29
DEC: 31
`,
    }
  }

  render() {
    return (
      <div className="App backgroundImage2" style={{ paddingTop: 25 }}>
        <div style={{ backgroundColor: '#003986', width: '90%', marginLeft: '5%', borderRadius: 10, color: 'white', fontSize: 24, marginTop: 20, marginBottom: 20 }}>{language[this.props.currentLanguage].specialDate}</div>
        <img src={Banner} alt="Modal1" style={{ width: '100%', height: '100%', paddingLeft: '10%', paddingRight: '10%' }} />
        <Button style={{ width: 100, margin: 20 }} onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}>
          {language[this.props.currentLanguage].copyText}
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(SpecialDate)
