import React from 'react';
import styled from 'styled-components';
import { BiPhone } from '@react-icons/all-files/bi/BiPhone';
import { RiWhatsappLine } from '@react-icons/all-files/ri/RiWhatsappLine';
import { MdLanguage } from '@react-icons/all-files/md/MdLanguage';
import { HiMenu } from '@react-icons/all-files/hi/HiMenu';
import { BiHome } from '@react-icons/all-files/bi/BiHome';
import { FiFlag } from '@react-icons/all-files/fi/FiFlag';
import { FaSearchDollar } from '@react-icons/all-files/fa/FaSearchDollar';
import { FaAward } from 'react-icons/fa';
import { BsBook } from '@react-icons/all-files/bs/BsBook';
import { AiOutlineMail } from '@react-icons/all-files/ai/AiOutlineMail';
import GooglePlay from '../Images/GooglePlay.png';
import AppStore from '../Images/AppStore2.png';
import { language } from '../language';
import { connect } from 'react-redux'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dmc1p3: {},
    }
  }

  render() {
    return (
      <FooterContainer className="main-footer" position= "fixed">
        <div className="footer-bottom">
          <div className="container" align="center">
            <div className="row">
              {/* Column 1 */}
              <div className="col-md-3 col-sm-6">
                <h4 align="left"><HiMenu size="30px" color="white"/> {language[this.props.currentLanguage].menu1} </h4>
                <ul className="list-unstyled" align="left">
                  <li><BiHome size="20px" color="white" /><a style={{ color: 'white' }} href="/MainPage" >&nbsp; {language[this.props.currentLanguage].menu}</a></li>
                  <li><FaAward size="20px" color="white" /><a style={{ color: 'white' }} href="/jackpot" >&nbsp; {language[this.props.currentLanguage].fDsD}</a></li>
                  <li><FaSearchDollar size="20px" color="white" /><a style={{ color: 'white' }} href="/PastResultClass" >&nbsp; {language[this.props.currentLanguage].pastResult}</a></li>
                  <li><BsBook size="20px" color="white" /><a style={{ color: 'white' }} href="/Dictionary" >&nbsp; 4D {language[this.props.currentLanguage].dictionary}</a></li>
                </ul>
              </div>
              { /* Column 2 */ }
              <div className="col-md-3 col-sm-6">
                <h4 align="left"><BiPhone size="35px" color="white"/>{language[this.props.currentLanguage].ContactUs}</h4>
                <ul className="list-unstyled" align="left">
                  <li><AiOutlineMail size="20px" color="white" /><a style={{ color: 'white' }} href="https://mail.google.com/" >&nbsp; ace4dv2@gmail.com</a></li>
                </ul>
              </div>
              { /* Column 3 */ }
              <div className="col-md-3 col-sm-6">
                <h4 align="left"><MdLanguage size="35px" color="white"/>{language[this.props.currentLanguage].language}</h4>
                <ul className="list-unstyled" align="left">
                  <li><a style={{ color: 'white' }} align-text="left" href="/" value="cn" >&nbsp; 中文</a></li>
                  <li><a style={{ color: 'white' }} href="/" value="en" >&nbsp; English</a></li>
                  <li><a style={{ color: 'white' }} href="/" value="Malay" >&nbsp; Malay</a></li>
                </ul>
              </div>
              { /* Column 3 */ }
              <div className="col-md-3 col-sm-6">
                <ul className="list-unstyled">
                  <li><a style={{ color: 'white' }} href="https://play.google.com/store/apps/details?id=com.ktlrsp" ><img src={GooglePlay} alt="Logo" className="Footerimages"/></a></li>
                </ul>
                <ul className="list-unstyled">
                  <li><a style={{ color: 'white' }} href="https://apps.apple.com/us/app/ace-4d-live-%E4%B8%87%E5%AD%97%E7%8E%B0%E5%9C%BA%E6%88%90%E7%BB%A9/id1561090489" ><img src={AppStore} alt="Logo" className="Footerimages"/></a></li>
                </ul>
              </div>
            </div>
            { /*  Footer Bottom */}
            <div className="footer-bottom">
              <p className="text-xs-center">
                    &copy;{new Date().getFullYear()} Copyright Ace 4D Live 2020
              </p>
            </div>
          </div>
        </div>
      </FooterContainer>
    );
  }
}

const FooterContainer = styled.footer`
 .footer-bottom {
     background: var(--mainDark);
     padding-top: 3rem;
     color: var(--mainWhite);
 }

 .footer-bottom {
     padding-top: 3rem;
     padding-bottom: 2rem;
     
 }

 ul li a {
     color: var(--mainGrey);
 }

 ul li a:hover {
     color: var(--mainLightGrey);
 }
`;

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)