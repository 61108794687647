import React from 'react';
import { connect } from 'react-redux';
import { language } from '../language/introductionTranslation';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import MagnumIcon from '../Images/Dictionary/Iconmagnum.png'
import DMCIcon from '../Images/Dictionary/Icondamacai.png'
import TOTOIcon from '../Images/Dictionary/IconSportToto.png'
import SGIcon from '../Images/Dictionary/Icon_SG.png'
import STCIcon from '../Images/Dictionary/Icon_stc.png'
import SBHIcon from '../Images/Dictionary/sabah88_Icon.png'
import SWKIcon from '../Images/Dictionary/cashsweep_IconWB.jpg'
import PlusIcon from '../Images/plus-square-solid.png'
import Bt4Icon from '../Images/Dictionary/btdg_small_icon.png';
import MinusIcon from '../Images/minus-square-solid.png'
import Table from 'react-bootstrap/Table'
import { isMobile } from 'react-device-detect';

class Introduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className="App backgroundImage2" style={{ paddingTop: 25 }}>
        <div style={{ backgroundColor: '#003986', width: '90%', marginLeft: '5%', borderRadius: 10, color: 'white', fontSize: 24, marginTop: 20, marginBottom: 20 }}>{language[this.props.currentLanguage].introduction}</div>
        <div className={`${isMobile && 'mobileIntroductionCard'}`} style={{ width: '80%', marginLeft: '10%', paddingBottom: 20 }}>
          <Accordion>
            <Card style={{ border: 'none', marginBottom: 20, borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={MagnumIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].magnum}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D2}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D3}</p>
                      <p>{language[this.props.currentLanguage].introduction4D4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 105</td>
                            <td>RM 209</td>
                            <td>RM 417</td>
                            <td>RM 625</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 21</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 125</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 8</td>
                            <td>RM 15</td>
                            <td>RM 30</td>
                            <td>RM 45</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 3</td>
                            <td>RM 5</td>
                            <td>RM 10</td>
                            <td>RM 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 146</td>
                            <td>RM 292</td>
                            <td>RM 584</td>
                            <td>RM 875</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 334</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D5}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D6}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D7}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D8}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D9}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D10}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].Prizes}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP1}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP1W}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionJPCP1}<br/>
                              {language[this.props.currentLanguage].introductionJPCP2}<br/>
                              {language[this.props.currentLanguage].introductionJPCP3}
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP2}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP2W}</td>
                            <td style={{ textAlign: 'left' }}>
                              <p>{language[this.props.currentLanguage].introductionJP2CP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG3}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionJP3W}
                              {language[this.props.currentLanguage].introductionJP3W2}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              RM168
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG4}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP4W}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM68
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG5}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP5W}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM28
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D11}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D12}</p>
                      <p>{language[this.props.currentLanguage].introduction4D13}</p>
                      <Table className={`${isMobile && 'mobileIntroductionFullCard mobileIntroductionSmallerText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introduction4D14}</th>
                            <th>{language[this.props.currentLanguage].introduction4D15}</th>
                            <th>{language[this.props.currentLanguage].introduction4D16}</th>
                            <th>{language[this.props.currentLanguage].introduction4D17}</th>
                            <th>{language[this.props.currentLanguage].introduction4D18}</th>
                            <th>{language[this.props.currentLanguage].introduction4D19}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>M-10</td>
                            <th>{language[this.props.currentLanguage].introduction4D20}</th>
                            <th>{language[this.props.currentLanguage].introduction4D21}</th>
                            <th>RM168</th>
                            <th>RM68</th>
                            <th>RM28</th>
                          </tr>
                          <tr>
                            <td>M-20</td>
                            <th>{language[this.props.currentLanguage].introduction4D22}</th>
                            <th>{language[this.props.currentLanguage].introduction4D23}</th>
                            <th>RM168</th>
                            <th>RM68</th>
                            <th>RM28</th>
                          </tr>
                          <tr>
                            <td>M-30</td>
                            <th>{language[this.props.currentLanguage].introduction4D24}</th>
                            <th>{language[this.props.currentLanguage].introduction4D25}</th>
                            <th>RM168</th>
                            <th>RM68</th>
                            <th>RM28</th>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D26}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D27}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D28}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D29}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D30}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D31}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D32}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].Prizes}</th>
                            <th>{language[this.props.currentLanguage].introduction4Dpatern}</th>
                            <th>{language[this.props.currentLanguage].AmountWon}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D33}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 1
                              <p>{language[this.props.currentLanguage].introductionMin2m}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introduction4D34}<br/>
                              {language[this.props.currentLanguage].introduction4D35}<br/>
                              {language[this.props.currentLanguage].introduction4D36}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 2
                              <p>{language[this.props.currentLanguage].introductionMin100k}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D37}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 100,000
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D38}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 3,388
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D39}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 338
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].sixth}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D40}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 38
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].seventh}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D41}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 4
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D42}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D43}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D44}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D45}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D46}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D47}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].Prizes}</th>
                            <th>{language[this.props.currentLanguage].introduction4Dpatern}</th>
                            <th>{language[this.props.currentLanguage].AmountWon}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].jackpot} 1 ({language[this.props.currentLanguage].first2})</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introduction4D48}<br/>
                              {language[this.props.currentLanguage].introduction4D49}<br/>
                              {language[this.props.currentLanguage].introduction4D50}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 1 - 100%
                              <p>{language[this.props.currentLanguage].introduction4DLowest2m}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].jackpot} 1 ({language[this.props.currentLanguage].second})</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introduction4D53}<br/>
                              {language[this.props.currentLanguage].introduction4D54}<br/>
                              {language[this.props.currentLanguage].introduction4D55}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 1 - 50%
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].jackpot} 1 ({language[this.props.currentLanguage].third})</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introduction4D56}<br/>
                              {language[this.props.currentLanguage].introduction4D57}<br/>
                              {language[this.props.currentLanguage].introduction4D58}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 1 - 25%
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].jackpot} 2</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introduction4D59}<br/>
                              {language[this.props.currentLanguage].introduction4D60}<br/>
                              {language[this.props.currentLanguage].introduction4D61}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 2
                              <p>{language[this.props.currentLanguage].introduction4DLowest100k}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D62}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 100
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D63}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 20
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introduction4D64}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM 7
                              <p>{language[this.props.currentLanguage].introductionEvery2}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', marginBottom: 20, borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4', borderRadius: 20 }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={DMCIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].damacai}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC1}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionDMC2}</p>
                      <p>{language[this.props.currentLanguage].introductionDMC3}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>ABC</th>
                            <th>A</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 250</td>
                            <td>RM 660</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 210</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 150</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC4}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC5}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC6}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC7}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC8}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC9}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC10}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D3}</p>
                      <p>{language[this.props.currentLanguage].introduction4D4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 105</td>
                            <td>RM 209</td>
                            <td>RM 417</td>
                            <td>RM 625</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 21</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 125</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 8</td>
                            <td>RM 15</td>
                            <td>RM 30</td>
                            <td>RM 45</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 3</td>
                            <td>RM 5</td>
                            <td>RM 10</td>
                            <td>RM 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 146</td>
                            <td>RM 292</td>
                            <td>RM 584</td>
                            <td>RM 875</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 334</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC23}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC11}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC12}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC13}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC14}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC15}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1ST</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC16}</td>
                            <td>RM 6500</td>
                          </tr>
                          <tr>
                            <td>2ND</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC17}</td>
                            <td>RM 6500</td>
                          </tr>
                          <tr>
                            <td>3RD</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC18}</td>
                            <td>RM 6500</td>
                          </tr>
                          <tr>
                            <td>STA</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC19}</td>
                            <td>RM 650</td>
                          </tr>
                          <tr>
                            <td>CON</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC20}</td>
                            <td>RM 650</td>
                          </tr>
                          <tr>
                            <td>TP3</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC21}</td>
                            <td>RM 2168</td>
                          </tr>
                          <tr>
                            <td>ALL</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC22}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC24}<br/>
                              {language[this.props.currentLanguage].special} - RM130<br/>
                              {language[this.props.currentLanguage].consolation} - RM130
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC25}
                    </div>
                    <div className="introductionCardBody">
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1ST</td>
                            <td>RM 271</td>
                            <td>RM 542</td>
                            <td>RM 1084</td>
                            <td>RM 1625</td>
                          </tr>
                          <tr>
                            <td>2ND</td>
                            <td>RM 271</td>
                            <td>RM 542</td>
                            <td>RM 1084</td>
                            <td>RM 1625</td>
                          </tr>
                          <tr>
                            <td>3RD</td>
                            <td>RM 271</td>
                            <td>RM 542</td>
                            <td>RM 1084</td>
                            <td>RM 1625</td>
                          </tr>
                          <tr>
                            <td>STA/CON</td>
                            <td>RM 28</td>
                            <td>RM 55</td>
                            <td>RM 109</td>
                            <td>RM 163</td>
                          </tr>
                          <tr>
                            <td>STA</td>
                            <td>RM 91</td>
                            <td>RM 181</td>
                            <td>RM 362</td>
                            <td>RM 542</td>
                          </tr>
                          <tr>
                            <td>ALL (1ST, 2ND, 3RD)</td>
                            <td>RM 55</td>
                            <td>RM 109</td>
                            <td>RM 217</td>
                            <td>RM 325</td>
                          </tr>
                          <tr>
                            <td>ALL ({language[this.props.currentLanguage].special}/{language[this.props.currentLanguage].consolation})</td>
                            <td>RM 6</td>
                            <td>RM 11</td>
                            <td>RM 22</td>
                            <td>RM 33</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC26}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D6}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D7}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D8}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D9}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D10}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].Prizes}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP1}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP1W}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionJPCP1}<br/>
                              {language[this.props.currentLanguage].introductionJPCP2}<br/>
                              {language[this.props.currentLanguage].introductionJPCP3}
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP2}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP2W}</td>
                            <td style={{ textAlign: 'left' }}>
                              <p>{language[this.props.currentLanguage].introductionJP2CP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG3}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionJP3W}<br/>
                              {language[this.props.currentLanguage].introductionJP3W2}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              RM168
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG4}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP3W}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM68
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG5}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP3W}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM28
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC27}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC28}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC29}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC30}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].Prizes}</th>
                            <th>{language[this.props.currentLanguage].introductionDMC31}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionDMCHighestCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP1}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC33}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 1 {language[this.props.currentLanguage].introduction4DLowest18m}<br/>
                            </td>
                            <td>{language[this.props.currentLanguage].introductionDMCNoLimit}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP2}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC34}<br/>
                              {language[this.props.currentLanguage].introductionDMC35}<br/>
                              {language[this.props.currentLanguage].introductionDMC36}<br/>
                              {language[this.props.currentLanguage].introductionDMC37}<br/>
                              {language[this.props.currentLanguage].introductionDMC38}<br/>
                              {language[this.props.currentLanguage].introductionDMC39}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 2 {language[this.props.currentLanguage].introduction4DLowest100k}<br/>
                            </td>
                            <td>{language[this.props.currentLanguage].introductionDMCNoLimit}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC40}
                            </td>
                            <td style={{ textAlign: 'left' }}>RM 250</td>
                            <td>RM 10,000,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC41}
                            </td>
                            <td style={{ textAlign: 'left' }}>RM 150</td>
                            <td>RM 10,000,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC42}
                            </td>
                            <td style={{ textAlign: 'left' }}>RM 50</td>
                            <td>RM 10,000,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].sixth}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC43}
                            </td>
                            <td style={{ textAlign: 'left' }}>RM 20</td>
                            <td>RM 10,000,000</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC44}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC45}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC46}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introductionDMC47}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].Prizes}</th>
                            <th>{language[this.props.currentLanguage].introductionDMC31}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionDMCHighestCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP1}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionDMC48}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].jackpot} 1 {language[this.props.currentLanguage].introduction4DLowest600k}<br/>
                            </td>
                            <td>{language[this.props.currentLanguage].introductionDMCNoLimit}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC49}
                            </td>
                            <td style={{ textAlign: 'left' }}>RM 500</td>
                            <td>RM 5,000,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionDMC50}
                            </td>
                            <td style={{ textAlign: 'left' }}>RM 3</td>
                            <td>RM 5,000,000</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>

                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', marginBottom: 20, borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4', borderRadius: 20 }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={TOTOIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].toto}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D2}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D3}</p>
                      <p>{language[this.props.currentLanguage].introduction4D4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 105</td>
                            <td>RM 209</td>
                            <td>RM 417</td>
                            <td>RM 625</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 21</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 125</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 8</td>
                            <td>RM 15</td>
                            <td>RM 30</td>
                            <td>RM 45</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 3</td>
                            <td>RM 5</td>
                            <td>RM 10</td>
                            <td>RM 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 146</td>
                            <td>RM 292</td>
                            <td>RM 584</td>
                            <td>RM 875</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 334</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D5}
                    </div>
                    <div className="introductionCardBody">
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D6}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D7}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D8}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D9}</p>
                      <p style={{ textAlign: 'left', marginLeft: '5%' }}>{language[this.props.currentLanguage].introduction4D10}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP1}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP1W}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionJPCP1}<br/>
                              {language[this.props.currentLanguage].introductionJPCP2}<br/>
                              {language[this.props.currentLanguage].introductionJPCP3}
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionJP2}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP2W}</td>
                            <td style={{ textAlign: 'left' }}>
                              <p>{language[this.props.currentLanguage].introductionJP2CP}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG3}</td>
                            <td style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionJP3W}<br/>
                              {language[this.props.currentLanguage].introductionJP3W2}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              RM168
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG4}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP3W}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM68
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].introductionG5}</td>
                            <td style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionJP3W}</td>
                            <td style={{ textAlign: 'left' }}>
                              RM28
                              <p>{language[this.props.currentLanguage].introductionJPCPLimit}</p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionTOTO1}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionTOTO2}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO3}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO4}</td>
                            <td>RM 15,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO5}</td>
                            <td>RM 5,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO6}</td>
                            <td>RM 3,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO7}</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO8}</td>
                            <td>RM 20</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].sixth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO9}</td>
                            <td>RM 5</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionTOTO10}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionTOTO11}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionTOTO6D2}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO3}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO12}</td>
                            <td>RM 100,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO13}</td>
                            <td>RM 3,000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO14}</td>
                            <td>RM 300</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO15}</td>
                            <td>RM 30</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO16}</td>
                            <td>RM 4</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionTOTO10}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionTOTO17}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionTOTO18}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO19}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO20}</td>
                            <td>{language[this.props.currentLanguage].jackpot} 1 {language[this.props.currentLanguage].introduction4DLowest1188k}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO21}</td>
                            <td>{language[this.props.currentLanguage].jackpot} 2 {language[this.props.currentLanguage].introductionMin100k}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO22}</td>
                            <td>RM 2,008</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO23}</td>
                            <td>RM 788</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO24}</td>
                            <td>RM 28</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].sixth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO25}</td>
                            <td>RM 18</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].seventh}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO26}</td>
                            <td>RM 8</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionTOTO27}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionTOTO28}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionTOTO29}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO30}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO31}</td>
                            <td>{language[this.props.currentLanguage].jackpot} {language[this.props.currentLanguage].introduction4DLowest3m}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO32}</td>
                            <td>RM 3,300</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO33}</td>
                            <td>RM 40</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO34}</td>
                            <td>RM 3</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionTOTO35}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionTOTO36}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionTOTO37}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO38}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO31}</td>
                            <td>{language[this.props.currentLanguage].jackpot} {language[this.props.currentLanguage].introduction4DLowest888k}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO32}</td>
                            <td>RM 6,888</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO33}</td>
                            <td>RM 80</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO34}</td>
                            <td>RM 8</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionTOTO35}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', marginBottom: 20, borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4', borderRadius: 20 }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={SGIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].singapore}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionSG1}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>SGD 2000</td>
                            <td>SGD 3000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>SGD 1000</td>
                            <td>SGD 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>SGD 490</td>
                            <td>SGD 800</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>SGD 250</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>SGD 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionSG2}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionSG3}</p>
                      <p>{language[this.props.currentLanguage].introductionSG4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSG5}</th>
                            <th>{language[this.props.currentLanguage].introductionSG6}</th>
                            <th>{language[this.props.currentLanguage].introductionSG7}</th>
                            <th>{language[this.props.currentLanguage].introductionSG8}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>SGD 83</td>
                            <td>SGD 166</td>
                            <td>SGD 335</td>
                            <td>SGD 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>SGD 41</td>
                            <td>SGD 83</td>
                            <td>SGD 168</td>
                            <td>SGD 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>SGD 20</td>
                            <td>SGD 40</td>
                            <td>SGD 85</td>
                            <td>SGD 127</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>SGD 10</td>
                            <td>SGD 20</td>
                            <td>SGD 41</td>
                            <td>SGD 62</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>SGD 3</td>
                            <td>SGD 6</td>
                            <td>SGD 10</td>
                            <td>SGD 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionSG5}</th>
                            <th>{language[this.props.currentLanguage].introductionSG6}</th>
                            <th>{language[this.props.currentLanguage].introductionSG7}</th>
                            <th>{language[this.props.currentLanguage].introductionSG8}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>SGD 125</td>
                            <td>SGD 250</td>
                            <td>SGD 500</td>
                            <td>SGD 750</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>SGD 83</td>
                            <td>SGD 167</td>
                            <td>SGD 333</td>
                            <td>SGD 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>SGD 33</td>
                            <td>SGD 66</td>
                            <td>SGD 133</td>
                            <td>SGD 200</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionSG9}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionSG10}</p>
                      <p>{language[this.props.currentLanguage].introductionSG11}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionSG12}</td>
                            <td>{language[this.props.currentLanguage].introductionSG19}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionSG13}</td>
                            <td>{language[this.props.currentLanguage].introductionSG20}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionSG14}</td>
                            <td>{language[this.props.currentLanguage].introductionSG21}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionSG15}</td>
                            <td>{language[this.props.currentLanguage].introductionSG22}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td>{language[this.props.currentLanguage].introductionSG16}</td>
                            <td>SGD 50</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].sixth}</td>
                            <td>{language[this.props.currentLanguage].introductionSG17}</td>
                            <td>SGD 25</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].seventh}</td>
                            <td>{language[this.props.currentLanguage].introductionSG18}</td>
                            <td>SGD 10</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>
                              {language[this.props.currentLanguage].introductionSG23}<br/>
                              {language[this.props.currentLanguage].introductionSG24}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', borderRadius: 20, marginBottom: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="7" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={Bt4Icon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].america} BT4</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D2}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D3}</p>
                      <p>{language[this.props.currentLanguage].introduction4D4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 105</td>
                            <td>RM 209</td>
                            <td>RM 417</td>
                            <td>RM 625</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 21</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 125</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 8</td>
                            <td>RM 15</td>
                            <td>RM 30</td>
                            <td>RM 45</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 3</td>
                            <td>RM 5</td>
                            <td>RM 10</td>
                            <td>RM 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 146</td>
                            <td>RM 292</td>
                            <td>RM 584</td>
                            <td>RM 875</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 334</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', marginBottom: 20, borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4', borderRadius: 20 }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="4" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={STCIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].sandakan}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D2}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D3}</p>
                      <p>{language[this.props.currentLanguage].introduction4D4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 105</td>
                            <td>RM 209</td>
                            <td>RM 417</td>
                            <td>RM 625</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 21</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 125</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 8</td>
                            <td>RM 15</td>
                            <td>RM 30</td>
                            <td>RM 45</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 3</td>
                            <td>RM 5</td>
                            <td>RM 10</td>
                            <td>RM 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 146</td>
                            <td>RM 292</td>
                            <td>RM 584</td>
                            <td>RM 875</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 334</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', marginBottom: 20, borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4', borderRadius: 20 }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="5" style={{ width: '100%', textAlign: 'left'}}>
                  <span style={{ width: 200 }}>
                    <img src={SBHIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].sabah}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionDMC1}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionDMC2}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO19}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 210</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 150</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D2}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D3}</p>
                      <p>{language[this.props.currentLanguage].introduction4D4}</p>
                      <Table className={`${isMobile && 'mobileIntroductionText'}`} striped bordered hover style={{ width: '90%', marginLeft: '5%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 105</td>
                            <td>RM 209</td>
                            <td>RM 417</td>
                            <td>RM 625</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 21</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 125</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 8</td>
                            <td>RM 15</td>
                            <td>RM 30</td>
                            <td>RM 45</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 3</td>
                            <td>RM 5</td>
                            <td>RM 10</td>
                            <td>RM 15</td>
                          </tr>
                        </tbody>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                            <th>{language[this.props.currentLanguage].introductionQB24}</th>
                            <th>{language[this.props.currentLanguage].introductionQB12}</th>
                            <th>{language[this.props.currentLanguage].introductionQB6}</th>
                            <th>{language[this.props.currentLanguage].introductionQB4}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 146</td>
                            <td>RM 292</td>
                            <td>RM 584</td>
                            <td>RM 875</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 334</td>
                            <td>RM 500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 42</td>
                            <td>RM 84</td>
                            <td>RM 167</td>
                            <td>RM 250</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introductionSBH1}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introductionSBH2}</p>
                      <p>{language[this.props.currentLanguage].introductionTOTO19}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionWinCondition}</th>
                            <th>{language[this.props.currentLanguage].introductionCashPrize}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO20}</td>
                            <td>{language[this.props.currentLanguage].jackpot} 1 {language[this.props.currentLanguage].introduction4DLowest500k}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO21}</td>
                            <td>{language[this.props.currentLanguage].jackpot} 2 {language[this.props.currentLanguage].introduction4DLowest5k}</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO22}</td>
                            <td>RM 1,400</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fourth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO23}</td>
                            <td>RM 250</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].fifth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO24}</td>
                            <td>RM 19</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].sixth}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO25}</td>
                            <td>RM 5</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].seventh}</td>
                            <td>{language[this.props.currentLanguage].introductionTOTO26}</td>
                            <td>RM 2</td>
                          </tr>
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'left' }}>{language[this.props.currentLanguage].introductionTOTO27}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card style={{ border: 'none', borderRadius: 20 }}>
              <Card.Header style={{ backgroundColor: '#3C96F4', borderRadius: 20 }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="6" style={{ width: '100%', textAlign: 'left'}}>
                  <span>
                    <img src={SWKIcon} alt="Logo" className="Diclogoimages"/>
                    <span style={{ marginLeft: 10, color: 'white' }}>{language[this.props.currentLanguage].sarawak}</span>
                  </span>
                  <img src={PlusIcon} alt="Logo" className="Diclogoimages" style={{ position: 'absolute', right: 20}}/>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <div className="introductionCard">
                    <div className="introductionCardHeader">
                      {language[this.props.currentLanguage].introduction4D}
                    </div>
                    <div className="introductionCardBody">
                      <p>{language[this.props.currentLanguage].introduction4D1}</p>
                      <Table className={`${isMobile && 'mobileIntroductionCard mobileIntroductionText'}`} striped bordered hover style={{ width: '60%', marginLeft: '20%', marginTop: 10, backgroundColor: 'white' }}>
                        <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                          <tr>
                            <th>{language[this.props.currentLanguage].introductionPrize}</th>
                            <th>{language[this.props.currentLanguage].introductionBig}</th>
                            <th>{language[this.props.currentLanguage].introductionSmall}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{language[this.props.currentLanguage].first2}</td>
                            <td>RM 2500</td>
                            <td>RM 3500</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].second}</td>
                            <td>RM 1000</td>
                            <td>RM 2000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].third}</td>
                            <td>RM 500</td>
                            <td>RM 1000</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].special}</td>
                            <td>RM 180</td>
                            <td>---</td>
                          </tr>
                          <tr>
                            <td>{language[this.props.currentLanguage].consolation}</td>
                            <td>RM 60</td>
                            <td>---</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(Introduction)
