import '../App.css';
import React from 'react';
import Moment from 'moment';
import MagnumIcon from '../Images/Dictionary/Iconmagnum.png';
import DamacaiIcon from '../Images/Dictionary/Icondamacai.png';
import TotoIcon from '../Images/Dictionary/IconSportToto.png';
import Sg4Icon from '../Images/Dictionary/Icon_SG.png';
import Bt4Icon from '../Images/Dictionary/btdg_small_icon.png';
import GdIcon from '../Images/Dictionary/GD_Small_Icon.png';
import SDKIcon from '../Images/Dictionary/Icon_stc.png';
import Sabah88Icon from '../Images/Dictionary/sabah88_Icon.png';
import SwkIcon from '../Images/icon_cashsweep5.png';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSistrix } from '@react-icons/all-files/fa/FaSistrix';
import { BiBarChart } from '@react-icons/all-files/bi/BiBarChart';
import { connect } from 'react-redux'
import { language } from '../language';
import Checkbox from '@material-ui/core/Checkbox';
import Table from 'react-bootstrap/Table'
import { getByStatistic, getByKeyword } from '../api'
import { isMobile } from 'react-device-detect';

class FourDHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      showStraight: false,
      showPermutation: false,
      getNumber: '',
      getPermutation: false,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showGD: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      result: [],
      errorM: '',
      showResult: false,
      straightResult: [],
      permutationsResult: [],
      winResult: {},
    }
    this.handleChange = this.handleChange.bind(this);
    this.getStatistic = this.getStatistic.bind(this);
    this.filterPlatform = this.filterPlatform.bind(this);
    this.calculateWinResult = this.calculateWinResult.bind(this);
    this.renderLogo = this.renderLogo.bind(this);
    this.renderDictionaryImage = this.renderDictionaryImage.bind(this);
  }

  async getStatistic() {
    if (this.state.number.toString().length < 4 || this.state.number.toString().length > 4 || this.state.number.toString() == "" || this.state.number.toString().length < 0) {
      this.state.errorM = <div className="dcc numberborderPastforerror" style={{ marginBottom: 30, marginLeft: 50, marginRight: 50 }}>{language[this.props.currentLanguage].ErrorOnly4number}</div>;
      this.setState({ showStraight: false })
      this.setState({ showPermutation: false })
      this.setState({ result: {} })
    // } else if (this.state.number.toString().pattern != regexp) {
    //   console.log('only allow number!!')
    } else {
      this.state.errorM = "";
      let result = await getByKeyword(this.state.number)
      if (result.length > 0) {
        result = this.filterPlatform(result)
        this.setState({ result })
        this.setState({ showResult: true })
      } else {
        // this.state.errorM = "No Records";
        this.setState({ result: [] })
        this.setState({ showResult: false })
      }
      const data = await getByStatistic(this.state.number, this.state.getPermutation)
      this.setState({ showStraight: true })
      let straightResult = []
      data[this.state.number].forEach((item) => {
        straightResult.push({
          platform: item.platform,
          number: this.state.number,
          position: item.position,
          date: item.date,
        })
      })
      straightResult = this.filterPlatform(straightResult)
      this.calculateWinResult(straightResult)
      straightResult = straightResult.sort((a, b) => b.date - a.date)
      this.setState({ straightResult })

      if (this.state.getPermutation) {
        this.setState({ showPermutation: true })
        let permutationsResult = []
        Object.keys(data).map((number, index) => {
          if (number === this.state.number) {
            return
          }
          data[number].forEach((item) => {
            permutationsResult.push({
              platform: item.platform,
              number: number,
              position: item.position,
              date: item.date,
            })
          })
        })
        permutationsResult = this.filterPlatform(permutationsResult)
        permutationsResult = permutationsResult.sort((a, b) => b.date - a.date)
        console.log(permutationsResult)
        this.setState({ permutationsResult })
      } else {
        this.setState({ showPermutation: false })
      }
    }
  }

  filterPlatform(data) {
    let dataCopy = data
    if (!this.state.showMAG) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'mag')
    }
    if (!this.state.showDMC) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'dmc')
    }
    if (!this.state.showTOTO) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'toto')
    }
    if (!this.state.showSG) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'sg4')
    }
    if (!this.state.showBT4) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'bt4')
    }
    if (!this.state.showGD) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'gd')
    }
    if (!this.state.showSTC) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'sdk' && item.platform !== 'stc')
    }
    if (!this.state.showSBH) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'sbh')
    }
    if (!this.state.showSWK) {
      dataCopy = dataCopy.filter((item) => item.platform !== 'swk')
    }
    return dataCopy
  }

  calculateWinResult(data) {
    const winResult = {}
    for(let i = 0; i < data.length; i += 1) {
      if (!winResult[data[i].platform]) {
        winResult[data[i].platform] = 0
      }
      if (!winResult[data[i].position]) {
        winResult[data[i].position] = 0
      }
      winResult[data[i].platform] += 1
      winResult[data[i].position] += 1
    }
    this.setState({ winResult })
  }

  renderLogo(platform) {
    if (platform === 'mag') {
      return <img src={MagnumIcon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'dmc') {
      return <img src={DamacaiIcon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'toto') {
      return <img src={TotoIcon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'sg4') {
      return <img src={Sg4Icon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'bt4') {
      return <img src={Bt4Icon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'gd') {
      return <img src={GdIcon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'sdk') {
      return <img src={SDKIcon} alt="Logo" className="Diclogoimages"/>
    } else if (platform === 'sbh') {
      return <img src={Sabah88Icon} alt="Logo" className="Diclogoimages"/>
    } else {
      return <img src={SwkIcon} alt="Logo" className="Diclogoimages"/>
    } 
  }

  handleChange(value, field) {
    this.setState({[field]: value});

    // if (this.state.regexp.test(value)) {
    //   this.setState({ [field]: value })
    // }
  }

  componentDidMount() {
    const historyPlatformCache = localStorage.getItem('historyPlatform')
    if (historyPlatformCache) {
      const historyPlatformCacheObject = JSON.parse(historyPlatformCache)
      this.setState({
        showDMC: historyPlatformCacheObject.includes('showDMC'),
        showMAG: historyPlatformCacheObject.includes('showMAG'),
        showTOTO: historyPlatformCacheObject.includes('showTOTO'),
        showSG: historyPlatformCacheObject.includes('showSG'),
        showBT4: historyPlatformCacheObject.includes('showBT4'),
        showGD: historyPlatformCacheObject.includes('showGD'),
        showSWK: historyPlatformCacheObject.includes('showSWK'),
        showSBH: historyPlatformCacheObject.includes('showSBH'),
        showSTC: historyPlatformCacheObject.includes('showSTC'),
      });
    } else {
      localStorage.setItem('historyPlatform', JSON.stringify([
        'showDMC',
        'showMAG',
        'showTOTO',
        'showSG',
        'showBT4',
        'showGD',
        'showSWK',
        'showSBH',
        'showSTC',
      ]));
    }
    const { data } = this.props.location
    if (data && data !== '----') {
      this.setState({ number: data}, () => {
        this.getStatistic()
      })
    }
  }

  // testing dictionary
  renderDictionaryImage(imageName) {
    return <img src={`/Image/${imageName}`} alt="Logo" className="Hislogoimages"/>
  }

  renderClass(platform) {
    if (platform === 'magnum') {
      return 'Dicmagnumborder'
    } else if (platform === 'damacai') {
      return 'Dicdamacaiborder'
    } else if (platform === 'toto') {
      return 'Dictotoborder'
    } 
  }

  renderLogo2(platform) {
    if (platform === 'magnum') {
      return <img src={MagnumIcon} alt="Logo" className="Diclogoimages1"/>
    } else if (platform === 'damacai') {
      return <img src={DamacaiIcon} alt="Logo" className="Diclogoimages1"/>
    } else if (platform === 'toto') {
      return <img src={TotoIcon} alt="Logo" className="Diclogoimages1"/>
    } 
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("searchButton").click()
    }
  }

  async alterCheckbox(platform) {
    this.setState({
      [platform]: !this.state[platform],
    });
    const historyPlatformCache = localStorage.getItem('historyPlatform') || [];
    const historyPlatformCacheObject = JSON.parse(historyPlatformCache)
    if (historyPlatformCacheObject.includes(platform)) {
      const index = historyPlatformCacheObject.indexOf(platform);
      historyPlatformCacheObject.splice(index, 1);
      localStorage.setItem('historyPlatform', JSON.stringify(historyPlatformCacheObject));
    } else {
      historyPlatformCacheObject.push(platform);
      localStorage.setItem('historyPlatform', JSON.stringify(historyPlatformCacheObject));
    }
  }

  render() {
    // const currentDateFormat = Moment(this.state.date).format('YYYYMMDD')
    const EMessage = this.state.errorM;
    return (
      <div className="App backgroundImage4" style={{ paddingTop: 25 }}>
        <div className="row" style={{ margin: '20px 50px', marginTop: 0 }}>
          <div className = "dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white', marginTop: 20, fontSize: '20px' }}>
            <BiBarChart size="28px" color="white" className="logohome" /> {language[this.props.currentLanguage].fourDhistory}
          </div>
        </div>
        <div className={`numberborderPast ${isMobile && `numberborderPastMobile`}`} style={{ marginBottom: 30, fontWeight: 'bold', marginLeft: '5%', marginRight: '5%' }}>
          <div className="dcc" style={{ display: 'flex', padding: '8px', fontWeight: 'bold', width: '40%', justifyContent: 'center' }}>
            <div>
              <div className="dcc" style={{ width: '100%' }}>
                <input 
                  // className="ScreenAdjust"
                  style={{ textAlign: 'center' }} 
                  value={this.state.number}
                  type="number"
                  onChange={(e) => this.handleChange(e.target.value, 'number')}
                  placeholder={'1234'} 
                  onKeyPress={(e) => this.onKeyEnter(e)}
                />
                <button className="dcc DictionaryButtonborder" id="searchButton" onClick={this.getStatistic}><FaSistrix size="20px" color="black"  /></button>
              </div>
              <div className="dcc" style={{ width: '100%', marginBottom: 5 }}>
                <div>{language[this.props.currentLanguage].pemutation} : 
                  <Checkbox
                    inputProps={{ 'aria-label': 'primary checkbox'}}
                    value={this.state.getPermutation} 
                    onChange={(e) => this.handleChange(e.target.checked, 'getPermutation')}
                    color={ 'primary' }
                  />
                  mBox, iBox, i-Perm
                </div>
              </div>
              <div className={`${isMobile && 'col mobileHeader'}`} style={{ display: 'flex', padding: '8px', fontWeight: 'bold', width: '100%' }}>
                <div style={{ marginBottom: 10 }}>{language[this.props.currentLanguage].pemutation} ：</div>
                <div>
                  <div className={`dcc mobileHeaderMo1 ${isMobile && 'mobileHeaderMo'}`} style={{ width: '100%', marginBottom: 10 }}>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox'}}
                      color={ 'primary' }
                      checked={this.state.showMAG} 
                      onChange={(e) => this.alterCheckbox('showMAG')}
                    />
                    <img src={MagnumIcon} alt="Logo" className="Diclogoimages"/>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color={ 'primary' }
                      checked={this.state.showDMC} 
                      onChange={(e) => this.alterCheckbox('showDMC')}
                    />
                    <img src={DamacaiIcon} alt="Logo" className="Diclogoimages"/>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color={ 'primary' }
                      checked={this.state.showTOTO} 
                      onChange={(e) => this.alterCheckbox('showTOTO')}
                    />
                    <img src={TotoIcon} alt="Logo" className="Diclogoimages"/>
                  </div>
                  <div className="dcc" style={{ width: '100%', marginBottom: 10 }}>
                    <div className="dcc">
                      <Checkbox
                        inputProps={{ 'aria-label': 'primary checkbox'}}
                        color={ 'primary' }
                        checked={this.state.showSG}
                        onChange={(e) => this.alterCheckbox('showSG')}
                      />
                      <img src={Sg4Icon} alt="Logo" className="Diclogoimages"/>
                      <Checkbox
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color={ 'primary' }
                        checked={this.state.showBT4}
                        onChange={(e) => this.alterCheckbox('showBT4')}
                      />
                      <img src={Bt4Icon} alt="Logo" className="Diclogoimages"/>
                      <Checkbox
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        color={ 'primary' }
                        checked={this.state.showGD}
                        onChange={(e) => this.alterCheckbox('showGD')}
                      />
                      <img src={GdIcon} alt="Logo" className="Diclogoimages"/>
                    </div>
                  </div>
                  <div className="dcc" style={{ width: '100%' }}>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox'}}
                      color={ 'primary' }
                      checked={this.state.showSTC}
                      onChange={(e) => this.alterCheckbox('showSTC')}
                    />
                    <img src={SDKIcon} alt="Logo" className="Diclogoimages"/>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color={ 'primary' }
                      checked={this.state.showSBH}
                      onChange={(e) => this.alterCheckbox('showSBH')}
                    />
                    <img src={Sabah88Icon} alt="Logo" className="Diclogoimages"/>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      color={ 'primary' }
                      checked={this.state.showSWK}
                      onChange={(e) => this.alterCheckbox('showSWK')}
                    />
                    <img src={SwkIcon} alt="Logo" className="Diclogoimages"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row mobile4D1 ${isMobile && 'mobile4D'}`} style={{ display: 'flex', marginBottom: 2, border: '5px', padding: '8px', fontWeight: 'bold' }}>
            <div style={{ width: '100%' }}>
              <div style={{ marginBottom: 10, marginLeft: 50, marginRight: 50 }}>
                <div className = "dcc Dictionaryborder" style={{ width: 'calc(100% / 1)', color: 'white' }}>
                  4D
                </div>
              </div>
              <div className={`dcc ${isMobile && 'row'}`} style={{ padding: '8px', fontWeight: 'bold', width: '100%'}}>
                <div className={`${isMobile && 'mobile4D3'}`}>
                  <div className="row">
                    <div className={`dcc ${isMobile && 'mobileHeader'}`} style={{ marginRight: 60, justifyContent: 'center' }}>
                      <div className="row">
                        <div style={{ padding: 10, marginRight: 20 }}>
                          <img src={MagnumIcon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.mag || 0}</div>
                        </div>
                        <div style={{ padding: 10, marginRight: 20 }}>
                          <img src={DamacaiIcon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.dmc || 0}</div>
                        </div>
                        <div style={{ padding: 10 }}>
                          <img src={TotoIcon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.toto || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`dcc ${isMobile && 'mobileHeader'}`} style={{ marginRight: 60, justifyContent: 'center' }}>
                      <div className="row">
                        <div style={{ padding: 10, marginRight: 20 }}>
                          <img src={Sg4Icon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.sg4 || 0}</div>
                        </div>
                        <div style={{ padding: 10, marginRight: 20 }}>
                          <img src={Bt4Icon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.bt4 || 0}</div>
                        </div>
                        <div style={{ padding: 10 }}>
                          <img src={GdIcon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.gd || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={`dcc ${isMobile && 'mobileHeader'}`} style={{ marginRight: 60, justifyContent: 'center' }}>
                      <div className="row">
                        <div style={{ padding: 10, marginRight: 20 }}>
                          <img src={SDKIcon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.sdk || 0}</div>
                        </div>
                        <div style={{ padding: 10, marginRight: 20 }}>
                          <img src={Sabah88Icon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.sbh || 0}</div>
                        </div>
                        <div style={{ padding: 10 }}>
                          <img src={SwkIcon} alt="Logo" className="Diclogoimages"/>
                          <div className="Historyborder">{this.state.winResult.swk || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className={`dcc ${isMobile && 'mobileHeader'}`} style={{ marginLeft: 20 ,justifyContent: 'center' }}>
                      <div className="row">
                        <div style={{ padding: 10 }}>
                          <div className = "Historyborder2" style={{ color: 'white', marginBottom: 5 }}>{language[this.props.currentLanguage].first}</div>
                          <div className="Historyborder3">{this.state.winResult.first || 0}</div>
                        </div>
                        <div style={{ padding: 10 }}>
                          <div className = "Historyborder2" style={{ color: 'white', marginBottom: 5 }}>{language[this.props.currentLanguage].second}</div>
                          <div className="Historyborder3">{this.state.winResult.second || 0}</div>
                        </div>
                        <div style={{ padding: 10 }}>
                          <div className = "Historyborder2" style={{ color: 'white', marginBottom: 5 }}>{language[this.props.currentLanguage].third}</div>
                          <div className="Historyborder3">{this.state.winResult.third || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ justifyContent: 'center'}}>
                    <div className={`dcc ${isMobile && 'mobileHeader'}`} style={{ marginLeft: 20 ,justifyContent: 'center' }}>
                      <div className="row">
                        <div style={{ padding: 10 }}>
                          <div className = "Historyborder2" style={{ color: 'white', marginBottom: 5 }}>{language[this.props.currentLanguage].special}</div>
                          <div className="Historyborder3">{this.state.winResult.special || 0}</div>
                        </div>
                        <div style={{ padding: 10 }}> 
                          <div className = "Historyborder2" style={{ color: 'white', marginBottom: 5 }}>{language[this.props.currentLanguage].consolation}</div>
                          <div className="Historyborder3">{this.state.winResult.consolation || 0}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ color: 'red', fontWeight: 'bold' }}> {EMessage} </div>
        {(this.state.showResult && this.state.result.length < 4) && 
          <div className="col">
            <div style={{ display: 'flex', marginBottom: 10, marginLeft: 50, marginRight: 50 }}>
              <div className = "dcc Dictionaryborder" style={{ width: 'calc(100% / 1)', color: 'white' }}>
                {language[this.props.currentLanguage].NumberDictionary}
              </div>
            </div>
            <div className="dcc numberborderDic" style={{ marginBottom: 30, fontWeight: 'bold', marginLeft: 50, marginRight: 50 }}>
              <div className="row" style={{ display: 'flex', padding: '8px', fontWeight: 'bold', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {this.state.result.map((item, index) => {
                  return (
                    <div className={`row mobileHeaderDic ${isMobile && 'mobileHeaderDicMobile'}`} style={{ margin: 20 }} key={index}>
                      <div className={`dcc Dictionarytypeborder ${this.renderClass(item.item.type)} ${isMobile && 'Dictionarytypebordermobile' }`} style={{ padding: 5 }}>
                        {this.renderLogo2(item.item.type)}
                        {language[this.props.currentLanguage][item.item.type]}
                      </div>
                      <div style={{ padding: 5 }}></div>
                      <div className={`numberborderDic2 ${isMobile && 'mobileHeaderDictionary'}`}>
                        {item.item.number}
                        <div style={{ padding: 10 }}>{this.renderDictionaryImage(item.item.img)}</div>
                        <div style={{ padding: 10 }}>{item.item.cn}</div>
                        <div style={{ padding: 10 }}>{item.item.en}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        }
        <div className={` ${isMobile && 'row'}`} style={{ display: 'flex', margin: '20px 50px', marginBottom: 0 }}>
          {this.state.showStraight && <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 10px' }}>
            <div className = "dcc jackpotborder" style={{ display: 'flex', width: '100%', color: 'white' }}>
              {language[this.props.currentLanguage].StraightNumHisResult}
            </div>
            <Table striped bordered hover style={{ width: '100%', marginTop: 10, backgroundColor: 'white' }}>
              <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                <tr>
                  <th>{language[this.props.currentLanguage].Platform}</th>
                  <th>{language[this.props.currentLanguage].fourD}</th>
                  <th>{language[this.props.currentLanguage].Prize}</th>
                  <th>{language[this.props.currentLanguage].drawDate}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.straightResult.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{this.renderLogo(item.platform)}</td>
                      <td>{item.number}</td>
                      <td>{language[this.props.currentLanguage][item.position]}</td>
                      <td>{Moment(item.date, 'YYYYMMDD').format('DD-MM-YYYY')}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>}
          {this.state.showPermutation && <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 10px' }}>
            <div className = "dcc jackpotborder" style={{ display: 'flex', width: '100%', color: 'white' }}>
              {language[this.props.currentLanguage].PemutationNumHisResult}
            </div>
            <Table striped bordered hover style={{ width: '100%', marginTop: 10, backgroundColor: 'white' }}>
              <thead style={{ backgroundColor: '#6D100E', color: 'white' }}>
                <tr>
                  <th>{language[this.props.currentLanguage].Platform}</th>
                  <th>{language[this.props.currentLanguage].fourD}</th>
                  <th>{language[this.props.currentLanguage].Prize}</th>
                  <th>{language[this.props.currentLanguage].drawDate}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.permutationsResult.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{this.renderLogo(item.platform)}</td>
                      <td>{item.number}</td>
                      <td>{language[this.props.currentLanguage][item.position]}</td>
                      <td>{Moment(item.date, 'YYYYMMDD').format('DD-MM-YYYY')}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(FourDHistory)
