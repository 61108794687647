import React, { Component } from 'react';
import { connect } from 'react-redux'
import { language } from '../../language';
import toto from '../../Images/toto.png';
import Moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { characterList } from '../../static'
import Monkey from '../../Images/zodiacToto-Monkey.png';
import Boar from '../../Images/zodiacToto-Boar.png';
import Dog from '../../Images/zodiacToto-Dog.png';
import Dragon from '../../Images/zodiacToto-Dragon.png';
import Goat from '../../Images/zodiacToto-Goat.png';
import Hourse from '../../Images/zodiacToto-Horse.png';
import Ox from '../../Images/zodiacToto-Ox.png';
import Rabbit from '../../Images/zodiacToto-Rabbit.png';
import Rat from '../../Images/zodiacToto-Rat.png';
import Rooster from '../../Images/zodiacToto-Rooster.png';
import Snake from '../../Images/zodiacToto-Snake.png';
import Tiger from '../../Images/zodiacToto-Tiger.png';
import { isMobile } from 'react-device-detect';
import { filterJackpotNumber } from '../../utility/filterJackpotNumber';
import LiveLogo from '../../Images/Live.gif';

class TotoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  renderLogo(platform) {
    if (platform === 'PIG') {
      return <img src={Boar} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'TIGER') {
      return <img src={Tiger} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'SNAKE') {
      return <img src={Snake} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'OX') {
      return <img src={Ox} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'RAT') {
      return <img src={Rat} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'GOAT') {
      return <img src={Goat} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'HORSE') {
      return <img src={Hourse} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'DRAGON') {
      return <img src={Dragon} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'RABBIT') {
      return <img src={Rabbit} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'MONKEY') {
      return <img src={Monkey} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'DOG') {
      return <img src={Dog} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else if (platform === 'ROOSTER') {
      return <img src={Rooster} alt="Logo" className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    } else {
      return <div className={`Animallogo2 ${isMobile && 'smallerAnimallogo'}`} />
    }
  }

  render() {
    const Special = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
    const consolation = [14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="totoborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={toto} alt="Logo" className="logoimages" />
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white', position: 'relative' }}>
              <b>
                {language[this.props.currentLanguage].toto} 4D
                {Moment().hours() >= 19 && <Link target="_blank" to={{ pathname: "http://totolive.sportstoto.com.my/stoto/popup_live_result.asp"}} >
                  <img src={LiveLogo} style={{ position: 'absolute', top: -25, right: -30, width: 60, height: 30}} alt="Live" />
                </Link>}
              </b>
            </div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.toto.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div className="row" style={{ width: '100%', marginLeft: -2, flexWrap: 'nowrap' }}>
          <div style={{ width: 'calc(100% / 1.2)' }}>
            <div style={{ display: 'flex', marginBottom: 10 }}>
              <div className="dcc totoborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, fontWeight: 'bold', color: 'white' }}>
                {language[this.props.currentLanguage].first}
              </div>
              <div className="dcc totoborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold', color: 'white' }}>
                {language[this.props.currentLanguage].second}
              </div>
              <div className="dcc totoborderclass" style={{ width: 'calc(100% / 3)', fontWeight: 'bold', color: 'white' }}>
                {language[this.props.currentLanguage].third}
              </div>
            </div>
            <div className="numberborder" style={{ marginBottom: 10, fontWeight: 'bold' }}>
              <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
                <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }} to={{ pathname: "/FourDHistory", data: this.props.toto.P1 || '----' }} >
                  {this.props.toto.P1OriPosition && <span style={{ color: 'red', position: 'absolute', top: -6, left: 3, fontSize: '15px' }}>{characterList[this.props.toto.P1OriPosition]}</span>}
                  {this.props.toto.P1 || '----'}
                </Link>
                <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }} to={{ pathname: "/FourDHistory", data: this.props.toto.P2 || '----' }} >
                  {this.props.toto.P2OriPosition && <span style={{ color: 'red', position: 'absolute', top: -6, left: 3, fontSize: '15px' }}>{characterList[this.props.toto.P2OriPosition]}</span>}
                  {this.props.toto.P2 || '----'}
                </Link>
                <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }} to={{ pathname: "/FourDHistory", data: this.props.toto.P3 || '----' }} >
                  {this.props.toto.P3OriPosition && <span style={{ color: 'red', position: 'absolute', top: -6, left: 3, fontSize: '15px' }}>{characterList[this.props.toto.P3OriPosition]}</span>}
                  {this.props.toto.P3 || '----'}
                </Link>
              </div>
            </div>
          </div>
          <div style={{ width: 'calc(100%/4)', marginLeft: 10 }}>
            <div className="zodiacspaces" style={{ marginBottom: -15, backgroundColor: '#ff431b', borderRadius: 10 }}>{this.renderLogo(this.props.toto.zodiac || '----')}</div>
            <div className="zodiacfont" style={{ color: 'white', fontWeight: 'bolder', backgroundColor: '#ff431b', borderRadius: 10 }}>{this.props.toto.zodiac}</div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10, fontWeight: 'bold', color: 'white' }}>
          <div className="dcc totoborderclass" style={{ width: 'calc(100% / 1)' }}>
            {language[this.props.currentLanguage].special}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              Special
                ? Special.map((item, index) => {
                  let itemList = [<Link key={index} style={{ width: 'calc(100% / 5)', padding: '8px', position: 'relative', color: 'black', textDecoration: 'none' }}
                    to={{ pathname: "/FourDHistory", data: this.props.toto[item] || '----' }} >
                    <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                    {this.props.toto[item] || '----'}
                  </Link>]
                  if (index === 10) {
                    itemList.unshift(<div key={'14'} style={{ width: 'calc(100% / 5)', padding: '8px' }}>
                      {' '}
                    </div>)
                  }
                  return itemList
                })
                : [...Array(13)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="dcc totoborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white' }}>
            {language[this.props.currentLanguage].consolation}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              consolation
                ? consolation.map((item, index) => <Link key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}
                  to={{ pathname: "/FourDHistory", data: this.props.toto[item] || '----' }} >
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '15px' }}>{characterList[item]}</span>
                  {this.props.toto[item] || '----'}
                </Link>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="dcc totoborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white' }}>
            {language[this.props.currentLanguage].JEstimated}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 30, fontWeight: 'bold' }}>
          <div style={{ display: 'flex', marginBottom: 2 }}>
            <div style={{ width: 'calc(100% / 2)', borderradius: '5px' }}>{language[this.props.currentLanguage].Jackpot} 1</div>
            <div style={{ width: 'calc(100% / 1)', borderradius: '5px' }}>RM {filterJackpotNumber(this.props.toto.jackpot1) || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 2)', borderradius: '5px' }}>{language[this.props.currentLanguage].Jackpot} 2</div>
            <div style={{ width: 'calc(100% / 1)', borderradius: '5px' }}>RM {filterJackpotNumber(this.props.toto.jackpot2) || '----'}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(TotoCard)
