import '../App.css';
import React from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiCalendar } from '@react-icons/all-files/fi/FiCalendar';
import { FaSearchDollar } from '@react-icons/all-files/fa/FaSearchDollar';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect';
import { language } from '../language';
import MagnumCard from '../Component/4DCards/MagnumCard'
import DamacaiCard from '../Component/4DCards/DamacaiCard'
import TotoCard from '../Component/4DCards/TotoCard'
import Sg4Card from '../Component/4DCards/Sg4Card'
import Bt4Card from '../Component/4DCards/bt4Card'
import GdCard from '../Component/4DCards/gdCard'
import SbhCard from '../Component/4DCards/sbhCard'
import SdkCard from '../Component/4DCards/sdkCard'
import SwkCard from '../Component/4DCards/swkCard'
import { getDataByDate } from '../api'
import { Link } from 'react-router-dom';
import { FaSistrix } from '@react-icons/all-files/fa/FaSistrix';

class PastResultClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dmc: {},
      mag: {},
      toto: {},
      sg4: {},
      bt4: {},
      gd: {},
      sdk: {},
      sbh: {},
      swk: {},
      number: '',
      currentTime: Moment().format('DD-MM-YYYY (ddd)'),
      curTime: Moment().format('YYYYMMDD'),
      date: new Date(),
    }
    this.setSelectedDate = this.setSelectedDate.bind(this);
    // this.getStatistic = this.getStatistic.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const text = this.state.curTime
    const data = await getDataByDate(text)
    if (data) {
      this.setState({ dmc: data.dmc || {} })
      this.setState({ mag: data.mag || {} })
      this.setState({ toto: data.toto || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt4: data.bt4 || {} })
      this.setState({ gd: data.gd || {} })
      this.setState({ sdk: data.sdk || {} })
      this.setState({ sbh: data.sbh || {} })
      this.setState({ swk: data.swk || {} })
    }
  }

  async setSelectedDate(date) {
    this.setState({ date })
    const dateDB = Moment(date).format('YYYYMMDD')
    const data = await getDataByDate(dateDB)
    if (data) {
      this.setState({ dmc: data.dmc || {} })
      this.setState({ mag: data.mag || {} })
      this.setState({ toto: data.toto || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt4: data.bt4 || {} })
      this.setState({ gd: data.gd || {} })
      this.setState({ sdk: data.sdk || {} })
      this.setState({ sbh: data.sbh || {} })
      this.setState({ swk: data.swk || {} })
    }
  }

  async changeYesterday() {
    const yesterday = Moment(this.state.curTime).subtract(0, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeYesterday1() {
    const yesterday = Moment(this.state.curTime).subtract(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeYesterday2() {
    const yesterday = Moment(this.state.curTime).subtract(2, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeYesterday3() {
    const yesterday = Moment(this.state.curTime).subtract(3, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeYesterday4() {
    const yesterday = Moment(this.state.curTime).subtract(4, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeYesterday5() {
    const yesterday = Moment(this.state.curTime).subtract(5, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeYesterday6() {
    const yesterday = Moment(this.state.curTime).subtract(6, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeTomorrow() {
    const yesterday = Moment(this.state.curTime).add(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  handleChange(value, field) {
    this.setState({[field]: value});
  }

  // getStatistic() {
  //   let result = this.state.number;
  //   console.log(result)
  // }

  render() {
    const currentDateFormat = Moment(this.state.date).format('YYYYMMDD')
    const InputKeyword = this.state.number
    return (
      <div className="App backgroundImage2" style={{ paddingTop: 50 }}>
        <div className="row" style={{ display: 'flex', marginLeft: 50, marginRight: 50 }}>
          <div className ={`dcc jackpotborder ${isMobile && 'jackpotborder'}`} style={{ width: 'calc(100% / 1)', color: 'white', fontSize: '20px' }}>
            <FaSearchDollar size="25px" color="white" className="logohome" /> {language[this.props.currentLanguage].pastResult}
          </div>
        </div>
        <div className={`dcc row ${isMobile && 'mobileHeader'}`} style={{ padding: '10px 0px', marginLeft: '5%', width: '90%', justifyContent: 'space-between' }}>
          <div className="dcc">
            <div>{language[this.props.currentLanguage].date} : {this.state.currentTime}</div>
          </div>
          <div className="dcc">
            <div style={{ marginRight: 5 }}>{language[this.props.currentLanguage].findNumber}: </div>
            <input
              style={{ textAlign: 'center' }}
              value={this.state.number}
              onChange={(e) => this.handleChange(e.target.value, 'number')}
              placeholder={'1234 / Tiger / 老虎'}
            />
            <button className="dcc DictionaryButtonborder22"><Link to={{ pathname: "/Dictionary", data: InputKeyword || '----' }}><FaSistrix size="20px" color="black" onClick={this.getStatistic}/></Link></button>
          </div>
        </div>
        <div className={`numberborderPast ${isMobile && `numberborderPastMobile`}`} style={{ marginBottom: 30, fontWeight: 'bold', marginLeft: '5%', width: '90%', justifyContent: 'space-evenly', display: 'flex' }}>
          <div className={`dcc ${isMobile && 'row mobileHeader2'} `} style={{ display: 'flex', fontWeight: 'bold' }}>
            <div> {language[this.props.currentLanguage].PleaseSelectedate}
              <div className="row" style={{ width: '100%', margin: 20 }}>
                <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].date} : </div>
                <br />
                <DatePicker
                  onChange = {(date) => this.setSelectedDate(date)}
                  dateFormat = 'dd-MMM-yyyy'
                  placeholder="Date"
                  selected={this.state.date}
                />
                <div className="dcc datetimeborder">
                  <FiCalendar size="20px" color="black" />
                </div>
              </div>
            </div>
          </div>
          <div className={`dcc`} className="dcc" style={{ display: 'flex', fontWeight: 'bold', flexDirection: 'column' }}>
            <p>{language[this.props.currentLanguage].recentdata}</p>
            <div className={`dcc ${isMobile && 'row mobileHeader2'}`}>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday6()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(6, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(6, 'days').format('DD/MM')}</div>
                </div>
              </button>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday5()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(5, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(5, 'days').format('DD/MM')}</div>
                </div>
              </button>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday4()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(4, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(4, 'days').format('DD/MM')}</div>
                </div>
              </button>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday3()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(3, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(3, 'days').format('DD/MM')}</div>
                </div>
              </button>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday2()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(2, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(2, 'days').format('DD/MM')}</div>
                </div>
              </button>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday1()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(1, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(1, 'days').format('DD/MM')}</div>
                </div>
              </button>
              <button className="dcc nearDateButton" style={{ marginRight: 10, marginBottom: 10, padding: '30px 10px', color: 'white', fontWeight: 'bold' }} onClick={() => this.changeYesterday()}>
                <div>
                  {language[this.props.currentLanguage].daysOfWeek[Moment(this.state.curTime).subtract(0, 'days').format('d')]}<div>{Moment(this.state.curTime).subtract(0, 'days').format('DD/MM')}</div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center px-3">
          <div className={`row Marginout ${isMobile && 'mobileCardContainer'}`} style={{ width: '100%', marginTop: 40, marginBottom: 0 }}>
            {
              (this.state.mag.drawNo || currentDateFormat >= this.state.curTime) && <MagnumCard mag={this.state.mag} date={this.state.date}/>
            }
            {
              (this.state.dmc.drawNo || currentDateFormat >= this.state.curTime) && <DamacaiCard dmc={this.state.dmc} date={this.state.date}/>
            }
            {
              (this.state.toto.drawNo || currentDateFormat >= this.state.curTime) && <TotoCard toto={this.state.toto} date={this.state.date}/>
            }
            {
              (this.state.sg4.drawNo || currentDateFormat >= this.state.curTime) && <Sg4Card sg4={this.state.sg4} date={this.state.date}/>
            }
            {
              (this.state.bt4.P1 || currentDateFormat >= this.state.curTime) && <Bt4Card bt4={this.state.bt4} date={this.state.date}/>
            }
            {
              (this.state.gd.drawNo || currentDateFormat >= this.state.curTime) && <GdCard gd={this.state.gd} date={this.state.date}/>
            }
            {
              (this.state.sdk.drawNo || currentDateFormat >= this.state.curTime) && <SdkCard sdk={this.state.sdk} date={this.state.date}/>
            }
            {
              (this.state.sbh.drawNo || currentDateFormat >= this.state.curTime) && <SbhCard sbh={this.state.sbh} date={this.state.date}/>
            }
            {
              (this.state.swk.drawNo || currentDateFormat >= this.state.curTime) && <SwkCard swk={this.state.swk} date={this.state.date}/>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(PastResultClass)
