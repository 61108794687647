import React, { Component } from 'react';
import { connect } from 'react-redux'
import toto from '../../Images/toto.png';
import { language } from '../../language';
import Moment from 'moment'
import { filterJackpotNumber } from '../../utility/filterJackpotNumber';

class TotoJackCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // const Special = [1,2,3,4,5,6,7,8,9,10]
    const forthprices = this.props.toto5[1]
    const fifthprices = this.props.toto5[1]
    const sixprices = this.props.toto5[1]
    const secondprices = this.props.toto6[1] ? this.props.toto6[1].substring(0,5) : '----'
    const secondPrices2 = this.props.toto6[1] ? this.props.toto6[1].substring(1,6) : '----'
    const thirdprices1 = this.props.toto6[1] ? this.props.toto6[1].substring(0,4) : '----'
    const thirdprices2 = this.props.toto6[1] ? this.props.toto6[1].substring(2,6) : '----'
    const fourprices1 = this.props.toto6[1] ? this.props.toto6[1].substring(0,3) : '----'
    const fourprices2 = this.props.toto6[1] ? this.props.toto6[1].substring(3,6) : '----'
    const fifthprices1 = this.props.toto6[1] ? this.props.toto6[1].substring(0,2) : '----'
    const fifthprices2 = this.props.toto6[1] ? this.props.toto6[1].substring(4,6) : '----'
    const totosta = [1,2,3,4,5,6,7,8]
    const totopow = [1,2,3,4,5,6]
    const totosup = [1,2,3,4,5,6]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="totoborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={toto} alt="Logo" className="logoimages"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white' }}><b>{language[this.props.currentLanguage].toto} 5D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.toto5.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="column americaborder" style={{ width: '100%', backgroundColor: '#ff431b', color: 'white' }}>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ff431b' }}>{language[this.props.currentLanguage].first}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ff431b' }}>{language[this.props.currentLanguage].second}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ff431b' }}>{language[this.props.currentLanguage].third}</div>
          </div>
          <div className="column numberborder2" style={{ width: '100%', backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{this.props.toto5[1] || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{this.props.toto5[2] || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{this.props.toto5[3] || '----'}</div>
          </div>
          <div className="column americaborder" style={{ width: '100%', backgroundColor: '#ff431b', color: 'white' }}>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ff431b' }}>{language[this.props.currentLanguage].fourth}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ff431b' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className = "dcc column americaborder5D2" style={{ height: '35px', backgroundColor: '#ff431b' }}>{language[this.props.currentLanguage].sixth}</div>
          </div>
          <div className="column numberborder2" style={{ width: '100%', backgroundColor: '#eeeeee', fontWeight: 'bold' }}>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{forthprices && forthprices.substring(1,5) || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{fifthprices && fifthprices.substring(2,5) || '----'}</div>
            <div className = "dcc column numberborderdspecon" style={{ height: '35px' }}>{sixprices && sixprices.substring(3,5) || '----'}</div>
          </div>
        </div>
        <div className="totoborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={toto} alt="Logo" className="logoimages"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white', fontWeight: 'bold' }}><b>{language[this.props.currentLanguage].toto} 6D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white', fontWeight: 'bold' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{this.props.toto6.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div className="numberbordergorjackport" style={{ marginBottom: 10, borderradius: '5px' }}>
          <div className="dcc" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold' }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ff431b', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].first}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>{this.props.toto6[1] || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ff431b', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].second}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{secondprices || '----'}</div>
              <div>or </div>
              <div>{secondPrices2 || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ff431b', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].third}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{thirdprices1 || '----'}</div>
              <div>or </div>
              <div>{thirdprices2 || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ff431b', height: '35px', color: 'white'}}>{language[this.props.currentLanguage].fourth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{fourprices1 || '----'}</div>
              <div>or </div>
              <div>{fourprices2 || '----'}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#ff431b', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{fifthprices1 || '----'}</div>
              <div>or </div>
              <div>{fifthprices2 || '----'}</div>
            </div>
          </div>
        </div>
        <div className="totoborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={toto} alt="Logo" className="logoimages"/>
            </div>
            <div className="textalignmen3t" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <b>{language[this.props.currentLanguage].StarToto} 6/50</b>
              <b>{language[this.props.currentLanguage].PowerToto} 6/55</b>
              <b>{language[this.props.currentLanguage].SupremeToto} 6/58</b>
            </div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.totosta.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc totoborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white'  }}>
            {language[this.props.currentLanguage].StarToto} 6/50
          </div>
        </div>
        <div className="numberbordergorjackport2" style={{ marginBottom: 10 }}>
          <div className="dcc border-bottom border-dark-solid" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold', padding: '8px' }}>
            {
              totosta
                ? totosta.map((item, index) => {
                  return index === 6 ? <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 3)'}}>+</div> : <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 3)'}}>{this.props.totosta[item] || '----' }</div>
                })
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 3)' }}>----</div>)
            }
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold', padding: '8px', borderradius: '5px' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 2)', borderradius: '5px', height: '35px' }}>{language[this.props.currentLanguage].Jackpot} :</div>
            <div className="dcc border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px' }}>RM {filterJackpotNumber(this.props.totosta.jackpot1) || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold', padding: '8px', borderradius: '5px' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 2)', height: '35px' }}>{language[this.props.currentLanguage].Jackpot} :</div>
            <div className="dcc border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 1)', height: '35px' }}>RM {filterJackpotNumber(this.props.totosta.jackpot2) || '----'}</div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc totoborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white'  }}>
            {language[this.props.currentLanguage].PowerToto} 6/55
          </div>
        </div>
        <div className="numberbordergorjackport2" style={{ marginBottom: 10 }}>
          <div className="dcc border-bottom border-dark-solid" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold', padding: '8px' }}>
            {
              totopow
                ? totopow.map((item, index) => <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 6)' }}>{this.props.totopow[item] || '----' }</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 6)' }}>----</div>)
            }
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold', padding: '8px' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 2)', borderradius: '5px', height: '35px' }}>{language[this.props.currentLanguage].Jackpot} :</div>
            <div className="dcc border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px' }}>RM {filterJackpotNumber(this.props.totopow.jackpot1) || '----'}</div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc totoborderclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold', color: 'white' }}>
            {language[this.props.currentLanguage].SupremeToto} 6/58
          </div>
        </div>
        <div className="numberbordergorjackport2" style={{ marginBottom: 30 }}>
          <div className="dcc border-bottom border-dark-solid" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold', padding: '8px' }}>
            {
              totosup
                ? totosup.map((item, index) => <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 6)' }}>{this.props.totosup[item] || '----' }</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 6)' }}>----</div>)
            }
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold', padding: '8px' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 2)', borderradius: '5px', height: '35px' }}>{language[this.props.currentLanguage].Jackpot} :</div>
            <div className="dcc border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px' }}>RM  {filterJackpotNumber(this.props.totosup.jackpot1) || '----'}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(TotoJackCard)
