// import '../App.css';
import React from 'react';
import TotoIcon from '../../Images/Dictionary/IconSportToto.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { language } from '../../language';
import { isMobile } from 'react-device-detect';

class KeywordTotoDictionary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  renderDictionaryImage(imageName) {
    return <img src={`/Image/${imageName}`} alt="Logo" className="Hislogoimages"/>
  }
  
  render() {
    return (
      <div className="App">
        <div style={{ display: 'flex', marginBottom: 10, marginLeft: 50, marginRight: 50 }}>
          <div className = "dcc DictionaryTotomborder" style={{ width: 'calc(100%)', color: 'white' }}>
            <img src={TotoIcon} alt="Logo" className="Diclogoimages" style={{ marginRight: 15 }}/>{language[this.props.currentLanguage].toto} {language[this.props.currentLanguage].NumberDictionary}
          </div>
        </div>
        <div className="d-flex justify-content-center px-3 numberborderDic" style={{ marginBottom: 30, fontWeight: 'bold', marginLeft: 50, marginRight: 50 }}>
          <div className="row" style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {this.props.result.filter((item) => item.item.type === 'toto').map((item, index) => {
              return (
                <div className={`row mobileHeaderDic ${isMobile && 'mobileHeaderDicMobile'}`} style={{ margin: 20 }} key={index}>
                  <div style={{ padding: 5 }}></div>
                  <div className={`numberborderDic2 ${isMobile && 'mobileHeaderDictionary'}`}>
                    {item.item.number}
                    <div style={{ padding: 10 }}>{this.renderDictionaryImage(item.item.img)}</div>
                    <div style={{ padding: 10 }}>{item.item.cn}</div>
                    <div style={{ padding: 10 }}>{item.item.en}</div>
                    <div style={{ padding: 10  }}><button className="datePickerButton"><Link style={{ color: 'black', textDecoration: 'none' }}  to={{ pathname: "/FourDHistory", data: item.item.number }}>查看中奖记录</Link></button></div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

// export default KeywordTotoDictionary;
function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(KeywordTotoDictionary)