const language = {
  en: {
    jackpot: 'Jackpot',
    sarawak: 'Sarawak Cashsweep',
    sabah: 'Sabah88',
    sandakan: 'Sandakan',
    GdToto: 'Grand Dragon Lotto',
    america: 'America',
    singapore: 'Singapore',
    toto: 'SportsToto',
    damacai: 'Damacai',
    magnum: 'Magnum',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Four',
    fifth: 'Fifth',
    sixth: 'Six',
    seventh: 'Seven',
    special: 'Special',
    consolation: 'Consolation',
    introduction: '4D Introduction & Payout Structure',
    introduction4D: '4D (Every RM1 betting)',
    introduction4D1: 'In these games, you win when your 4D number matches one of the winning numbers drawn.',
    introductionPrize: 'Prize Category',
    introductionWinCondition: 'How to win',
    introductionCashPrize: '	Prize Money',
    introductionBig: 'Big',
    introductionSmall: 'Small',
    first2: '1st Prize',
    introduction4D2: '4D mBox ( for every RM1 buy )',
    introduction4D3: 'In mBox, you win when ANY permutation of your 4D number matches one of the winning numbers.',
    introduction4D4: 'The odds are a lot more in your favour, and the payouts differ according to the number of permutations.',
    introductionQB24: 'mBox 24',
    introductionQB12: '	mBox 12',
    introductionQB6: '	mBox 6',
    introductionQB4: '	mBox 4',
    introduction4D5: '4D Jackpot ( for every RM2 buy )',
    introduction4D6: 'Up the stakes with 4D Jackpot, an extension of the original 4D game with a multi million ringgit prize!',
    introduction4D7: '1. Pick two 4-digit numbers from 0000 to 9999, e.g. (1188, 2288), (3388, 2233), or (1338, 8868).',
    introduction4D8: '2. Each pair of numbers is considered as 1 Normal Buy.',
    introduction4D9: '3. Place 1 Normal Buy with a minimum of RM2 (or multiples thereof).',
    introduction4D10: 'You win when one or both of your 4D numbers matches one or more of the 23 winning numbers drawn.',
    introductionJP1: 'Jackpot 1 Prize',
    introductionJP1W: 'Selected pair matches any two of the 1st, 2nd or 3rd Prizes.',
    introductionJPCP1: 'Jackpot 1 (min RM 2,000,000) No Maximum limit',
    introductionJPCP2: 'Note: Cascading to Jackpot 2 starts',
    introductionJPCP3: 'whenever Jackpot 1 exceeds RM 30 million',
    introductionJP2: 'Jackpot 2 Prize',
    introductionJP2W: 'Selected pair matches any one of the 1st, 2nd or 3rd Prizes and any one of the 10 Starter Prizes.',
    introductionJP2CP: 'Jackpot 2 (min RM 100,000) No Maximum limit',
    introductionG3: 'Group 3 Prize',
    introductionJP3W: 'Any one number matches the ',
    introductionJP3W2: '1st, 2nd or 3rd Prizes.',
    introductionG4: 'Group 4 Prize',
    introductionJP4W: 'Any one number matches the 10 Starter Prizes.',
    introductionJP5W: 'Any one number matches the 10 Consolation Prizes.',
    introductionG5: 'Group 5 Prize',
    introductionJPCPLimit: 'Maximum RM 10,000,000',
    introduction4D11: '4D Jackpot M-System',
    introduction4D12: 'All in all, its a more affordable play with a minimum buy of RM10 for 10 numbers.',
    introduction4D13: 'You can choose up to 50 numbers for RM50. And of course, you have the options to Lucky Pick,Roll or Permutate as you wish!',
    introduction4D14: 'M-System',
    introduction4D15: '	2 of top 3 Prizes',
    introduction4D16: '1 of top 3 + 1 SPE Prize',
    introduction4D17: '1 of top 3 Prizes',
    introduction4D18: '1 SPE Prize',
    introduction4D19: '1 CON Prize',
    introduction4D20: '1/9 of Jackpot 1',
    introduction4D21: '1/9 of Jackpot 2',
    introduction4D22: '1/19 of Jackpot 1',
    introduction4D23: '1/19 of Jackpot 2',
    introduction4D24: '1/49 of Jackpot 1',
    introduction4D25: '1/49 of Jackpot 2',
    introduction4D26: '4D Jackpot Gold',
    introduction4Dpatern: 'Winning Pattern',
    Prizes: 'Prize',
    AmountWon: 'Amount Won',
    introduction4D27: '4D Jackpot GOLD raises the fun factor even more, with more numbers involved and a separate multi-million ringgit jackpot prize!',
    introduction4D28: '1. Pick a 6-digit component – 3x 2-digit numbers (from 00 to 99) eg. 220278.',
    introduction4D29: '2. Pick a 2-digit component – 1x 2-digit number (from 00 to 19) eg. 11.',
    introduction4D30: '3. Place a buy with a minimum of RM2 (or multiples thereof).',
    introduction4D31: 'You win when the 6-digit component you picked matches the 6-digit component drawn, ',
    introduction4D32: 'and/or the 2-digit component you picked also matches the 2-digit component drawn.',
    introduction4D33: 'Win Jackpot Gold 1 if every one of your 6 Digits and GOLDEN numbers exactly match the Jackpot Gold winning number.',
    introduction4D34: 'Win Jackpot Gold 2 if:',
    introduction4D35: '- Your 6-digit umbers match the first 5 or last 5 numbers in the 6 Digits component',
    introduction4D36: '- AND your GOLDEN numbers match both the numbers in the GOLDEN component in the Jackpot Gold winning number.',
    introduction4D37: 'Win 3rd Prize if your 6 Digits numbers exactly match the 6 Digits component of the Jackpot Gold winning number.',
    introduction4D38: 'Win 4th Prize if your 6 Digits numbers match the first 5 or last 5 numbers in the 6 Digits component of the Jackpot Gold winning number.',
    introduction4D39: 'Win 5th Prize if your 6 Digits numbers match the first 4 or last 4 numbers in the 6 Digits component of the Jackpot Gold winning number.',
    introduction4D40: 'Win 6th Prize if your 6 Digits numbers match the first 3 or last 3 numbers in the 6 Digits component of the Jackpot Gold winning number.',
    introduction4D41: '	Win 7th Prize if your 6 Digits numbers match the first 2, middle 2 and last 2 numbers in the 6 Digits component of the Jackpot Gold winning number.',
    introductionMin2m: '(guaranteed minimum of RM 2,000,000)',
    introductionMin100k: '(guaranteed minimum of RM 100,000)',
    introductionEvery2: '(for every RM2 buy)',
    introduction4D42: '4D Jackpot Powerball',
    introduction4D43: '4D Powerball is another take on the classic 4D Jackpot, just with an additional 2 Powerball numbers.',
    introduction4D44: '1. Pick a 4-digit number from 0000 to 9999, e.g. 3456',
    introduction4D45: '2. Pick 2 x 2-digit Powerball numbers from 00 to 99, e.g. 12 and 78',
    introduction4D46: '3. Place a buy with a minimum of RM2 (or multiples thereof).',
    introduction4D47: 'You win when your 4-digit number matches any of the top 3 prizes, and/or the Powerball numbers match the Powerball numbers drawn.  ',
    introduction4D48: 'Win Powerball Jackpot 1, 1st Prize if:',
    introduction4D49: '- Your 4 Digits must match the 4D 1st Prize winning number.',
    introduction4D50: '- Your Powerball numbers exactly match both the winning Powerball numbers in any order.',
    introduction4DLowest2m: '(guaranteed minimum of RM 2,000,000)',
    introduction4DLowest100k: '(guaranteed minimum of RM 100,000)',
    introduction4D53: 'Win Powerball Jackpot 1, 2nd Prize if:',
    introduction4D54: '- Your 4 Digits must match the 4D 2nd Prize winning number.',
    introduction4D55: '- Your Powerball numbers exactly match both the winning Powerball numbers in any order.',
    introduction4D56: 'Win Powerball Jackpot 1, 3rd Prize if:',
    introduction4D57: '- Your 4 Digits must match the 4D 3rd Prize winning number.',
    introduction4D58: '- Your Powerball numbers exactly match both the winning Powerball numbers in any order.',
    introduction4D59: '	Win Powerball Jackpot 2, if:',
    introduction4D60: '- Your 4 Digits must matches any of the 4D Special Prize or Consolation Prize winning numbers.',
    introduction4D61: '- Your Powerball numbers exactly match both the winning Powerball numbers in any order.',
    introduction4D62: 'Win 3rd Prize if any of your 4 Digits matches the 4D 1st, 2nd or 3rd Prize winning numbers.',
    introduction4D63: 'Win 4th Prize if any of your 4 Digits must matches the 4D Special Prize or Consolation Prize winning numbers.',
    introduction4D64: 'Win 5th Prize if any of your Powerball numbers matches any one of the Powerball winning numbers.',
    introductionDMC1: '3D ( for every RM1 buy )',
    introductionDMC2: 'Simply select any 3-Digit number from 000 – 999',
    introductionDMC3: 'Place minimum bet amount of RM1 on ABC or A',
    introductionDMC4: '1+3D ( for every RM1 buy )',
    introductionDMC5: '1. To play 1+3D game, choose your favourite or lucky four-digit numbers from 0000 to 9999.',
    introductionDMC6: '2. Then decide on your bet type i.e. "ABC" (Big) or "A" (Small); or you can play both.',
    introductionDMC7: '3. "ABC" (Big) increases your chance of winning as there are 23 winning numbers to match with i.e. 1st Prize, 2nd Prize, 3rd Prize, 10 Starter Prizes and 10 Consolation Prizes.',
    introductionDMC8: '4. "A" (Small) pays higher winning prizes but there are only three winning numbers to match with i.e. 1st Prize, 2nd Prize and 3rd Prize.',
    introductionDMC9: '5. Finally, decide on your bet amount for each chosen number. The minimum bet amount for a 1+3D number is RM1.',
    introductionDMC10: '1+3D iBox ( for every RM1 buy )',
    introductionDMC11: '1. To play Super 1+3D game, choose your favourite or lucky four-digit numbers from 0000 to 9999.',
    introductionDMC12: '2. The Super 1+3D game has 7 Prize Category; consisting of 1ST, 2ND, 3RD, STA, CON, TP3 and ALL.',
    introductionDMC13: '3. For every four-digit number bet, a Prize Category is selected and attached to the chosen four-digit number.',
    introductionDMC14: '4. Only a single pool type for Super 1+3D is available, as such, A or ABC pools for Super 1+3D are not required.',
    introductionDMC15: '5. Finally, decide on your bet amount for each chosen number. The minimum bet amount for a Super 1+3D is RM1 or in multiples of RM1.',
    introductionDMC16: 'Your selected prize category and numbers match the 1st prize category and its corresponding winning numbers of the relevant 1+3D draw.',
    introductionDMC17: 'Your selected prize category and numbers match the 2nd prize category and its corresponding winning numbers of the relevant 1+3D draw.',
    introductionDMC18: 'Your selected prize category and numbers match the 3rd prize category and its corresponding winning numbers of the relevant 1+3D draw.',
    introductionDMC19: 'Your selected prize category and numbers match the Starter prize category and any of its corresponding winning numbers of the relevant 1+3D draw.',
    introductionDMC20: 'Your selected prize category and numbers match the Consolation prize category and any of its corresponding winning numbers of the relevant 1+3D draw.',
    introductionDMC21: 'Your selected prize category and numbers match either the 1st, 2nd or 3rd prize category and its corresponding winning numbers of the relevant 1+3D draw.',
    introductionDMC22: 'Your selected prize category and numbers match either the 1st, 2nd, 3rd, Starter or Consolation prize category and any of the winning numbers of the relevant 1+3D draw.',
    introductionDMC23: '1+3D ( for every RM1 buy )',
    introductionDMC24: '1st, 2nd or 3rd - RM 1300',
    introductionDMC25: '1+3D iBox ( for every RM1 buy )',
    introductionDMC26: '1+3D Jackpot ( for every RM2 buy )',
    introductionDMC27: 'DMC Jackpot ( for every RM2 buy )',
    introductionDMC28: '1. Pick one 4-digit numbers from 0000 to 9999 and one 3-digit numbers from 000 to 999',
    introductionDMC29: '2. Each pair of numbers is considered as 1 Normal Buy.',
    introductionDMC30: '3. Place 1 Normal Buy with a minimum of RM2 (or multiples thereof).',
    introductionDMC31: 'Pre-Requisites for Win',
    introductionDMCHighestCashPrize: 'Maximum Prize Payout',
    introductionDMCNoLimit: 'NO LIMIT',
    introductionDMC33: 'Your selected pair matches the result of 1+3D 1st Prize and the 3D 2nd Prize.',
    introductionDMC34: 'Your selected pair matches the result of',
    introductionDMC35: '- 1+3D 2nd Prize and the 3D 1st Prize or',
    introductionDMC36: '- 1+3D 3rd Prize and the 3D 1st Prize or',
    introductionDMC37: '- 1+3D 2nd Prize and 3D 3rd Prize or',
    introductionDMC38: '- 1+3D 3rd Prize and 3D 2nd Prize or',
    introductionDMC39: '- 1+3D 1st Prize and 3D 3rd Prize.',
    introductionDMC40: 'Your selected 1+3D number matches any of the 1st, 2nd or 3rd Prize of the 1+3D result.',
    introductionDMC41: 'Your selected 1+3D number matches any of the Starter Prizes of the 1+3D result.',
    introductionDMC42: 'Your selected 1+3D number matches any of the Consolation Prizes of the 1+3D result.',
    introductionDMC43: 'Your selected 3D number matches any of the 1st, 2nd or 3rd Prize of the 3D result.',
    introduction4DLowest18m: '(with a minimum of RM 1,800,000)',
    introductionDMC44: '3D Jackpot ( for every RM2 buy )',
    introductionDMC45: '1. Pick three sets of 3D numbers from 000 to 999 to form a composition of 3D numbers, e.g. 333 + 488 + 826',
    introductionDMC46: '2. Each pair of numbers is considered as 1 Normal Buy.',
    introductionDMC47: '3. Place 1 Normal Buy with a minimum of RM2 (or multiples thereof).',
    introduction4DLowest600k: '(with a minimum of RM 600,000)',
    introductionDMC48: 'All three sets of the 3D numbers match the Top 3 Prizes in any order of the 3D results.',
    introductionDMC49: 'Any two sets of the 3D numbers match any two of the Top 3 Prizes in any order of the 3D results.',
    introductionDMC50: 'Any one set of the 3D numbers matches any one of the Top 3 Prizes in any order of the 3D results.',
    introductionTOTO1: '5D',
    introductionTOTO2: 'Pick 5-Digit number from 00000 – 99999',
    introductionTOTO3: 'Place minimum bet amount of RM1',
    introductionTOTO4: '5 digits drawn as 1st Prize',
    introductionTOTO5: '5 digits drawn as 2nd Prize',
    introductionTOTO6: '5 digits drawn as 3rd Prize',
    introductionTOTO7: 'Last 4 digits of 1st Prize',
    introductionTOTO8: 'Last 3 digits of 1st Prize',
    introductionTOTO9: 'Last 2 digits of 1st Prize',
    introductionTOTO10: '* Each winning number is entitled to one prize only.',
    introductionTOTO11: '6D',
    introductionTOTO6D2: 'Pick 6-Digit number from 00000 – 99999',
    introductionTOTO12: '6 digits drawn as 1st Prize',
    introductionTOTO13: 'First 5 or last 5 digits of 1st Prize',
    introductionTOTO14: 'First 4 or last 4 digits of 1st Prize',
    introductionTOTO15: 'First 3 or last 3 digits of 1st Prize',
    introductionTOTO16: 'First 2 or last 2 digits of 1st Prize',
    introductionTOTO17: 'Star ToTo 6/50 (Prize per RM 1 bet)',
    introductionTOTO18: 'Pick 6 numbers from 1 – 50 (Number should not repeat in each selection)',
    introductionTOTO19: 'Place minimum bet amount of RM1',
    introduction4DLowest1188k: '(min of RM 1,188,888)',
    introductionTOTO20: 'Matched all 6 numbers',
    introductionTOTO21: 'Matched any 5 numbers + Bonus Number',
    introductionTOTO22: 'Matched any 5 numbers',
    introductionTOTO23: 'Matched any 4 numbers + Bonus Number',
    introductionTOTO24: 'Matched any 4 numbers',
    introductionTOTO25: 'Matched any 3 numbers + Bonus Number',
    introductionTOTO26: 'Matched any 3 numbers',
    introductionTOTO27: '* If there are more than one winner, the Jackpot will be divided among the winners based on the bet amount each winner placed.',
    introductionTOTO28: 'Power ToTo 6/55 (Prize per RM 1 bet)',
    introductionTOTO29: 'Pick 6 numbers from 1 – 55 (Number should not repeat in each selection)',
    introductionTOTO30: 'Place minimum bet amount of RM1',
    introductionTOTO31: 'Matched all 6 numbers',
    introductionTOTO32: 'Matched any 5 numbers',
    introductionTOTO33: 'Matched any 4 numbers',
    introductionTOTO34: 'Matched any 3 numbers',
    introductionTOTO35: '* If there are more than one winner, the Jackpot will be divided among the winners based on the bet amount each winner placed.',
    introduction4DLowest3m: '(min of RM 3,000,000)',
    introductionTOTO36: 'Supreme ToTo 6/58 (Prize per RM 2 bet)',
    introductionTOTO37: 'Pick 6 numbers from 1 – 58 (Number should not repeat in each selection)',
    introductionTOTO38: 'Place minimum bet amount of RM2',
    introduction4DLowest888k: '(min of RM 8,888,888)',
    introductionSG1: '4D ( for every SGD1 buy )',
    introductionSG2: '4D iBet ( for every SGD1 buy )',
    introductionSG3: 'In iBet, you win when ANY permutation of your 4D number matches one of the winning numbers.',
    introductionSG4: 'The odds are a lot more in your favour, and the payouts differ according to the number of permutations.',
    introductionSG5: 'iBet 24',
    introductionSG6: 'iBet 12',
    introductionSG7: 'iBet 6',
    introductionSG8: 'iBet 4',
    introductionSG9: 'Toto 6/49 ( for every SGD1 buy )',
    introductionSG10: 'Pick 6 numbers from 1 – 49 (Number should not repeat in each selection)',
    introductionSG11: 'Place minimum bet amount of SGD 1',
    introductionSG12: 'Matched all 6 numbers',
    introductionSG13: 'Matched any 5 numbers plus the additional number',
    introductionSG14: 'Matched any 5 numbers',
    introductionSG15: 'Matched any 4 numbers plus the additional number',
    introductionSG16: 'Matched any 4 numbers',
    introductionSG17: 'Matched any 3 numbers plus the additional number',
    introductionSG18: 'Matched any 3 numbers',
    introductionSG19: '38% of prize pool',
    introductionSG20: '8% of prize pool',
    introductionSG21: '5.5% of prize pool',
    introductionSG22: '3% of prize pool',
    introductionSG23: '* Guaranteed Jackpot Pool at a minimum of SGD 1,000,000.',
    introductionSG24: '* If there are more than one winning share, equal sharing kicks in. It means the prize amount will be shared equally among the winners/winning shares. Equal sharing only apply to Prize Group 1, 2, 3 and 4.',
    introductionSBH1: 'Lotto 6/45 (Prize per RM 1 bet)',
    introductionSBH2: 'Pick 6 numbers from 1 to 45 (Number should not repeat in each selection)',
    introduction4DLowest500k: '(min of RM 500,000)',
    introduction4DLowest5k: '(min of RM 5,000)',
    introductionBt4Jp1: '* Maximum 30 Numbers for each receipt and must atleast RM2 for each number',
    introductionBt4Jp2: '* iBox and 3D is not eligible to participate in MINI FORTUNE, each betting slip is only eligible to receive one set of PRIZE',
    secondp: '2ND',
    thirdp: '3RD',
    introductionBt4Jp3: 'Two Number Special OR Two Number Consolation',
    introductionBt4Jp4: 'Any Two Number From Result',
    introductionBt4Jp5: 'RM168,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp6: 'RM118,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp7: 'RM68,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp8: '* If there is any dispute of details regarding the Mini Fortune Prize, the BOOMSROWN Company reserves the right of final deesion.',
    introductionBt4Jp9: 'MINI FORTUNE JACKPOT',
    introductionBt4Jpjj1: 'GOLDEN BULL',
    introductionBt4Jpjj2: '* Lottery drawn every day, in 13 positions A-M of [Special Prize], position A is designated as reward',
    introductionBt4Jpjj3: '* When drawing the First, Second and Third prizes, if the position A number is drawn as the first, Second and Third Prize, You can win another phone',
    introductionBt4Jpjj4: '* Each group of numbers requires a minimum bet of RM2.00',
    introductionBt4Jpjj5: '* ibox and 3D are not included in this game',
    introductionBt4Jpjj6: 'Reward a mobile phone: Samsung Galaxy A71, realme 7Pro, Samsung Galaxy A42, Oppo Reno 4',
  },
  cn: {
    jackpot: '积宝',
    sarawak: '砂拉越大万',
    sabah: '沙巴万字',
    sandakan: '山打根赛马场',
    GdToto: '豪龙彩票',
    america: '美国',
    singapore: '新加坡',
    toto: '多多',
    damacai: '大马彩',
    magnum: '万能',
    first: '首奖',
    second: '二奖',
    third: '三奖',
    fourth: '四奖',
    fifth: '五奖',
    sixth: '六奖',
    seventh: '七奖',
    special: '特别奖',
    consolation: '安慰奖',
    winningnumber: '中奖号码',
    specialNumber: '特别号码',
    introduction: '万字介绍与派彩结构',
    introduction4D: '4D ( 每投注RM1 )',
    introduction4D1: '在这游戏中,你将赢取奖金如你投注的号码与开彩号码匹配。',
    introductionPrize: '奖项',
    introductionWinCondition: '获胜条件',
    introductionCashPrize: '奖金',
    introductionBig: '大',
    introductionSmall: '小',
    first2: '头奖',
    introduction4D2: '4D 全保 ( 每投注RM1 )',
    introduction4D3: '在全保, 你将赢取奖金如你投注的号码(任何排列)与开彩号码匹配。',
    introduction4D4: '赔率对你有利得多, 奖金会依据排列的数量而不同。',
    introductionQB24: '全保 24 (24 翻)',
    introductionQB12: '全保 12 (12 翻)',
    introductionQB6: '全保 6 (6 翻)',
    introductionQB4: '全保 4 (4 翻)',
    introduction4D5: '4D 积宝 (每投注RM2)',
    introduction4D6: '这是原来4D游戏的延伸,奖金高达数百万!',
    introductionBt4Jp1: '* 每张单最多30条字，每个字最少RM2大万',
    introductionBt4Jp2: '* IBOX 及 3D 不包括在内，每张单只能赢得1份奖',
    introduction4D7: '1. 挑选两组4位数字的号码(从0000 到 9999),例子 (1188, 2288), (3388, 2233), 或(1338, 8868)。',
    introduction4D8: '2. 每一组的号码视为一个普通投注。',
    introduction4D9: '3. 一个普通投注最低金额为RM2 (或其倍数)。',
    introduction4D10: '你赢如你挑选的一组或是两组4D号码与23个开彩的其中一个或多个号码相匹配。',
    introductionJP1: '积宝 1 奖',
    introductionJP1W: '所选配对与头奖,第二或第三个奖项中的任何两个匹配。',
    introductionJPCP1: '积宝1 (最少RM 2,000,000) 没有上限',
    introductionJPCP2: '注: 累积到积宝2,',
    introductionJPCP3: '如果积宝1超过RM3000万。',
    introductionJP2: '积宝 2 奖',
    introductionJP2W: '所选配对与头奖,二或三等奖中的任何一个相匹配,并与十个特别奖中的任何一个匹配。',
    introductionJP2CP: '积宝2 (最少RM 100,000) 没有上限',
    introductionG3: '组 3 奖',
    introductionJP3W: '所选配对的其中一组号码与前三奖中的一个相匹配',
    introductionJP3W2: '(头奖,二奖或是三奖)。',
    introductionJP4W: '	所选配对的其中一组号码与十个特别奖中的一个匹配。',
    introductionJP5W: '所选配对的其中一组号码与十个安慰奖中的一个匹配。',
    introductionG4: '组 4 奖',
    introductionG5: '组 5 奖',
    introductionJPCPLimit: '(上限 RM 10,000,000)',
    introduction4D11: '4D 积宝M-系统',
    introduction4D12: '总而言之,这是一个更实惠的游戏,10个号码, 最低投注为10令吉。',
    introduction4D13: '您可选择高达50个号码RM50。当然,你也可以随心所欲地选择幸运分拣,滚动或排列!',
    introduction4D14: 'M-系统',
    introduction4D15: '前三奖中的两个',
    introduction4D16: '前三奖中的一个 + 一个特别奖',
    introduction4D17: '前三奖中的一个',
    introduction4D18: '一个特别奖',
    introduction4D19: '一个安慰奖',
    introduction4D20: '积宝1的1/9',
    introduction4D21: '积宝2的1/9',
    introduction4D22: '积宝1的1/19',
    introduction4D23: '积宝2的1/19',
    introduction4D24: '积宝1的1/49',
    introduction4D25: '积宝2的1/49',
    introduction4D26: '4D 金积宝',
    introduction4D27: '4D金积宝涉及更多的数字和一个单独的数百万令吉大奖的奖品!',
    introduction4D28: '1.  挑选一个6位数字组合 – 3x 2位数字的号码(从 00 到 99) 例子. 220278。',
    introduction4D29: '2. 挑选一个2位数字组合 – 1x 2位数字的号码(从00 到 19) 例子. 11。',
    introduction4D30: '3. 投注最低金额为RM2 (或其倍数) 。',
    introduction4D31: '你将获胜如你所挑选的6位数字组合与开彩的6位数字组合相匹配, ',
    introduction4D32: '和/或你所挑选的2位数字组合与开彩的2位数字组合也相匹配。',
    introduction4D33: '赢金积宝1 如你的6位数字号码和金色号码与金积宝开彩号码完全匹配。',
    introduction4D34: '赢金积宝2 如:',
    introduction4D35: '- 你的6位数字号码与开彩的6位数字组合前5位或是后5位数字相匹配。',
    introduction4D36: '- 和你的金色号码与金积宝开彩号码相匹配。',
    introduction4D37: '赢三奖如你的6位数字号码与金积宝6位数字开彩号码完全匹配。',
    introduction4D38: '赢四奖如你的6位数字号码与金积宝6位数字开彩号码的前5位或是后5位数字相匹配。',
    introduction4D39: '赢五奖如你的6位数字号码与金积宝6位数字开彩号码的前4位或是后4位数字相匹配。',
    introduction4D40: '赢六奖如你的6位数字号码与金积宝6位数字开彩号码的前3位或是后3位数字相匹配。',
    introduction4D41: '赢七奖如你的6位数字号码与金积宝6位数字开彩号码的前2位或是后2位数字相匹配。',
    introductionMin2m: '(最少RM 2,000,000)',
    introductionMin100k: '(最少RM 100,000)',
    introductionEvery2: '(每投注RM2)',
    introduction4D42: '4D 积宝强力球',
    AmountWon: '赢得的金额',
    Prizes: '奖项',
    introduction4Dpatern: '获胜模式',
    introduction4D43: '4D 强力球与4D积宝相像,只是加了另外两个强力球号码。',
    introduction4D44: '1. 挑选一个4位数字的号码(从0000 到 9999), 例子. 3456',
    introduction4D45: '2. 挑选 2 x 2位数字的强力球号码(从00 到 99), 例子. 12 和 78',
    introduction4D46: '3. 投注最低金额为RM2 (或其倍数)',
    introduction4D47: '你将获胜如你所挑选的4位数字号码与开彩的前三奖任何一个相匹配,和/或你所挑选的强力球号码与开彩强力球号码相匹配。',
    introduction4D48: '赢强力球积宝1, 头奖如:',
    introduction4D49: '- 你的4位数字号码必须与4D头奖相匹配',
    introduction4D50: '- 你的强力球号码必须与开彩的两个强力球号码完全匹配',
    introduction4DLowest2m: '(保证最低RM 2,000,000)',
    introduction4DLowest100k: '(保证最低RM 100,000)',
    introduction4D53: '赢强力球积宝1, 二奖如:',
    introduction4D54: '- 你的4位数字号码必须与4D二奖相匹配',
    introduction4D55: '- 你的强力球号码必须与开彩的两个强力球号码完全匹配',
    introduction4D56: '赢强力球积宝1, 三奖如:',
    introduction4D57: '- 你的4位数字号码必须与4D三奖相匹配',
    introduction4D58: '- 你的强力球号码必须与开彩的两个强力球号码完全匹配',
    introduction4D59: '赢强力球积宝2 如:',
    introduction4D60: '- 你的4位数字号码必须与4D特别奖或是安慰奖的其中一个相匹配。',
    introduction4D61: '- 你的强力球号码必须与开彩的两个强力球号码完全匹配',
    introduction4D62: '赢三奖如你的4位数字号码与4D开彩的头奖,二奖或是三奖相匹配。',
    introduction4D63: '赢四奖如你的4位数字号码与4D开彩的特别奖或是安慰奖相匹配。',
    introduction4D64: '赢五奖如你的强力球号码与开彩任何一个强力球号码相匹配。',
    introductionDMC1: '3D ( 每投注RM1 )',
    introductionDMC2: '只需从000 - 999 中随意选择任何3个数字',
    introductionDMC3: '投注最低金额RM1 至ABC 或A',
    introductionDMC4: '1+3D( 每投注RM1 )',
    introductionDMC5: '1. 玩1 + 3D游戏,从0000到9999中选择你最喜欢的或幸运的四位数字。',
    introductionDMC6: '2. 然后决定你要投注的类型,例子"ABC" (大) 或 "A" (小); 或你可投注两个。',
    introductionDMC7: '3. "ABC" (大) 可以你增加获胜的机会因为有23个中奖号码, 例子,头奖,二奖,三奖,10 个特别奖和10个安慰奖。',
    introductionDMC8: '4. "A" (小) 赔更高的奖金,但只有三个中奖号码匹配,例子,头奖,二奖和三奖。',
    introductionDMC9: '5. 最后,为每个选定的号码决定要投注的金额。1+3D号码的最低投注金额为RM1。',
    introductionDMC10: '1+3D 全保 ( 每投注RM1 )',
    introductionDMC11: '1. 玩Super 1 + 3D游戏,从0000到9999中选择你最喜欢的或幸运的四位数字。',
    introductionDMC12: '2. Super 1+3D 游戏有七个奖项,包括 1ST, 2ND, 3RD, STA, CON, TP3 和 ALL。',
    introductionDMC13: '3. 每组投注的四位数字号码, 一个奖项会被选中,并附加到所选的四位数字号码。',
    introductionDMC14: '4. Super 1 + 3D只有一个类型,因此,Super 1 + 3D无需A或ABC类型。',
    introductionDMC15: '5. 最后,为每个选定的号码决定要投注的金额。Super 1+3D号码的最低投注金额为RM1 或 RM1 的倍数。 ',
    introductionDMC16: '你挑选的奖项和号码与其对应的1+3D头奖开彩号码相匹配。',
    introductionDMC17: '你挑选的奖项和号码与其对应的1+3D二奖开彩号码相匹配。',
    introductionDMC18: '你挑选的奖项和号码与其对应的1+3D三奖开彩号码相匹配。',
    introductionDMC19: '你挑选的奖项和号码与其对应的1+3D特别奖任何开彩号码相匹配。',
    introductionDMC20: '你挑选的奖项和号码与其对应的1+3D安慰奖任何开彩号码相匹配。',
    introductionDMC21: '你挑选的奖项和号码与其对应的1+3D头奖,二奖或三奖开彩号码相匹配。',
    introductionDMC22: '你挑选的奖项和号码与其对应的1+3D头奖,二奖,三奖,特别奖或安慰奖任何开彩号码相匹配。',
    introductionDMC23: 'Super 1+3D ( 每投注RM1 )',
    introductionDMC24: '1st, 2nd 或 3rd - RM 1300',
    introductionDMC25: 'Super 1+3D 全保 ( 每投注RM1 )',
    introductionDMC26: '1+3D 积宝( 每投注RM2 )',
    introductionDMC27: 'DMC 积宝( 每投注RM2 )',
    introductionDMC28: '1. 从0000到9999中挑选一组四位数字的号码和从000到999中挑选一组三位数字的号码。',
    introductionDMC29: '2. 每一组的号码视为一个普通投注。',
    introductionDMC30: '3. 一个普通投注最低金额为RM2 (或其倍数)。',
    introductionDMC31: '获胜的先决条件',
    introductionDMCHighestCashPrize: '最高彩金',
    introductionDMCNoLimit: '没有上限',
    introductionDMC33: '你挑选的两组号码与1+3D头奖以及1+3D二奖相匹配。',
    introductionDMC34: '你挑选的两组号码与以下的相匹配',
    introductionDMC35: '- 1+3D二奖以及3D头奖或',
    introductionDMC36: '- 1+3D三奖以及3D头奖或',
    introductionDMC37: '- 1+3D二奖以及3D三奖或',
    introductionDMC38: '- 1+3D三奖以及3D二奖或',
    introductionDMC39: '- 1+3D头奖以及3D 三奖',
    introductionDMC40: '你挑选的1+3D 号码与1+3D头奖,二奖或是三奖开彩号码相匹配。',
    introductionDMC41: '你挑选的1+3D 号码与1+3D特别奖开彩号码其中之一相匹配。',
    introductionDMC42: '你挑选的1+3D 号码与1+3D安慰奖开彩号码其中之一相匹配。',
    introductionDMC43: '你挑选的3D 号码与3D头奖,二奖或是三奖开彩号码相匹配。',
    introduction4DLowest18m: '(保证最低RM 1,800,000)',
    introductionDMC44: '3D 积宝( 每投注RM2 )',
    introductionDMC45: '1. 从000到999中挑选三组3D号码形成3D号码组合,例子333 + 488 + 826',
    introductionDMC46: '2. 每一组的号码视为一个普通投注。',
    introductionDMC47: '3. 一个普通投注最低金额为RM2 (或其倍数)。',
    introduction4DLowest600k: '(最少RM 600,000)',
    introductionDMC48: '所有三组3D号码与3D前三奖的开彩号码相匹配(任何顺序)。',
    introductionDMC49: '任何两组3D号码与3D前三奖(任何两个)的开彩号码相匹配的开彩号码相匹配(任何顺序)。',
    introductionDMC50: '任何一组3D号码与3D前三奖(任何两个)的开彩号码相匹配的开彩号码相匹配(任何顺序)。',
    introductionTOTO1: '5D',
    introductionTOTO2: '从00000到99999中挑选五位数字的号码',
    introductionTOTO3: '投注最低RM1金额',
    introductionTOTO4: '五位数字号码开在头奖',
    introductionTOTO5: '五位数字号码开在二奖',
    introductionTOTO6: '五位数字号码开在三奖',
    introductionTOTO7: '头奖的后四位数字',
    introductionTOTO8: '头奖的后三位数字',
    introductionTOTO9: '头奖的后两位数字',
    introductionTOTO10: '* 每个中奖号码只能获得一个奖。',
    introductionTOTO11: '6D',
    introductionTOTO6D2: 'Pick 6-Digit number from 00000 – 99999',
    introductionTOTO12: '六位数字号码开在头奖',
    introductionTOTO13: '头奖的前五位数字或后五位数字',
    introductionTOTO14: '头奖的前四位数字或后四位数字',
    introductionTOTO15: '头奖的前三位数字或后三位数字',
    introductionTOTO16: '头奖的前两位数字或后两位数字',
    introductionTOTO17: '星运多多 6/50 (每投注RM 1 奖金)',
    introductionTOTO18: '从1- 50中挑选六个号码(号码不重复)',
    introductionTOTO19: '投注最低RM1金额',
    introduction4DLowest1188k: '(最少RM 1,188,888)',
    introductionTOTO20: '六个号码全匹配',
    introductionTOTO21: '任何五个号码匹配 + Bonus 号码',
    introductionTOTO22: '任何五个号码匹配',
    introductionTOTO23: '任何四个号码匹配 + Bonus 号码',
    introductionTOTO24: '任何四个号码匹配',
    introductionTOTO25: '任何三个号码匹配 + Bonus 号码',
    introductionTOTO26: '任何三个号码匹配',
    introductionTOTO27: '* 如果有多个赢家,积宝则根据每位获胜者下注的金额分配。',
    introductionTOTO28: '至尊多多 6/55 (每投注RM 1 奖金)',
    introductionTOTO29: '从1- 55中挑选六个号码(号码不重复)',
    introductionTOTO30: '投注最低RM1金额',
    introductionTOTO31: '六个号码全匹配',
    introductionTOTO32: '任何五个号码匹配',
    introductionTOTO33: '任何四个号码匹配',
    introductionTOTO34: '任何三个号码匹配',
    introductionTOTO35: '* 如果有多个赢家,积宝则根据每位获胜者下注的金额分配。',
    introduction4DLowest3m: '(最少RM 3,000,000)',
    introductionTOTO36: '好运多多 6/58 (每投注RM 2奖金)',
    introductionTOTO37: '从1- 58中挑选六个号码(号码不重复)',
    introductionTOTO38: '投注最低RM2金额',
    introduction4DLowest888k: '(最少RM 8,888,888)',
    introductionSG1: '4D (每投注SGD1)',
    introductionSG2: '4D iBet (每投注SGD1)',
    introductionSG3: '在iBet, 你将赢取奖金如你投注的4D号码(任何排列)与开彩号码匹配。',
    introductionSG4: '赔率对你有利得多, 奖金会依据排列的数量而不同。',
    introductionSG5: 'iBet 24 (24 翻)',
    introductionSG6: 'iBet 12 (12 翻)',
    introductionSG7: 'iBet 6 (6 翻)',
    introductionSG8: 'iBet 4 (4 翻)',
    introductionSG9: 'Toto 6/49 (每投注SGD1)',
    introductionSG10: '从1- 49中挑选六个号码 (号码不重复)',
    introductionSG11: '投注最低SGD 1金额',
    introductionSG12: '六个号码全匹配',
    introductionSG13: '任何五个号码匹配加额外号码',
    introductionSG14: '任何五个号码匹配',
    introductionSG15: '任何四个号码匹配加额外号码',
    introductionSG16: '任何四个号码匹配',
    introductionSG17: '任何三个号码匹配加额外号码',
    introductionSG18: '任何三个号码匹配',
    introductionSG19: '奖池的38%',
    introductionSG20: '奖池的8%',
    introductionSG21: '奖池的5.5%',
    introductionSG22: '奖池的3%',
    introductionSG23: '* 保证积宝至少有SGD 1,000,000。',
    introductionSG24: '* 如果有多于一个的获胜者,奖金将平等分配。这意味着奖金数额将在获胜者中平均分配。平等分配仅适用于第1,2,3,4组。',
    introductionSBH1: 'Lotto 6/45 (每投注 RM 1 奖金)',
    introductionSBH2: '从1- 45中挑选六个号码(号码不重复)',
    introduction4DLowest500k: '(最少RM 500,000)',
    introduction4DLowest5k: '(最少RM 5,000)',
    secondp: '二奖',
    thirdp: '三奖',
    introductionBt4Jp3: '特别奖二组或安慰奖二组',
    introductionBt4Jp4: '开奖号码其中二组',
    introductionBt4Jp5: 'RM168,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp6: 'RM118,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp7: 'RM68,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp8: '* 如有任何争议，BOOMSTOWN 公可拥有最后决定权.',
    introductionBt4Jp9: '小富尊积宝',
    introductionBt4Jpjj1: '金牛奖',
    introductionBt4Jpjj2: '* 在[特别奖]的13个位置 A-M， 指定位置 A 为奖励',
    introductionBt4Jpjj3: '* 如果您下注位置 A 号码 赢得头奖或二奖或三奖， 就可另外赢得手机一部。',
    introductionBt4Jpjj4: '* 每组号码需要下注最少 RM2 大万',
    introductionBt4Jpjj5: '* ibox 和 3D 不包括在此游戏内。',
    introductionBt4Jpjj6: '奖励手机为：SAMSUNG GALAXY A71, REALME 7 PRO, XIAOMI MI 10T, OPPO RENO 4',
  },
  Malay: {
    jackpot: 'Jackpot',
    sarawak: 'Sarawak Cashsweep',
    sabah: 'Sabah88',
    sandakan: 'Sandakan',
    GdToto: 'Grand Dragon Lotto',
    america: 'Amerika',
    singapore: 'Singapore',
    toto: 'SportsToto',
    damacai: 'Damacai',
    magnum: 'Magnum',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'fourth',
    fifth: 'fifth',
    sixth: 'sixth',
    seventh: 'seventh',
    special: 'Special',
    consolation: 'Consolation',
    introduction: 'Struktur Pengenalan & Pembayaran 4D',
    introduction4D: '4D (Setiap pertaruhan RM1)',
    introduction4D1: 'Dalam permainan ini, anda menang apabila nombor 4D anda sepadan dengan salah satu nombor kemenangan yang dilukis.',
    introductionPrize: 'Kategori Hadiah',
    introductionWinCondition: 'Bagaimana untuk menang',
    introductionCashPrize: 'Wang Hadiah',
    introductionBig: 'Besar',
    introductionSmall: 'Kecil',
    first2: 'Hadiah ke-1',
    introduction4D2: '4D mBox ( untuk setiap pembelian RM1 )',
    introduction4D3: 'Dalam mBox, anda menang apabila SETIAP permutasi nombor 4D anda sepadan dengan salah satu nombor yang menang.',
    introduction4D4: 'Kemungkinan lebih banyak memihak kepada anda, dan pembayaran berbeza mengikut jumlah permutasi.',
    introductionQB24: 'mBox 24',
    introductionQB12: '	mBox 12',
    introductionQB6: '	mBox 6',
    introductionQB4: '	mBox 4',
    introduction4D5: '4D Jackpot ( untuk setiap pembelian RM2 )',
    introduction4D6: 'Naik taraf dengan Jackpot 4D, lanjutan dari permainan 4D yang asal dengan hadiah berjuta-juta ringgit!',
    introduction4D7: '1. Pilih dua nombor 4 digit dari 0000 hingga 9999, e.g. (1188, 2288), (3388, 2233), or (1338, 8868).',
    introduction4D8: '2. Setiap pasangan nombor dianggap sebagai 1 Pembelian Biasa.',
    introduction4D9: '3. Tempatkan 1 Pembelian Biasa dengan minimum RM2 (atau darabnya).',
    introduction4D10: 'Anda menang apabila satu atau kedua-dua nombor 4D anda sepadan dengan satu atau lebih daripada 23 nombor kemenangan yang ditarik.',
    introductionJP1: 'Jackpot 1 Prize',
    introductionJP1W: 'Pasangan yang dipilih sesuai dengan mana-mana dua dari Hadiah 1, 2 atau 3.',
    introductionJPCP1: 'Jackpot 1 (min RM 2,000,000) Tiada had Maksimum',
    introductionJPCP2: 'Catatan: Cascading ke Jackpot 2 bermula',
    introductionJPCP3: 'setiap kali Jackpot 1 melebihi RM 30 juta',
    introductionJP2: 'Jackpot 2 Prize',
    introductionJP2W: 'Pasangan yang dipilih sesuai dengan mana-mana yang pertama, Hadiah ke-2 atau ke-3 dan salah satu daripada 10 Hadiah Permulaan.',
    introductionJP2CP: 'Jackpot 2 (min RM 100,000) Tiada had Maksimum',
    introductionG3: 'Hadiah Kumpulan 3',
    introductionJP3W: 'Mana-mana satu nombor ',
    introductionJP3W2: 'Hadiah 1, 2 atau 3.',
    introductionG4: 'Hadiah Kumpulan 4',
    introductionJP4W: 'Mana-mana satu nombor sepadan dengan 10 Hadiah Permulaan.',
    introductionJP5W: 'Mana-mana satu nombor sepadan dengan 10 Hadiah Permulaan.',
    introductionG5: 'Hadiah Kumpulan 5',
    introductionJPCPLimit: 'Maksimum RM 10,000,000',
    introduction4D11: '4D Jackpot M-System',
    introduction4D12: 'Semua dalam semua, ia adalah permainan yang lebih berpatutan dengan pembelian minimum RM10 untuk 10 nombor.',
    introduction4D13: 'Anda boleh memilih hingga 50 nombor dengan harga RM50. Dan sudah tentu, anda mempunyai pilihan untuk Lucky Pick, Gulung atau Permutasi mengikut kehendak anda!',
    introduction4D14: 'M-System',
    introduction4D15: '	2 daripada 3 Hadiah teratas',
    introduction4D16: '1 daripada Hadiah SPE 3 + 1 teratas',
    introduction4D17: '1 daripada 3 Hadiah teratas',
    introduction4D18: '1 Hadiah SPE',
    introduction4D19: '1 Hadiah CON',
    introduction4D20: '1/9 dari Jackpot 1',
    introduction4D21: '1/9 dari Jackpot 2',
    introduction4D22: '1/19 dari Jackpot 1',
    introduction4D23: '1/19 dari Jackpot 2',
    introduction4D24: '1/49 dari Jackpot 1',
    introduction4D25: '1/49 dari Jackpot 2',
    introduction4D26: '4D Jackpot Gold',
    introduction4Dpatern: 'Corak Menang',
    Prizes: 'Hadiah',
    AmountWon: 'Jumlah Dimenangi',
    introduction4D27: '4D Jackpot GOLD meningkatkan faktor keseronokan, dengan lebih banyak bilangan yang terlibat dan hadiah jackpot berjuta-juta ringgit yang berasingan!',
    introduction4D28: '1. Pilih komponen 6 digit – 3x Nombor 2 digit (dari 00 hingga 99) cth. 220278.',
    introduction4D29: '2. Pilih komponen 2 digit – 1x Nombor 2 digit (dari 00 hingga 19) cth. 11.',
    introduction4D30: '3. Beli dengan minimum RM2 (atau gandaan).',
    introduction4D31: 'Anda menang apabila komponen 6 digit yang anda pilih sepadan dengan komponen 6 digit yang dilukis, ',
    introduction4D32: 'dan / atau komponen 2 digit yang anda pilih juga sepadan dengan komponen 2 digit yang dilukis.',
    introduction4D33: 'Menangkan Jackpot Gold 1 jika setiap 6 digit dan EMAS nombor anda sama dengan nombor pemenang Jackpot Gold.',
    introduction4D34: 'Menangkan Jackpot Gold 2 jika:',
    introduction4D35: '- Umber 6 digit anda sesuai dengan 5 nombor pertama atau 5 terakhir dalam komponen 6 Digit',
    introduction4D36: '- DAN nombor EMAS anda sepadan dengan kedua-dua nombor dalam komponen EMAS dalam nombor pemenang Jackpot Gold.',
    introduction4D37: 'Menangi Hadiah ke-3 jika nombor 6 Digit anda sama persis dengan komponen 6 Digit dari nombor pemenang Jackpot Gold.',
    introduction4D38: 'Menangi Hadiah ke-4 jika nombor 6 Digit anda sepadan dengan 5 nombor pertama atau 5 terakhir dalam komponen 6 Digit dari nombor pemenang Jackpot Gold.',
    introduction4D39: 'Menangi Hadiah ke-5 jika nombor 6 Digit anda sepadan dengan 4 nombor terakhir atau 4 nombor terakhir dalam komponen 6 Digit dari nombor pemenang Jackpot Gold.',
    introduction4D40: 'Menangi Hadiah ke-6 jika nombor 6 Digit anda sepadan dengan 3 nombor pertama atau 3 nombor terakhir dalam komponen 6 Digit dari nombor pemenang Jackpot Gold.',
    introduction4D41: '	Win 7th Prize if your 6 Digits numbers match the first 2, middle 2 and last 2 numbers in the 6 Digits component of the Jackpot Gold winning number.',
    introductionMin2m: '(dijamin minimum RM 2,000,000)',
    introductionMin100k: '(dijamin minimum RM 100,000)',
    introductionEvery2: '(untuk setiap pembelian RM2)',
    introduction4D42: '4D Jackpot Powerball',
    introduction4D43: '4D Powerball adalah satu lagi pengambilan Jackpot 4D klasik, hanya dengan tambahan 2 nombor Powerball.',
    introduction4D44: '1. Pilih nombor 4 digit dari 0000 hingga 9999, mis. 3456',
    introduction4D45: '2. Pilih 2 x 2-digit Powerball nombor dari 00 to 99, e.g. 12 dan 78',
    introduction4D46: '3. Beli dengan minimum RM2 (atau darabnya).',
    introduction4D47: 'Anda menang apabila nombor 4 digit anda sepadan dengan mana-mana 3 hadiah teratas, dan / atau nombor Powerball sepadan dengan nombor Powerball yang dilukis.  ',
    introduction4D48: 'Menangkan Powerball Jackpot 1, Hadiah Pertama jika:',
    introduction4D49: '- 4 digit anda mesti sepadan dengan nombor pemenang Hadiah Pertama 4D.',
    introduction4D50: '- Nombor Powerball anda sama persis dengan kedua-dua nombor Powerball yang menang dalam urutan apa pun.',
    introduction4DLowest2m: '(dijamin minimum RM 2,000,000)',
    introduction4DLowest100k: '(dijamin minimum RM 100,000)',
    introduction4D53: 'Menangkan Powerball Jackpot 1, Hadiah ke-2 jika:',
    introduction4D54: '- 4 digit anda mesti sepadan dengan nombor pemenang Hadiah 2D ke-2.',
    introduction4D55: '- Nombor Powerball anda sama persis dengan kedua-dua nombor Powerball yang menang dalam urutan apa pun.',
    introduction4D56: 'Menangkan Powerball Jackpot 1, Hadiah ke-3 jika:',
    introduction4D57: '- 4 digit anda mesti sepadan dengan nombor pemenang Hadiah 3D ke-4.',
    introduction4D58: '- Nombor Powerball anda sama persis dengan kedua-dua nombor Powerball yang menang dalam urutan apa pun.',
    introduction4D59: '	Menangkan Jackpot Powerball 2, jika:',
    introduction4D60: '- 4 digit anda mesti sesuai dengan mana-mana nombor pemenang Hadiah Khas atau Hadiah Saguhati 4D.',
    introduction4D61: '- Nombor Powerball anda sama persis dengan kedua-dua nombor Powerball yang menang dalam urutan apa pun.',
    introduction4D62: 'Menangi Hadiah ke-3 jika mana-mana 4 Digit anda sepadan dengan nombor pemenang Hadiah 4D 1, 2 atau 3.',
    introduction4D63: 'Menangi Hadiah ke-4 jika mana-mana 4 Digit anda mesti sepadan dengan nombor pemenang Hadiah Khas 4D atau Hadiah Saguhati.',
    introduction4D64: 'Menangi Hadiah ke-5 jika mana-mana nombor Powerball anda sepadan dengan salah satu nombor pemenang Powerball.',
    introductionDMC1: '3D ( untuk setiap pembelian RM1 )',
    introductionDMC2: 'Cukup pilih mana-mana nombor 3-Digit dari 000 - 999',
    introductionDMC3: 'Letakkan taruhan minimum RM1 pada ABC atau A',
    introductionDMC4: '1+3D ( untuk setiap pembelian RM1)',
    introductionDMC5: '1. Untuk bermain permainan 1 + 3D, pilih nombor empat digit kegemaran atau bertuah anda dari 0000 hingga 9999.',
    introductionDMC6: '2. Kemudian tentukan jenis pertaruhan anda iaitu "ABC" (Besar) atau "A" (Kecil); atau anda boleh bermain kedua-duanya.',
    introductionDMC7: '3. "ABC" (Besar) meningkatkan peluang anda untuk menang kerana terdapat 23 nombor pemenang untuk dipadankan iaitu Hadiah 1, Hadiah 2, Hadiah ke-3, 10 Hadiah Permulaan dan 10 Hadiah Saguhati.',
    introductionDMC8: '4. "A" (Kecil) membayar hadiah kemenangan yang lebih tinggi tetapi hanya ada tiga nombor pemenang untuk dipadankan iaitu Hadiah Pertama, Hadiah Kedua dan Hadiah ke-3.',
    introductionDMC9: '5. Akhirnya, tentukan jumlah pertaruhan anda untuk setiap nombor yang dipilih. Jumlah pertaruhan minimum untuk nombor 1 + 3D ialah RM1.',
    introductionDMC10: '1+3D iBox ( untuk setiap pembelian RM1 )',
    introductionDMC11: '1. Untuk bermain permainan Super 1 + 3D, pilih nombor empat digit kegemaran atau bertuah anda dari 0000 hingga 9999.',
    introductionDMC12: '2. Permainan Super 1 + 3D mempunyai 7 Kategori Hadiah; terdiri daripada 1ST, 2ND, 3RD, STA, CON, TP3 dan ALL.',
    introductionDMC13: '3. Untuk setiap pertaruhan nombor empat digit, Kategori Hadiah dipilih dan dilampirkan pada nombor empat digit yang dipilih.',
    introductionDMC14: '4. Hanya satu jenis kolam untuk Super 1 + 3D yang tersedia, kerana itu, kolam A atau ABC untuk Super 1 + 3D tidak diperlukan.',
    introductionDMC15: '5. Akhirnya, tentukan jumlah pertaruhan anda untuk setiap nombor yang dipilih. Jumlah pertaruhan minimum untuk Super 1 + 3D adalah RM1 atau dalam gandaan RM1.',
    introductionDMC16: 'Kategori dan nombor hadiah yang anda pilih sepadan dengan kategori hadiah pertama dan nombor kemenangannya yang sesuai dari cabutan 1 + 3D yang berkaitan.',
    introductionDMC17: 'Kategori dan nombor hadiah yang anda pilih sepadan dengan kategori hadiah ke-2 dan nombor kemenangannya yang sepadan dengan cabutan 1 + 3D yang berkaitan.',
    introductionDMC18: 'Kategori dan nombor hadiah yang anda pilih sepadan dengan kategori hadiah ke-3 dan nombor kemenangannya yang sesuai dari cabutan 1 + 3D yang berkaitan.',
    introductionDMC19: 'Kategori dan nombor hadiah yang anda pilih sepadan dengan kategori hadiah Permulaan dan mana-mana nombor kemenangannya yang sesuai dari cabutan 1 + 3D yang berkaitan.',
    introductionDMC20: 'Kategori dan nombor hadiah yang anda pilih sepadan dengan kategori hadiah Saguhati dan mana-mana nombor kemenangannya yang sesuai dari cabutan 1 + 3D yang berkaitan.',
    introductionDMC21: 'Kategori dan nombor hadiah yang anda pilih sesuai dengan kategori hadiah 1, 2 atau 3 dan nombor kemenangannya yang sesuai dari cabutan 1 + 3D yang berkaitan.',
    introductionDMC22: 'Kategori dan nombor hadiah yang anda pilih sepadan dengan kategori hadiah 1, 2, 3, Starter atau Saguhati dan mana-mana nombor kemenangan cabutan 1 + 3D yang berkaitan.',
    introductionDMC23: '1+3D ( untuk setiap pembelian RM1 )',
    introductionDMC24: '1st, 2nd atau 3rd - RM 1300',
    introductionDMC25: '1+3D iBox ( untuk setiap pembelian RM1)',
    introductionDMC26: '1+3D Jackpot ( untuk setiap pembelian RM2)',
    introductionDMC27: 'DMC Jackpot ( untuk setiap pembelian RM2 )',
    introductionDMC28: '1. Pilih satu nombor 4 digit dari 0000 hingga 9999 dan satu nombor 3 digit dari 000 hingga 999',
    introductionDMC29: '2. Setiap pasangan nombor dianggap sebagai 1 Pembelian Biasa.',
    introductionDMC30: '3. Tempatkan 1 Pembelian Biasa dengan minimum RM2 (atau gandaan daripadanya).',
    introductionDMC31: 'Pra-Syarat untuk Menang',
    introductionDMCHighestCashPrize: 'Pembayaran Hadiah Maksimum',
    introductionDMCNoLimit: 'TIADA HAD',
    introductionDMC33: 'Pasangan pilihan anda sepadan dengan keputusan 1 + 3D 1st Prize dan 3D 2nd Prize.',
    introductionDMC34: 'Pasangan pilihan anda sepadan dengan hasil dari',
    introductionDMC35: '- 1+3D 2nd Hadiah dan Hadiah Pertama 3D atau',
    introductionDMC36: '- 1+3D 3rd Hadiah dan Hadiah Pertama 3D atau',
    introductionDMC37: '- 1+3D 2nd Hadiah dan Hadiah ke-3 3D atau',
    introductionDMC38: '- 1+3D 3rd Hadiah dan Hadiah ke-2 3D atau',
    introductionDMC39: '- 1+3D 1st Hadiah dan Hadiah ke-3 3D.',
    introductionDMC40: 'Nombor 1 + 3D yang anda pilih sesuai dengan mana-mana Hadiah 1, 2 atau 3 hasil 1 + 3D.',
    introductionDMC41: 'Nombor 1 + 3D yang anda pilih sesuai dengan mana-mana Hadiah Permulaan hasil 1 + 3D.',
    introductionDMC42: 'Nombor 1 + 3D yang anda pilih sesuai dengan mana-mana Hadiah Saguhati hasil 1 + 3D.',
    introductionDMC43: 'Nombor 3D yang anda pilih sesuai dengan mana-mana Hadiah 1, 2 atau 3 hasil 3D.',
    introduction4DLowest18m: '(dengan minimum RM 1,800,000)',
    introductionDMC44: '3D Jackpot ( untuk setiap pembelian RM2 )',
    introductionDMC45: '1. Pilih tiga set nombor 3D dari 000 hingga 999 untuk membentuk komposisi nombor 3D, mis. 333 + 488 + 826',
    introductionDMC46: '2. Setiap pasangan nombor dianggap sebagai 1 Pembelian Biasa.',
    introductionDMC47: '3. Tempatkan 1 Pembelian Biasa dengan minimum RM2 (atau gandaan daripadanya).',
    introduction4DLowest600k: '(dengan minimum RM 600,000)',
    introductionDMC48: 'Ketiga-tiga set nombor 3D sesuai dengan 3 Hadiah Teratas dalam urutan hasil 3D.',
    introductionDMC49: 'Mana-mana dua set nombor 3D sepadan dengan dua Hadiah 3 Teratas dalam urutan hasil 3D.',
    introductionDMC50: 'Mana-mana satu set nombor 3D sesuai dengan salah satu daripada Hadiah 3 Teratas dalam urutan hasil 3D.',
    introductionTOTO1: '5D',
    introductionTOTO2: 'Pilih nombor 5-Digit dari 00000 - 99999',
    introductionTOTO3: 'Letakkan taruhan minimum RM1',
    introductionTOTO4: '5 digit diambil sebagai Hadiah Pertama',
    introductionTOTO5: '5 digit diambil sebagai Hadiah ke-2',
    introductionTOTO6: '5 digit dilukis sebagai Hadiah ke-3',
    introductionTOTO7: '4 digit terakhir Hadiah 1',
    introductionTOTO8: '3 digit terakhir Hadiah 1',
    introductionTOTO9: '2 digit terakhir Hadiah 1',
    introductionTOTO10: '* Setiap nombor yang menang hanya berhak mendapat satu hadiah sahaja.',
    introductionTOTO11: '6D',
    introductionTOTO6D2: 'Pilih nombor 6-Digit dari 00000 - 99999',
    introductionTOTO12: '6 digit dilukis sebagai Hadiah Pertama',
    introductionTOTO13: '5 digit pertama atau 5 digit terakhir Hadiah Pertama',
    introductionTOTO14: '4 digit pertama atau 4 digit terakhir Hadiah 1',
    introductionTOTO15: '3 digit pertama atau 3 digit terakhir dari Hadiah Pertama',
    introductionTOTO16: '2 digit pertama atau 2 digit terakhir dari Hadiah Pertama',
    introductionTOTO17: 'Star ToTo 6/50 (Hadiah setiap pertaruhan RM 1)',
    introductionTOTO18: 'Pilih 6 nombor dari 1 - 50 (Nombor tidak boleh diulang dalam setiap pilihan)',
    introductionTOTO19: 'Letakkan taruhan minimum RM1',
    introduction4DLowest1188k: '(min of RM 1,188,888)',
    introductionTOTO20: 'Memadankan semua 6 nombor',
    introductionTOTO21: 'Memadankan sebarang 5 nombor + Nombor Bonus',
    introductionTOTO22: 'Memadankan sebarang 5 nombor',
    introductionTOTO23: 'Sesuai dengan 4 nombor + Nombor Bonus',
    introductionTOTO24: 'Memadankan sebarang 4 nombor',
    introductionTOTO25: 'Sesuai dengan 3 nombor + Nombor Bonus',
    introductionTOTO26: 'Memadankan sebarang 3 nombor',
    introductionTOTO27: '* Sekiranya terdapat lebih dari satu pemenang, Jackpot akan dibahagikan di antara pemenang berdasarkan jumlah pertaruhan setiap pemenang diletakkan.',
    introductionTOTO28: 'Power ToTo 6/55 (Hadiah setiap pertaruhan RM 1)',
    introductionTOTO29: 'Pilih 6 nombor dari 1 - 55 (Nombor tidak boleh diulang dalam setiap pilihan)',
    introductionTOTO30: 'Letakkan taruhan minimum RM1',
    introductionTOTO31: 'Memadankan semua 6 nombor',
    introductionTOTO32: 'Memadankan sebarang 5 nombor',
    introductionTOTO33: 'Memadankan sebarang 4 nombor',
    introductionTOTO34: 'Memadankan sebarang 3 nombor',
    introductionTOTO35: '* Sekiranya terdapat lebih dari satu pemenang, Jackpot akan dibahagikan di antara pemenang berdasarkan jumlah pertaruhan setiap pemenang diletakkan.',
    introduction4DLowest3m: '(min of RM 3,000,000)',
    introductionTOTO36: 'Supreme ToTo 6/58 (Hadiah setiap pertaruhan RM 2)',
    introductionTOTO37: 'Pilih 6 nombor dari 1 - 58 (Nombor tidak boleh diulang dalam setiap pilihan)',
    introductionTOTO38: 'Letakkan taruhan minimum sebanyak RM2',
    introduction4DLowest888k: '(min of RM 8,888,888)',
    introductionSG1: '4D ( untuk setiap pembelian SGD1 )',
    introductionSG2: '4D iBet ( untuk setiap pembelian SGD1 )',
    introductionSG3: 'Dalam iBet, anda menang apabila SETIAP permutasi nombor 4D anda sepadan dengan salah satu nombor yang menang.',
    introductionSG4: 'Peluang lebih banyak memihak kepada anda, dan pembayaran berbeza mengikut jumlah permutasi.',
    introductionSG5: 'iBet 24',
    introductionSG6: 'iBet 12',
    introductionSG7: 'iBet 6',
    introductionSG8: 'iBet 4',
    introductionSG9: 'Toto 6/49 ( untuk setiap pembelian SGD1 )',
    introductionSG10: 'Pilih 6 nombor dari 1 - 49 (Nombor tidak boleh diulang dalam setiap pilihan)',
    introductionSG11: 'Letakkan jumlah pertaruhan minimum SGD 1',
    introductionSG12: 'Memadankan semua 6 nombor',
    introductionSG13: 'Memadankan 5 nombor ditambah dengan nombor tambahan',
    introductionSG14: 'Memadankan sebarang 5 nombor',
    introductionSG15: 'Dipadankan dengan 4 nombor ditambah dengan nombor tambahan',
    introductionSG16: 'Memadankan sebarang 4 nombor',
    introductionSG17: 'Memadankan 3 nombor ditambah nombor tambahan',
    introductionSG18: 'Memadankan sebarang 3 nombor',
    introductionSG19: '38% kumpulan hadiah',
    introductionSG20: '8% kumpulan hadiah',
    introductionSG21: '5.5% kumpulan hadiah',
    introductionSG22: '3% kumpulan hadiah',
    introductionSG23: '* Kolam Jackpot Dijamin sekurang-kurangnya SGD 1,000,000.',
    introductionSG24: '* Sekiranya terdapat lebih daripada satu bahagian yang menang, perkongsian akan sama. Ini bermaksud jumlah hadiah akan dibahagi sama di antara pemenang / saham yang menang. Perkongsian yang sama hanya berlaku untuk Kumpulan Hadiah 1, 2, 3 dan 4.',
    introductionSBH1: 'Lotto 6/45 (Hadiah setiap pertaruhan RM 1)',
    introductionSBH2: 'Pilih 6 nombor dari 1 hingga 45 (Nombor tidak boleh diulang dalam setiap pilihan)',
    introduction4DLowest500k: '(min of RM 500,000)',
    introduction4DLowest5k: '(min of RM 5,000)',
    secondp: '2ND',
    thirdp: '3RD',
    introductionBt4Jp1: '* Setiap resit hanya terhad sebanyak 30 nombor. setiap nombor yang bertaruh sekurang-kurang RM2 besar hanya layak untuk memenangi satu.',
    introductionBt4Jp2: '* iBox dan 3D tidak dikira dalam permainan Little, setiap resit hanya boleh menang SATU hadiah Little Fortune sahaja.',
    introductionBt4Jp3: 'Dua Nombor Special ATAU Dua Nombor Consolation',
    introductionBt4Jp4: 'SALAH Dua Nombor Daripada Result',
    introductionBt4Jp5: 'RM168,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp6: 'RM118,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp7: 'RM68,000 + PROTON X50 FLAGSHIP',
    introductionBt4Jp8: '* Jika terdapat pertikalan dalam permainan Little Fortune ini syarikat BOOMSTOWN berhak untuk membuat keputusan terakhir.',
    introductionBt4Jp9: 'MINI FORTUNE JACKPOT',
    introductionBt4Jpjj1: 'GANJARAN EMAS',
    introductionBt4Jpjj2: '* 13 petak dari "Special Prize" akan ditanda A-M, kami pilih A sebagai Ganjaran.',
    introductionBt4Jpjj3: '* Jika nombor yang anda beli di petak A dan kena "1st prize", "2nd prize" atau "3rd prize", anda akan menang satu telefon bimbit bernama.',
    introductionBt4Jpjj4: '* Setiap nombor minimum hendak beli RM2(Besar)',
    introductionBt4Jpjj5: '* ibox dan 3D tidak layak untuk permainan ini',
    introductionBt4Jpjj6: 'TELEFON YANG AKAN DIHADIAH: SAMSUNG GALAXY A71, REALME 7 PRO, XIAOMI MI 10T, OPPO RENO 4',
  },
}

exports.language = language
  