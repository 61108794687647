import React, { Component } from 'react';
import { connect } from 'react-redux'
import Sabah88 from '../../Images/sabah88.png';
import { language } from '../../language';
import Moment from 'moment'
import { filterJackpotNumber } from '../../utility/filterJackpotNumber';

class sbhJackCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const totosta = [1,2,3,4,5,6,7,8]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="sabahborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={Sabah88} alt="Logo" className="logoimages"/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white' }}><b>{language[this.props.currentLanguage].sabah} 88</b>
            </div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.sbhlotto.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div className="numberbordergorjackport2" style={{ marginBottom: 10 }}>
          <div className="dcc border-bottom border-dark-solid" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold', padding: '8px' }}>
            {
              totosta
                ? totosta.map((item, index) => {
                  return index === 6 ? <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 3)'}}>+</div> : <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 3)'}}>{this.props.sbhlotto[item] || '----' }</div>
                })
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 3)' }}>----</div>)
            }
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold', padding: '8px', borderradius: '5px' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 2)', borderradius: '5px', height: '35px' }}>{language[this.props.currentLanguage].Jackpot} :</div>
            <div className="dcc border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px'}}>RM {filterJackpotNumber(this.props.sbhlotto.jackpot1) || '----'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold', padding: '8px', borderradius: '5px' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 2)', height: '35px' }}>{language[this.props.currentLanguage].Jackpot} :</div>
            <div className="dcc border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 1)', height: '35px' }}>RM {filterJackpotNumber(this.props.sbhlotto.jackpot2) || '----'}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(sbhJackCard)