const base = process.env.REACT_APP_SERVER
// const base = 'https://ktl-testing.herokuapp.com/';

function getDataByDate(date) {
  return new Promise((resolve) => {
    fetch(`${base}result4D/byDate`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ date }),
    }).then((res) => {
      if (res.status === 429) {
        resolve({ error: 'type2'})
      } else if (res.status !== 200) {
        resolve({})
      } else {
        resolve(res.json())
      }
    })
  })
}

function get5DDataByDate(date) {
  return new Promise((resolve) => {
    fetch(`${base}result5D6D/byDate`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ date }),
    }).then((res) => {
      if (res.status === 429) {
        resolve({ error: 'type2'})
      } else if (res.status !== 200) {
        resolve({})
      } else {
        resolve(res.json())
      }
    })
  })
}

function getByKeyword(number) {
  return new Promise((resolve) => {
    fetch(`${base}result4D/byKeyword`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ number }),
    }).then((res) => {
      resolve(res.json())
    })
  })
}

function getByStatistic(number, showPermutation) {
  return new Promise((resolve) => {
    fetch(`${base}result4D/byStatistic`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        number, 
        showPermutation,
      }),
    }).then((res) => {
      resolve(res.json())
    })
  })
}

exports.getDataByDate = getDataByDate
exports.getByKeyword = getByKeyword
exports.get5DDataByDate = get5DDataByDate
exports.getByStatistic = getByStatistic
