import React, { Component } from 'react';
import { connect } from 'react-redux'
import magnumlogo from '../../Images/magnum.png';
import { language } from '../../language';
import Moment from 'moment';
import { isMobile } from 'react-device-detect';

class MagnumJackCards extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const no1 = this.props.magjg[1]
    const no2 = this.props.magjg[2]
    const no3 = this.props.magjg[3]
    const no4 = this.props.magjg[4]
    const no5 = this.props.magjg[5]
    const no6 = this.props.magjg[6]
    const no7 = this.props.magjg[7]
    const no8 = this.props.magjg[8]
    const Special = [1,2,3,4,5,6,7,8]
    const number9 = [9]
    const number10 = [10]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="magnumborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={magnumlogo} alt="Logo" className={`logomagnumimages ${isMobile && 'mobileCardLogo'}`}/>
            </div>
            <div className={`textalignmen3t ${isMobile && 'mobileTextAlign'}`} style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
              <b>
                {language[this.props.currentLanguage].magnum}
                <br />
                {language[this.props.currentLanguage].GoldJackpotResult}
              </b>
            </div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.magjg.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div className="numberbordergorjackport" style={{ marginBottom: 10 }}>
          <div className="dcc" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px' }}>{language[this.props.currentLanguage].first}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>{no1 || '-'}{no2}{no3}{no4}{no5}{no6} + {no7 || '-'}{no8}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px' }}>{language[this.props.currentLanguage].second}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{no2 || '-'}{no3}{no4}{no5}{no6} + {no7 || '-'}{no8}</div>
              <div>or </div>
              <div>{no1 || '-'}{no2}{no3}{no4}{no5} + {no7 || '-'}{no8}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px'}}>{language[this.props.currentLanguage].third}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{no1 || '-'}{no2}{no3}{no4}{no5}{no6}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px' }}>{language[this.props.currentLanguage].fourth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{no1 || '-'}{no2}{no3}{no4}{no5}</div>
              <div>or </div>
              <div>{no2 || '-'}{no3}{no4}{no5}{no6}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{no3 || '-'}{no4}{no5}{no6}</div>
              <div>or </div>
              <div>{no1 || '-'}{no2}{no3}{no4}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px' }}>{language[this.props.currentLanguage].sixth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{no4 || '-'}{no5}{no6}</div>
              <div>or </div>
              <div>{no1 || '-'}{no2}{no3}</div>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 10, border: '5px', fontWeight: 'bold'  }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#fff23e', height: '35px'}}>{language[this.props.currentLanguage].seventh}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', justifyContent: 'space-evenly', fontWeight: 'bold' }}>
              <div>{no1 || '-'}{no2}</div>
              <div>or </div>
              <div>{no3 || '-'}{no4}</div>
              <div>or </div>
              <div>{no5 || '-'}{no6}</div>
            </div>
          </div>
        </div>
        <div className="magnumborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={magnumlogo} alt="Logo" className={`logomagnumimages ${isMobile && 'mobileCardLogo'}`}/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)'}}><b>{language[this.props.currentLanguage].MagnumLive}</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.magl.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc bordermagnumclass" style={{ width: 'calc(100% / 1)', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].winningnumber}
          </div>
        </div>
        <div className="numberbordergorjackport2" style={{ marginBottom: 30 }}>
          <div className="dcc border-bottom border-dark-solid" style={{ display: 'flex', marginBottom: 2, padding: '8px', fontWeight: 'bold' }}>
            {
              Special
                ? Special.map((item, index) => <div className="number5Dborderdspecon" key={index} style={{ width: 'calc(100% / 8)', padding: '8px' }}>{this.props.magl[item] || '----' }</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 8)' }}>----</div>)
            }
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', padding: '8px', fontWeight: 'bold' }}>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px' }}>
              <span style={{ marginRight: 30 }}>{language[this.props.currentLanguage].specialNumber} : </span> 
            </div>
            {
              number9
                ? number9.map((item, index) => 
                  <div key={index} className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px' }}>
                    {this.props.magl[item] || '----'}
                  </div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 1)' }}>----</div>)
            }
            {
              number10
                ? number10.map((item, index) => <div key={index} className="dcc border-left border-dark-ridge number5Dborderdspecon" style={{ width: 'calc(100% / 2)', borderradius: '5px', height: '35px'}}>{this.props.magl[item] || '----'}</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 1)' }}>----</div>)
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(MagnumJackCards)
