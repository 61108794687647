import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import MainPage from './Page/MainPage';
import Footer from './Component/Footer';
import Dictionary from './Page/Dictionary';
import PastResultClass from './Page/PastResultClass';
import NavBar from './Component/NavBar';
import jackpot from './Page/jackpot';
import FourDHistory from './Page/FourDHistory';
import Introduction from './Page/Introduction';
import PrivacyPolicy from './Page/PrivacyPolicy';
import TermsAndConditions from './Page/TermsAndConditions';
import SpecialDate from './Page/SpecialDate';
// import halo from './Page/halo';

function App() {
  return (
    <div>
      <Router style={{ position: 'relative' }}>
        <NavBar/>
        <div className="App">
          <div style={{ height: 58 }}></div>
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/MainPage" component={MainPage} />
            <Route exact path="/Dictionary" component={Dictionary} />
            <Route exact path="/PastResultClass" component={PastResultClass} />
            <Route exact path="/FourDHistory" component={FourDHistory} />
            <Route exact path="/jackpot" component={jackpot} />
            <Route exact path="/Introduction" component={Introduction} />
            <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
            <Route exact path="/TermsAndConditions" component={TermsAndConditions} />
            <Route exact path="/SpecialDate" component={SpecialDate} />
            {/* <Route exact path="/halo" component={halo} /> */}
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
