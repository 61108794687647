import React from 'react';
import { HiHome } from '@react-icons/all-files/hi/HiHome';
import { GiWhiteBook } from '@react-icons/all-files/gi/GiWhiteBook';
import { FaSearchDollar } from '@react-icons/all-files/fa/FaSearchDollar';
import { BiBarChart } from '@react-icons/all-files/bi/BiBarChart';
import { FaAward } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux'
import { language } from '../language';
import homelogo2 from '../Images/homelogo2.png';
import { isMobile } from 'react-device-detect';

class NavBar extends React.Component {
  render() {
    return (
      <nav className="navbardesign navbar navbar-expand-lg navbar-dark bg-dark shadow-sm">
        <img src={homelogo2} alt="Logo" className={`Headerlogo ${isMobile && 'mobileHeaderlogo'}`}/>
        <a className="navbar-brand" href="#"></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="/MainPage" style={{ fontSize: 18 }}>
                <HiHome size="20px" color="white" className="logohome" /> {language[this.props.currentLanguage].home}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/jackpot" style={{ fontSize: 18 }}>
                <FaAward size="20px" color="white" className="logohome" /> {language[this.props.currentLanguage].fDsD}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/PastResultClass" style={{ fontSize: 18 }}>
                <FaSearchDollar size="20px" color="white" className="logohome" /> {language[this.props.currentLanguage].pastResult}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/FourDHistory" style={{ fontSize: 18 }}>
                <BiBarChart size="20px" color="white" className="logohome" /> {language[this.props.currentLanguage].fourDhistory}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Dictionary" style={{ fontSize: 18 }}>
                <GiWhiteBook size="20px" color="white" className="logohome" /> {language[this.props.currentLanguage].dictionary}
              </a>
            </li>
            <li className="nav-item dropdown" style={{ fontSize: 18 }}>
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {language[this.props.currentLanguage].more}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="nav-link" href="/Introduction" style={{ fontSize: 18, color: 'black', minWidth: 200, textAlign: 'center' }}>
                  {language[this.props.currentLanguage].introduction}
                </a>
                <a className="nav-link" href="/SpecialDate" style={{ fontSize: 18, color: 'black', minWidth: 200, textAlign: 'center' }}>
                  {language[this.props.currentLanguage].specialDate}
                </a>
              </div>
            </li>
          </ul>
          <Form.Group className="languagePicker">
            <Form.Label style={{ fontSize: 20 }}>{language[this.props.currentLanguage].language}</Form.Label>
            <Form.Control as="select" onChange={(e) => this.props.setLanguage(e.target.value)}>
              <option value="cn" selected={this.props.currentLanguage === 'cn'}>中文</option>
              <option value="en" selected={this.props.currentLanguage === 'en'}>English</option>
              <option value="Malay" selected={this.props.currentLanguage === 'Malay'}>Malay</option>
            </Form.Control>
          </Form.Group>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
