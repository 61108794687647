import '../App.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSistrix } from '@react-icons/all-files/fa/FaSistrix';
import { GiWhiteBook } from '@react-icons/all-files/gi/GiWhiteBook';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import { language } from '../language';
import MagnumIcon from '../Images/Dictionary/Iconmagnum.png';
import DamacaiIcon from '../Images/Dictionary/Icondamacai.png';
import TotoIcon from '../Images/Dictionary/IconSportToto.png';
import KeyMagnumDictionary from '../Component/Dictionary/KeywordMagnumDictionary'
import KeyDamacaiDictionary from '../Component/Dictionary/KeywordDamacaiDictionary'
import KeyTotoDictionary from '../Component/Dictionary/KeywordTotoDictionary'
import { getByKeyword } from '../api'
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

class Dictionary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      result: [],
      errorM: '',
      // backg: true,
      showResult: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.filterPlatform = this.filterPlatform.bind(this);
    this.renderDictionaryImage = this.renderDictionaryImage.bind(this);
  }

  async handleSubmit() {
    let result = await getByKeyword(this.state.keyword)
    if (result.length > 0) {
      this.state.errorM = "";
      result = this.filterPlatform(result)
      this.setState({ result })
      this.setState({ showResult: true })
    } else {
      this.state.errorM = <div className="dcc numberborderPastforerror" style={{ marginBottom: 30, marginLeft: 50, marginRight: 50 }}>{language[this.props.currentLanguage].NoResultFound}</div>;
      this.setState({ result: [] })
      this.setState({ showResult: false })
    }
  }

  handleChange(value, field) {
    this.setState({[field]: value});
  }

  filterPlatform(data) {
    let dataCopy = data
    if (!this.state.showMAG) {
      dataCopy = dataCopy.filter((item) => item.item.type !== 'magnum')
    }
    if (!this.state.showDMC) {
      dataCopy = dataCopy.filter((item) => item.item.type !== 'damacai')
    }
    if (!this.state.showTOTO) {
      dataCopy = dataCopy.filter((item) => item.item.type !== 'toto')
    }
    return dataCopy
  }

  renderLogo(platform) {
    if (platform === 'magnum') {
      return <img src={MagnumIcon} alt="Logo" className="Diclogoimages1"/>
    } else if (platform === 'damacai') {
      return <img src={DamacaiIcon} alt="Logo" className="Diclogoimages1"/>
    } else if (platform === 'toto') {
      return <img src={TotoIcon} alt="Logo" className="Diclogoimages1"/>
    } 
  }

  renderDictionaryImage(imageName) {
    return <img src={`/Image/${imageName}`} alt="Logo" className="Hislogoimages"/>
  }

  renderClass(platform) {
    if (platform === 'magnum') {
      return 'Dicmagnumborder'
    } else if (platform === 'damacai') {
      return 'Dicdamacaiborder'
    } else if (platform === 'toto') {
      return 'Dictotoborder'
    } 
  }

  componentDidMount() {
    const { data } = this.props.location
    if (data && data !== '----') {
      this.setState({ keyword: data}, () => {
        this.handleSubmit()
      })
    }
  }

  // changeColor(){
  //   this.setState({backg: !this.state.backg})
  // }
  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("searchButton").click()
    }
  }

  render() {
    const EMessage = this.state.errorM;
    // let background_images = this.state.backg ? "backgroundImage4" : "backgroundImage";
    return (
      <div className="backgroundImage4" style={{ paddingTop: 25, paddingBottom: 80 }}>
        <div style={{ display: 'flex', marginBottom: 10, marginLeft: 50, marginRight: 50, padding: 10, paddingTop: 20, fontSize: '20px' }}>
          <div className = "dcc jackpotborder" style={{ width: 'calc(100% / 1)', color: 'white' }}>
            <GiWhiteBook size="25px" color="white" className="logohome" />{language[this.props.currentLanguage].dictionary}
          </div>
        </div>
        <div className={`numberborderPast ${isMobile && `numberborderPastMobile`}`} style={{ marginBottom: 0, fontWeight: 'bold', marginLeft: '5%', marginRight: '5%' }}>
          <div className={`dcc ${isMobile && 'mobileHeaderDicMobile'}`} style={{ display: 'flex', fontWeight: 'bold', width: '50%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div className="row" style={{ margin: 20 }}>
              <input
                style={{ textAlign: 'center' }} 
                value={this.state.keyword}
                onChange={(e) => this.handleChange(e.target.value, 'keyword')}
                placeholder={language[this.props.currentLanguage].SearchforKeywordsorNumber} 
                onKeyPress={(e) => this.onKeyEnter(e)}
              />
              <button className="dcc DictionaryButtonborder" id="searchButton" onClick={this.handleSubmit}><FaSistrix  size="20px" color="black"/></button>
            </div>
            <div className="dcc" style={{ width: '90%', flexDirection: 'column' }}>
              <div style={{ marginBottom: 10 }}>{language[this.props.currentLanguage].source} ：</div>
              <div className="dcc">
                <Checkbox
                  inputProps={{ 'aria-label': 'primary checkbox'}}
                  color={ 'primary' }
                  value={this.state.showMAG} 
                  defaultChecked={this.state.showMAG}
                  onChange={(e) => this.handleChange(e.target.checked, 'showMAG')}
                />
                <img src={MagnumIcon} alt="Logo" className="Diclogoimages"/>
                <Checkbox
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color={ 'primary' }
                  value={this.state.showDMC} 
                  defaultChecked={this.state.showDMC}
                  onChange={(e) => this.handleChange(e.target.checked, 'showDMC')}
                />
                <img src={DamacaiIcon} alt="Logo" className="Diclogoimages"/>
                <Checkbox
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  color={ 'primary' }
                  value={this.state.showTOTO} 
                  defaultChecked={this.state.showTOTO}
                  onChange={(e) => this.handleChange(e.target.checked, 'showTOTO')}
                />
                <img src={TotoIcon} alt="Logo" className="Diclogoimages"/>
              </div>
            </div>
          </div>
        </div>
        <div style={{ color: 'red', fontWeight: 'bold', paddingTop: 20 }}> {EMessage} </div>
        {(this.state.showResult && this.state.result.length < 4) && 
          <div style={{ width: '100%'}}>
            <div style={{ display: 'flex', marginBottom: 10, marginLeft: 50, marginRight: 50 }}>
              <div className = "dcc Dictionaryborder" style={{ width: 'calc(100% / 1)', color: 'white' }}>
                {language[this.props.currentLanguage].NumberDictionary}
              </div>
            </div>
            <div className="dcc numberborderDic" style={{ marginBottom: 30, fontWeight: 'bold', marginLeft: 50, marginRight: 50 }}>
              <div className="row" style={{ display: 'flex', padding: '8px', fontWeight: 'bold', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {this.state.result.map((item, index) => {
                  return (
                    <div className={`row mobileHeaderDic ${isMobile && 'mobileHeaderDicMobile'}`} style={{ margin: 20 }} key={index}>
                      <div className={`dcc Dictionarytypeborder ${this.renderClass(item.item.type)} ${isMobile && 'Dictionarytypebordermobile' }`} style={{ padding: 5 }}>
                        {this.renderLogo(item.item.type)}
                        {language[this.props.currentLanguage][item.item.type]}
                      </div> 
                      <div style={{ padding: 5 }}></div>
                      <div className={`numberborderDic2 ${isMobile && 'mobileHeaderDictionary'}`}>
                        {item.item.number}
                        <div style={{ padding: 10 }}>{this.renderDictionaryImage(item.item.img)}</div>
                        <div style={{ padding: 10 }}>{item.item.cn}</div>
                        <div style={{ padding: 10 }}>{item.item.en}</div>
                        <div style={{ padding: 10  }}><button className="datePickerButton"><Link style={{ color: 'black', textDecoration: 'none' }}  to={{ pathname: "/FourDHistory", data: item.item.number }}>查看中奖记录</Link></button></div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        }
        {(this.state.showResult && this.state.result.length >= 4) && 
          <div>
            <KeyMagnumDictionary result={this.state.result}/>
            <KeyDamacaiDictionary result={this.state.result}/>
            <KeyTotoDictionary result={this.state.result}/>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(Dictionary)
