import React, { Component } from 'react';
import { connect } from 'react-redux'
import GrandDragonLotto from '../../Images/GrandDragonLotto.png';
import { language } from '../../language';
import Moment from 'moment'

class gdJackCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // const Special = [1,2,3,4,5,6,7,8,9,10]
    const D1 = '6D1'
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="draganborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={GrandDragonLotto} alt="Logo" className="logoimages"/>
            </div>
            <div className="dcc textalignmen3t" style={{ width: 'calc(100% / 3)', color: 'white' }}><b>{language[this.props.currentLanguage].GdToto} GD Lotto 6D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', color: 'white' }}>{this.props.gd6.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold', color: 'white' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div className="numberbordergorjackport" style={{ marginBottom: 30 }}>
          <div className="dcc" style={{ display: 'flex', marginBottom: 2 }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#c1272d', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].first}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>{(this.props.gd6 && this.props.gd6[D1]) ? this.props.gd6[D1] : '--- ---'}</div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#c1272d', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].second}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>
              {(this.props.gd6 && this.props.gd6[D1]) 
                ? <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                  <span>{this.props.gd6[D1].substring(0, 5)}</span>
                  <span>or</span>
                  <span>{this.props.gd6[D1].substring(1, 6)}</span>
                </div>
                : '----- or -----'}
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#c1272d', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].third}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>
              {(this.props.gd6 && this.props.gd6[D1]) 
                ? <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                  <span>{this.props.gd6[D1].substring(0, 4)}</span>
                  <span>or</span>
                  <span>{this.props.gd6[D1].substring(2, 6)}</span>
                </div>
                : '----- or -----'}
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#c1272d', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].fourth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>
              {(this.props.gd6 && this.props.gd6[D1]) 
                ? <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                  <span>{this.props.gd6[D1].substring(0, 3)}</span>
                  <span>or</span>
                  <span>{this.props.gd6[D1].substring(3, 6)}</span>
                </div>
                : '----- or -----'}
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div className="dcc americaborder5D2" style={{ width: 'calc(100% / 2)', borderradius: '5px', backgroundColor: '#c1272d', height: '35px', color: 'white' }}>{language[this.props.currentLanguage].fifth}</div>
            <div className="dcc number5Dborderdspecon" style={{ width: 'calc(100% / 1)', borderradius: '5px', height: '35px', fontWeight: 'bold' }}>
              {(this.props.gd6 && this.props.gd6[D1]) 
                ? <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                  <span>{this.props.gd6[D1].substring(0, 2)}</span>
                  <span>or</span>
                  <span>{this.props.gd6[D1].substring(4, 6)}</span>
                </div>
                : '----- or -----'}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(gdJackCard)
