import '../App.css';
import React from 'react';
import Moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { connect } from 'react-redux'
import { language } from '../language';
import { isMobile } from 'react-device-detect';
import MagnumCard from '../Component/5DCards/MagnumJackCards'
// import MagnumCard2 from '../Component/5DCards/MagnumJackCards2'
import DamacaiCard from '../Component/5DCards/DamacaiJackCard'
import TotoCard from '../Component/5DCards/TotoJackCard'
// import TotoCard3 from '../Component/5DCards/TotoJackCard3'
// import TotoCard4 from '../Component/5DCards/TotoJackCard4'
// import TotoCard5 from '../Component/5DCards/TotoJackCard5'
import Bt4Card from '../Component/5DCards/bt4JackCard'
import GdCard from '../Component/5DCards/gdJackCard'
import SbhCard from '../Component/5DCards/sbhJackCard'
import { FaAward } from 'react-icons/fa';
import { get5DDataByDate } from '../api'
import io from 'socket.io-client';
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { Link } from 'react-router-dom';
import { FaSistrix } from '@react-icons/all-files/fa/FaSistrix';
import totoLogo from '../Images/toto.png';
import MagnumLogo from '../Images/magnumlogo.png';
import Damacailogo from '../Images/damacaimobile.png';
import Bt4logo from '../Images/bt4DG1mobile.png';
import GdLotto from '../Images/GrandDragonLotto.png';
import sabah88 from '../Images/sabah88.png';

var timer

class Jackpot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dmc6: {},
      magl: {},
      magjg: {},
      toto5: {},
      toto6: {},
      totopow: {},
      totosta: {},
      totosup: {},
      sg4: {},
      bt5: {},
      sbhlotto: {},
      gd6: {},
      number: '',
      curTime: Moment().format('YYYYMMDD'),
      date: new Date(),
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      refreshing: false,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showBT4: true,
      showGD: true,
      showSBH: true,
    }
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.getStatistic = this.getStatistic.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleResult = this.toggleResult.bind(this);
  }

  async componentDidMount() {
    await this.renderOpenDay()
    const text = this.state.curTime
    const initialDate = Moment(this.state.date).format('YYYYMMDD')
    const data = await get5DDataByDate(initialDate)
    if (data) {
      this.setState({ dmc6: data.dmc6 || {} })
      this.setState({ magl: data.magl || {} })
      this.setState({ magjg: data.magjg || {} })
      this.setState({ toto5: data.toto5 || {} })
      this.setState({ toto6: data.toto6 || {} })
      this.setState({ totopow: data.totopow || {} })
      this.setState({ totosta: data.totosta || {} })
      this.setState({ totosup: data.totosup || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt5: data.bt5 || {} })
      this.setState({ sbhlotto: data.sbhlotto || {} })
      this.setState({ gd6: data.gd6 || {} })
    } else {
      this.setState({ dmc6: {} })
      this.setState({ magl: {} })
      this.setState({ magjg: {} })
      this.setState({ toto5: {} })
      this.setState({ toto6: {} })
      this.setState({ totopow: {} })
      this.setState({ totosta: {} })
      this.setState({ totosup: {} })
      this.setState({ sg4: {} })
      this.setState({ bt5: {} })
      this.setState({ sbhlotto: {} })
      this.setState({ gd6: {} })
    }
    const socket = io(process.env.REACT_APP_WS_SERVER)
    // socket.on('connect', () => {
    //   socket.emit('join', 'result5DListener')
      
    // })
    socket.on('result5DListener', (data) => {
      if (this.state.curTime >= Moment(this.state.date).startOf('day').format('YYYYMMDD') && data && data[this.state.curTime]) {
        this.setState({ dmc6: data[this.state.curTime].dmc6 || {} })
        this.setState({ magl: data[this.state.curTime].magl || {} })
        this.setState({ magjg: data[this.state.curTime].magjg || {} })
        this.setState({ toto5: data[this.state.curTime].toto5 || {} })
        this.setState({ toto6: data[this.state.curTime].toto6 || {} })
        this.setState({ totopow: data[this.state.curTime].totopow || {} })
        this.setState({ totosta: data[this.state.curTime].totosta || {} })
        this.setState({ totosup: data[this.state.curTime].totosup || {} })
        this.setState({ sg4: data[this.state.curTime].sg4 || {} })
        this.setState({ bt5: data[this.state.curTime].bt5 || {} })
        this.setState({ sbhlotto: data[this.state.curTime].sbhlotto || {} })
        this.setState({ gd6: data[this.state.curTime].gd6 || {} })
      }
    })
  }

  renderOpenDay() {
    const special2021 = [
      '20210126',
      '20210202', '20210209', '20210216',
      '20210302', '20210330',
      '20210406',
      '20210525',
      '20210615', '20210629',
      '20210706', '20210727',
      '20210803', '20210824',
      '20210907', '20210928',
      '20211005', '20211026',
      '20211102', '20211130',
      '20211207', '20211228',
    ]
    if (Moment().tz('Asia/Kuala_Lumpur').hour() <= 18) {
      let dayOfWeekMoment = Moment().subtract(1, 'days').tz('Asia/Kuala_Lumpur')
      for(let i = 6 ; i > 0; i -= 1) {
        let dayOfWeek = dayOfWeekMoment.day()
        if (dayOfWeek === 0 || dayOfWeek === 3 || dayOfWeek === 6) {
          break
        }
        if (special2021.includes(dayOfWeekMoment.format('YYYYMMDD'))) {
          break
        }
        dayOfWeekMoment = dayOfWeekMoment.subtract(1, 'days').tz('Asia/Kuala_Lumpur')
      }
      this.setState({ date: dayOfWeekMoment.toDate()})
    }
  }

  async setSelectedDate(date) {
    this.setState({ date })
    const dateDB = Moment(date).format('YYYYMMDD')
    const data = await get5DDataByDate(dateDB)
    if (data) {
      this.setState({ dmc6: data.dmc6 || {} })
      this.setState({ magl: data.magl || {} })
      this.setState({ magjg: data.magjg || {} })
      this.setState({ toto5: data.toto5 || {} })
      this.setState({ toto6: data.toto6 || {} })
      this.setState({ totopow: data.totopow || {} })
      this.setState({ totosta: data.totosta || {} })
      this.setState({ totosup: data.totosup || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt5: data.bt5 || {} })
      this.setState({ gd6: data.gd6 || {} })
      this.setState({ sbhlotto: data.sbhlotto || {} })
    } else {
      this.setState({ dmc6: {} })
      this.setState({ magl: {} })
      this.setState({ magjg: {} })
      this.setState({ toto5: {} })
      this.setState({ toto6: {} })
      this.setState({ totopow: {} })
      this.setState({ totosta: {} })
      this.setState({ totosup: {} })
      this.setState({ sg4: {} })
      this.setState({ bt5: {} })
      this.setState({ gd6: {} })
      this.setState({ sbhlotto: {} })
    }
  }

  async changeYesterday() {
    const yesterday = Moment(this.state.date).subtract(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeTomorrow() {
    const yesterday = Moment(this.state.date).add(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async refreshData() {
    const dateDB = Moment(this.state.date).format('YYYYMMDD')
    this.setState({ refreshing: true })
    const data = await get5DDataByDate(dateDB)
    if (data.error) {
      this.openNotification(data.error)
      this.setState({ notificationType: 'error' });
    } else if (data) {
      this.setState({ dmc6: data.dmc6 || {} })
      this.setState({ magl: data.magl || {} })
      this.setState({ magjg: data.magjg || {} })
      this.setState({ toto5: data.toto5 || {} })
      this.setState({ toto6: data.toto6 || {} })
      this.setState({ totopow: data.totopow || {} })
      this.setState({ totosta: data.totosta || {} })
      this.setState({ totosup: data.totosup || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt5: data.bt5 || {} })
      this.setState({ gd6: data.gd6 || {} })
      this.setState({ sbhlotto: data.sbhlotto || {} })
      this.setState({ notificationType: 'success' });
      this.openNotification('refreshed')
    }
    this.setState({ refreshing: false })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: language[this.props.currentLanguage][message] });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 3000);
  };

  handleChange(value, field) {
    this.setState({[field]: value});
  }

  getStatistic() {
    let result = this.state.number;
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("searchButton").click()
    }
  }
  
  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  render() {
    const currentDateFormat = Moment(this.state.date).format('YYYYMMDD')
    const numberOfCurrentDay = Number(Moment(this.state.date).tz('Asia/Kuala_Lumpur').format('d'))
    const yesterdayDay = numberOfCurrentDay - 1
    const tomorrowDay = numberOfCurrentDay + 1
    const bt5 = '5D1'
    const numberInput = this.state.number
    return (
      <div className="App backgroundImage" style={{ paddingTop: 25 }}>
        <div style={{ position: 'absolute', right: 20, top: 20 }}>
          <Alert show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <div style={{ display: 'flex', margin: '10px 30px', paddingTop: 10 }}>
          <div className ={`dcc jackpotborder ${isMobile && 'jackpotborder'}`} style={{ width: 'calc(100% / 1)', color: 'white', fontSize: '20px' }}>
            <FaAward size="25px" color="white" className="logohome" />5D/6D {language[this.props.currentLanguage].Jackpot}
          </div>
        </div>
        <div className={`dcc row ${isMobile && 'mobileHeader' }`} style={{ padding: 20, width: '100%', justifyContent: 'space-between' }}>
          <div className="dcc">
            <button className="dcc datePickerButton" onClick={() => this.changeYesterday()}>
              <FaArrowCircleLeft size="16px" color="#000" />
              <div className={`${isMobile && 'mobiledatefont'}`} style={{ marginLeft: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[yesterdayDay === -1 ? 6 : yesterdayDay]}
              </div>
            </button>
            <DatePicker
              onChange = {(date) => this.setSelectedDate(date)}
              dateFormat = 'dd-MMM-yyyy'
              placeholder="Date"
              maxDate={new Date()}
              selected={this.state.date}
              popperPlacement="top-right"
            />
            <button className="dcc datePickerButton" onClick={() => this.changeTomorrow()}>
              <div className={`${isMobile && 'mobiledatefont'}`} style={{ marginRight: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[tomorrowDay === 7 ? 0 : tomorrowDay]}
              </div>
              <FaArrowCircleRight size="16px" color="#000" />
            </button>
          </div>
          {
            this.state.refreshing
              ? <Spinner animation="border" />
              : <button className={`dcc refreshButton ${isMobile && 'mobileRefreshButton'}`} onClick={() => this.refreshData()}>
                {language[this.props.currentLanguage].refresh}
              </button>
          }
          <div className="dcc">
            <div style={{ marginRight: 5, color: 'white' }}>{language[this.props.currentLanguage].findNumber}: </div>
            <input
              style={{ textAlign: 'center' }}
              value={this.state.number}
              onChange={(e) => this.handleChange(e.target.value, 'number')}
              placeholder={'1234 / Tiger / 老虎'}
              onKeyPress={(e) => this.onKeyEnter(e)}
            />
            <button className="dcc DictionaryButtonborder22"><Link id="searchButton" to={{ pathname: "/Dictionary", data: numberInput || '----' }}><FaSistrix size="20px" color="black" onClick={this.getStatistic}/></Link></button>
          </div>
        </div>
        <div className={`d-flex justify-content-center px-3 ${isMobile && 'mobileToggleBar'}`}>
          <div className="row" style={{ justifyContent: 'center' }} align="left">
            <img src={MagnumLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showMAG && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('MAG')}/>
            <img src={Damacailogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showDMC && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('DMC')}/>
            <img src={totoLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showTOTO && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('TOTO')}/>
            <img src={Bt4logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showBT4 && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('BT4')}/>
            <img src={GdLotto} alt="Logo" className={`mainPageToggleLogo ${this.state.showGD && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('GD')}/>
            <img src={sabah88} alt="Logo" className={`mainPageToggleLogo ${this.state.showSBH && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SBH')}/>
          </div>
        </div>
        <div className="d-flex justify-content-center px-3">
          <div className={`row Marginout ${isMobile && 'mobileCardContainer'}`} style={{ width: '100%', marginTop: 30 }}>
            {
              (this.state.showMAG && (this.state.magjg.drawNo ||this.state.magl.drawNo || currentDateFormat >= this.state.curTime)) && <MagnumCard magjg={this.state.magjg} magl={this.state.magl} date={this.state.date}/>
            }
            {
              (this.state.showDMC && (this.state.dmc6.drawNo || currentDateFormat >= this.state.curTime)) && <DamacaiCard dmc6={this.state.dmc6} date={this.state.date}/>
            }
            {
              (this.state.showGD && (this.state.gd6 && this.state.gd6['6D1'] || currentDateFormat >= this.state.curTime)) && <GdCard gd6={this.state.gd6} date={this.state.date}/>
            }
            {
              (this.state.showBT4 && (this.state.bt5[bt5] || currentDateFormat >= this.state.curTime)) && <Bt4Card bt5={this.state.bt5} date={this.state.date}/>
            }
            {
              (this.state.showSBH && (this.state.sbhlotto.drawNo || currentDateFormat >= this.state.curTime)) && <SbhCard sbhlotto={this.state.sbhlotto} date={this.state.date}/>
            }
            {
              (this.state.showTOTO && (this.state.toto5.drawNo || this.state.toto6.drawNo || this.state.totosta.drawNo || 
                this.state.totopow.drawNo || this.state.totosup.drawNo || currentDateFormat >= this.state.curTime)) 
              && <TotoCard toto5={this.state.toto5} toto6={this.state.toto6} totosta={this.state.totosta} totopow={this.state.totopow} totosup={this.state.totosup} date={this.state.date}/>
            }
            {/* {
              (this.state.showTOTO && (this.state.totosta.drawNo || currentDateFormat >= this.state.curTime)) && <TotoCard3 totosta={this.state.totosta} date={this.state.date}/>
            }
            {
              (this.state.showTOTO && (this.state.totopow.drawNo || currentDateFormat >= this.state.curTime)) && <TotoCard4 totopow={this.state.totopow} date={this.state.date}/>
            }
            {
              (this.state.showTOTO && (this.state.totosup.drawNo || currentDateFormat >= this.state.curTime)) && <TotoCard5 totosup={this.state.totosup} date={this.state.date}/>
            } */}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(Jackpot)
