import '../App.css';
import React from 'react';
import Moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { connect } from 'react-redux'
import { language } from '../language';
import MagnumCard from '../Component/4DCards/MagnumCard'
import DamacaiCard from '../Component/4DCards/DamacaiCard'
import TotoCard from '../Component/4DCards/TotoCard'
import Sg4Card from '../Component/4DCards/Sg4Card'
import Bt4Card from '../Component/4DCards/bt4Card'
import GdCard from '../Component/4DCards/gdCard'
import SbhCard from '../Component/4DCards/sbhCard'
import SdkCard from '../Component/4DCards/sdkCard'
import SwkCard from '../Component/4DCards/swkCard'
import { getDataByDate } from '../api'
import io from 'socket.io-client';
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import { isMobile } from 'react-device-detect';
import totoLogo from '../Images/toto.png';
import MagnumLogo from '../Images/magnumlogo.png';
import Damacailogo from '../Images/damacaimobile.png';
import Sglogo from '../Images/singaporePool.png';
import Bt4logo from '../Images/bt4DG1mobile.png';
import GdLotto from '../Images/GrandDragonLotto.png';
import SdkLogo from '../Images/sandakan.png';
import SwkLogo from '../Images/sarawak.png';
import sabah88 from '../Images/sabah88.png';
import modal2 from '../Images/modal2.png';
import Banner from '../Images/Banner.png';
import closeSVG from '../Images/close.svg';
import { FaSistrix } from '@react-icons/all-files/fa/FaSistrix';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

var timer

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dmc: {},
      mag: {},
      toto: {},
      sg4: {},
      bt4: {},
      gd: {},
      sdk: {},
      sbh: {},
      swk: {},
      testing: '',
      number: '',
      curTime: Moment().format('YYYYMMDD'),
      date: new Date(),
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      refreshing: false,
      showMAG: true,
      showDMC: true,
      showTOTO: true,
      showSG: true,
      showBT4: true,
      showGD: true,
      showSTC: true,
      showSBH: true,
      showSWK: true,
      modalShow: false,
    }
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.getStatistic = this.getStatistic.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const opened = window.sessionStorage.getItem("opened")
    if (!opened) {
      this.setState({ modalShow: true })
    }
    await this.renderOpenDay()
    const initialDate = Moment(this.state.date).format('YYYYMMDD')
    const data = await getDataByDate(initialDate)
    if (data) {
      this.setState({ dmc: data.dmc || {} })
      this.setState({ mag: data.mag || {} })
      this.setState({ toto: data.toto || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt4: data.bt4 || {} })
      this.setState({ gd: data.gd || {} })
      this.setState({ sdk: data.sdk || {} })
      this.setState({ sbh: data.sbh || {} })
      this.setState({ swk: data.swk || {} })
    } else {
      this.setState({ dmc: {} })
      this.setState({ mag: {} })
      this.setState({ toto: {} })
      this.setState({ sg4: {} })
      this.setState({ bt4: {} })
      this.setState({ gd: {} })
      this.setState({ sdk: {} })
      this.setState({ sbh: {} })
      this.setState({ swk: {} })
    }
    const socket = io(process.env.REACT_APP_WS_SERVER)
    // socket.on('connect', () => {
    //   socket.emit('join', 'resultListener')
    // })
    socket.on('resultListener', (data) => {
      if (this.state.curTime >= Moment(this.state.date).startOf('day').format('YYYYMMDD') && data && data[this.state.curTime]) {
        this.setState({ dmc: data[this.state.curTime].dmc || {} })
        this.setState({ mag: data[this.state.curTime].mag || {} })
        this.setState({ toto: data[this.state.curTime].toto || {} })
        this.setState({ sg4: data[this.state.curTime].sg4 || {} })
        this.setState({ bt4: data[this.state.curTime].bt4 || {} })
        this.setState({ gd: data[this.state.curTime].gd || {} })
        this.setState({ sdk: data[this.state.curTime].sdk || {} })
        this.setState({ sbh: data[this.state.curTime].sbh || {} })
        this.setState({ swk: data[this.state.curTime].swk || {} })
      }
    })
  }

  renderOpenDay() {
    const special2021 = [
      '20210126',
      '20210202', '20210209', '20210216',
      '20210302', '20210330',
      '20210406',
      '20210525',
      '20210615', '20210629',
      '20210706', '20210727',
      '20210803', '20210824',
      '20210907', '20210928',
      '20211005', '20211026',
      '20211102', '20211130',
      '20211207', '20211228',
    ]
    if (Moment().tz('Asia/Kuala_Lumpur').hour() <= 17) {
      let dayOfWeekMoment = Moment().subtract(1, 'days').tz('Asia/Kuala_Lumpur')
      for (let i = 6; i > 0; i -= 1) {
        let dayOfWeek = dayOfWeekMoment.day()
        if (dayOfWeek === 0 || dayOfWeek === 3 || dayOfWeek === 6) {
          break
        }
        if (special2021.includes(dayOfWeekMoment.format('YYYYMMDD'))) {
          break
        }
        dayOfWeekMoment = dayOfWeekMoment.subtract(1, 'days').tz('Asia/Kuala_Lumpur')
      }
      this.setState({ date: dayOfWeekMoment.toDate() })
    }
  }

  async setSelectedDate(date) {
    this.setState({ date })
    const dateDB = Moment(date).format('YYYYMMDD')
    const data = await getDataByDate(dateDB)
    if (data) {
      this.setState({ dmc: data.dmc || {} })
      this.setState({ mag: data.mag || {} })
      this.setState({ toto: data.toto || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt4: data.bt4 || {} })
      this.setState({ gd: data.gd || {} })
      this.setState({ sdk: data.sdk || {} })
      this.setState({ sbh: data.sbh || {} })
      this.setState({ swk: data.swk || {} })
    } else {
      this.setState({ dmc: {} })
      this.setState({ mag: {} })
      this.setState({ toto: {} })
      this.setState({ sg4: {} })
      this.setState({ bt4: {} })
      this.setState({ gd: {} })
      this.setState({ sdk: {} })
      this.setState({ sbh: {} })
      this.setState({ swk: {} })
    }
  }

  async changeYesterday() {
    const yesterday = Moment(this.state.date).subtract(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async changeTomorrow() {
    const yesterday = Moment(this.state.date).add(1, 'days').toDate()
    this.setState({ date: yesterday })
    this.setSelectedDate(yesterday)
  }

  async refreshData() {
    const dateDB = Moment(this.state.date).format('YYYYMMDD')
    this.setState({ refreshing: true })
    const data = await getDataByDate(dateDB)
    if (data.error) {
      this.openNotification(data.error)
      this.setState({ notificationType: 'error' });
    } else if (data) {
      this.setState({ dmc: data.dmc || {} })
      this.setState({ mag: data.mag || {} })
      this.setState({ toto: data.toto || {} })
      this.setState({ sg4: data.sg4 || {} })
      this.setState({ bt4: data.bt4 || {} })
      this.setState({ gd: data.gd || {} })
      this.setState({ sdk: data.sdk || {} })
      this.setState({ sbh: data.sbh || {} })
      this.setState({ swk: data.swk || {} })
      this.setState({ notificationType: 'success' });
      this.openNotification('refreshed')
    }
    this.setState({ refreshing: false })
  }

  openNotification(message) {
    this.setState({ showNotification: true });
    this.setState({ notificationMessage: language[this.props.currentLanguage][message] });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ showNotification: false });
      this.setState({ notificationMessage: '' });
    }, 3000);
  };

  toggleResult(platform) {
    this.setState({
      [`show${platform}`]: !this.state[`show${platform}`],
    })
  }

  // renderContent = () => {
  //   if (isMobile === true) {
  //     'mobileHeader'
  //   }
  // }
  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  getStatistic() {
    let result = this.state.number;
    console.log(result)
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      document.getElementById("searchButton").click()
    }
  }

  async REsultSend() {
    let PlatformData = {
      Sendmag: '',
      Senddmc: '',
      Sendtoto: '',
      Sendsg4: '',
    }
    const ANR = 'Check More Result : https://ace4d.live/';
    const Date = `\n\nDate: ${Moment(this.state.date).format('MMDD')}`
    let ResultPlatfrom = ''
    const PlatformNeed = ['mag', 'dmc', 'toto', 'sg4']
    
    PlatformNeed.forEach((item) => {
      PlatformData[`Send${item}`] = `\n ${item === 'mag' ? '=M=' : item === 'dmc' ? '=K=' :  item === 'toto' ? '=T=' : item === 'bt4' ? '=8=' : item === 'sg4' ? '=S=' : item === 'sdk' ? '=D=' : item === 'sbh' ? '=B=' : item === 'gd' ? '=GD=' : '=W='} \n 1) ${this.state[item].P1 || 'XXXX'} \n 2) ${this.state[item].P2 || 'XXXX'} \n 3) ${this.state[item].P3 || 'XXXX'} ` 
      +  `\n 4) \n ${this.state[item][1] || 'XXXX'} ${this.state[item][2] || 'XXXX'} ${this.state[item][3] || 'XXXX'} ${this.state[item][4] || 'XXXX'} ${this.state[item][5] || 'XXXX'}`
      + `\n ${this.state[item][6] || 'XXXX'} ${this.state[item][7] || 'XXXX'} ${this.state[item][8] || 'XXXX'} ${this.state[item][9] || 'XXXX'} ${this.state[item][10] || 'XXXX'} \n`
      + ` ${this.state[item][11] || 'XXXX'} ${this.state[item][12] || 'XXXX'} ${this.state[item][13] || 'XXXX'} \n`
      + `5) \n ${this.state[item][14] || 'XXXX'} ${this.state[item][15] || 'XXXX'} ${this.state[item][16] || 'XXXX'} ${this.state[item][17] || 'XXXX'} ${this.state[item][18] || 'XXXX'}`
      + `\n ${this.state[item][19] || 'XXXX'} ${this.state[item][20] || 'XXXX'} ${this.state[item][21] || 'XXXX'} ${this.state[item][22] || 'XXXX'} ${this.state[item][23] || 'XXXX'}`
    })
    // console.log('ANR', ANR)
    // console.log('Date', Date)
    ResultPlatfrom = `${ANR}${Date}${PlatformData.Sendmag}\n${PlatformData.Senddmc}\n${PlatformData.Sendtoto}\n${PlatformData.Sendsg4}`
    // console.log('ResultPlatfrom', ResultPlatfrom)
    navigator.clipboard.writeText(ResultPlatfrom)
    this.setState({ notificationType: 'success' });
    this.openNotification('CopySuccess')
  }

  CopyToClipcoard() {
    this.REsultSend()
  }

  render() {
    const currentDateFormat = Moment(this.state.date).format('YYYYMMDD')
    const numberOfCurrentDay = Number(Moment(this.state.date).tz('Asia/Kuala_Lumpur').format('d'))
    const yesterdayDay = numberOfCurrentDay - 1
    const tomorrowDay = numberOfCurrentDay + 1
    const inputNumber = this.state.number
    return (
      <div className="App backgroundImage" style={{ paddingTop: 25, minHeight: 800 }}>
        <div style={{ position: 'absolute', right: 20, top: 100 }}>
          <Alert show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
            <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
          </Alert>
        </div>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShow}
          contentClassName="br23"
        >
          <Modal.Body style={{ padding: 10, width: '100%', backgrondColor: null }}>
            <a target="_blank" href="https://ace4d.live/SpecialDate">
              <img src={Banner} alt="Modal1" style={{ width: '100%', height: '100%' }} />
            </a>
            <img src={closeSVG} alt="Close" style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer', width: isMobile ? 24 : 48, height: isMobile ? 24 : 48, backgroundColor: '#fff', boxShadow: '0px 0px 5px' }} onClick={() => {
              this.setState({ modalShow: false }) 
              window.sessionStorage.setItem("opened", true);
            }} />
          </Modal.Body>
        </Modal>
        <div className={`dcc row ${isMobile && 'mobileHeader'}`} style={{ padding: 20, width: '100%', justifyContent: 'space-between' }}>
          <div className="dcc">
            <button className="dcc datePickerButton" onClick={() => this.changeYesterday()}>
              <FaArrowCircleLeft size="16px" color="#000" />
              <div className={`${isMobile && 'mobiledatefont'}`} style={{ marginLeft: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[yesterdayDay === -1 ? 6 : yesterdayDay]}
              </div>
            </button>
            <DatePicker
              onChange={(date) => this.setSelectedDate(date)}
              dateFormat='dd-MMM-yyyy'
              placeholder="Date"
              maxDate={new Date()}
              // style="position:relative"
              popperPlacement="top-right"
              selected={this.state.date}
            />
            <button className="dcc datePickerButton" onClick={() => this.changeTomorrow()}>
              <div className={`${isMobile && 'mobiledatefont'}`} style={{ marginRight: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[tomorrowDay === 7 ? 0 : tomorrowDay]}
              </div>
              <FaArrowCircleRight size="16px" color="#000" />
            </button>
          </div>
          {
            this.state.refreshing
              ? <Spinner animation="border" />
              : <button className={`dcc refreshButton ${isMobile && 'mobileRefreshButton'}`} onClick={() => this.refreshData()}>
                {language[this.props.currentLanguage].refresh}
              </button>
          }
          <div className="dcc">
            <div style={{ marginRight: 5, color: 'white' }}>{language[this.props.currentLanguage].findNumber}: </div>
            <input
              style={{ textAlign: 'center' }}
              value={this.state.number}
              onChange={(e) => this.handleChange(e.target.value, 'number')}
              placeholder={'1234 / Tiger / 老虎'}
              onKeyPress={(e) => this.onKeyEnter(e)}
            />
            <button className="dcc DictionaryButtonborder22"><Link id="searchButton" to={{ pathname: "/Dictionary", data: inputNumber || '----' }}><FaSistrix size="20px" color="black" onClick={this.getStatistic} /></Link></button>
          </div>
        </div>
        <div className={`d-flex justify-content-center px-3 ${isMobile && 'mobileToggleBar'}`} style={{ justifyContent: 'space-between'}}>
          <div className="row" style={{ justifyContent: 'center' }} align="left">
            <img src={MagnumLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showMAG && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('MAG')} />
            <img src={Damacailogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showDMC && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('DMC')} />
            <img src={totoLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showTOTO && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('TOTO')} />
            <img src={Sglogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSG && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SG')} />
            <img src={Bt4logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showBT4 && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('BT4')} />
            <img src={GdLotto} alt="Logo" className={`mainPageToggleLogo ${this.state.showGD && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('GD')} />
            <img src={SdkLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSTC && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('STC')} />
            <img src={sabah88} alt="Logo" className={`mainPageToggleLogo ${this.state.showSBH && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SBH')} />
            <img src={SwkLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSWK && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SWK')} />
          </div>
        </div>
        <div className="d-flex justify-content-center px-3">
          <div className={`row Marginout ${isMobile && 'mobileCardContainer'}`} style={{ width: '100%', marginTop: 30 }}>
            {
              (this.state.showMAG && (this.state.mag.P1 || currentDateFormat >= this.state.curTime)) && <MagnumCard mag={this.state.mag} date={this.state.date} />
            }
            {
              (this.state.showDMC && (this.state.dmc.P1 || currentDateFormat >= this.state.curTime)) && <DamacaiCard dmc={this.state.dmc} date={this.state.date} />
            }
            {
              (this.state.showTOTO && (this.state.toto.P1 || currentDateFormat >= this.state.curTime)) && <TotoCard toto={this.state.toto} date={this.state.date} />
            }
            {
              (this.state.showSG && (this.state.sg4.P1 || currentDateFormat >= this.state.curTime)) && <Sg4Card sg4={this.state.sg4} date={this.state.date} />
            }
            {
              (this.state.showBT4 && (this.state.bt4.P1 || currentDateFormat >= this.state.curTime)) && <Bt4Card bt4={this.state.bt4} date={this.state.date} />
            }
            {
              (this.state.showGD && (this.state.gd.P1 || currentDateFormat >= this.state.curTime)) && <GdCard gd={this.state.gd} date={this.state.date} />
            }
            {
              (this.state.showSTC && (this.state.sdk.P1 || currentDateFormat >= this.state.curTime)) && <SdkCard sdk={this.state.sdk} date={this.state.date} />
            }
            {
              (this.state.showSBH && (this.state.sbh.P1 || currentDateFormat >= this.state.curTime)) && <SbhCard sbh={this.state.sbh} date={this.state.date} />
            }
            {
              (this.state.showSWK && (this.state.swk.P1 || currentDateFormat >= this.state.curTime)) && <SwkCard swk={this.state.swk} date={this.state.date} />
            }
          </div>
          <div className="dcc" style={{ paddingBottom: 30, cursor: 'pointer' }}>
            <div class="scrollDesign button2"  
              onClick={() => this.CopyToClipcoard()}>
              {/* ↑ ⇈ */}
              <div style={{ fontSize: '30px'}}>📄</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(MainPage)
