import React, { Component } from 'react';
import { connect } from 'react-redux'
import bt4dg512logo from '../../Images/bt4dg512-logo_D1.png';
import LiveLogo from '../../Images/Live.gif';
import { language } from '../../language';
import Moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { characterList } from '../../static';
import { filterJackpotNumber } from '../../utility/filterJackpotNumber';

class bt4Card extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const prices1 = this.props.bt4.P1
    const prices2 = this.props.bt4.P2
    const prices3 = this.props.bt4.P3
    const Special = [1,2,3,4,5,6,7,8,9,10,11,12,13]
    const consolation = [14,15,16,17,18,19,20,21,22,23]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="americaborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)', justifyContent: 'center' }}>
              <img src={bt4dg512logo} alt="Logo" className={`logomagnumimages ${isMobile && 'mobileCardLogo2'}`}/>
            </div>
            <div className="textalignmen3t" style={{ width: 'calc(100% / 3)', color: 'white', position: 'relative' }}>
              <b>
                {language[this.props.currentLanguage].america}
                {Moment().hours() >= 19 && <Link target="_blank" to={{ pathname: "https://bt4dg.net/"}} >
                  <img src={LiveLogo} style={{ position: 'absolute', top: 0, right: -30, width: 60, height: 30}} alt="Live" />
                </Link>}
              </b>
              <div><b>BT4DG 4D</b></div>
            </div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{Moment(this.props.date).format('MMDD/YY')}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className="dcc americaborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].first}
          </div>
          <div className="dcc americaborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, color: 'white', fontWeight: 'bold'}}>
            {language[this.props.currentLanguage].second}
          </div>
          <div className="dcc americaborderclass" style={{ width: 'calc(100% / 3)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].third}
          </div>
        </div>
        <div className="numberborder" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            {/* <div style={{ width: 'calc(100% / 3)', borderradius: '5px' }}>{this.props.bt4.P1 || '----'}</div> */}
            <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }} to={{ pathname: "/FourDHistory", data: this.props.bt4.P1 || '----' }} >
              {this.props.bt4.P1OriPosition && <span style={{ color: 'red', position: 'absolute', top: -6, left: 3, fontSize: '14px' }}>{this.props.bt4.P1OriPosition}</span>}
              {this.props.bt4.P1 || '----'}
            </Link>
            <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }} to={{ pathname: "/FourDHistory", data: this.props.bt4.P2 || '----' }} >
              {this.props.bt4.P2OriPosition && <span style={{ color: 'red', position: 'absolute', top: -6, left: 3, fontSize: '14px' }}>{this.props.bt4.P2OriPosition}</span>}
              {this.props.bt4.P2 || '----'}
            </Link>
            <Link style={{ width: 'calc(100% / 3)', borderradius: '5px', color: 'black', textDecoration: 'none', position: 'relative' }} to={{ pathname: "/FourDHistory", data: this.props.bt4.P3 || '----' }} >
              {this.props.bt4.P3OriPosition && <span style={{ color: 'red', position: 'absolute', top: -6, left: 3, fontSize: '14px' }}>{this.props.bt4.P3OriPosition}</span>}
              {this.props.bt4.P3 || '----'}
            </Link>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc americaborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].special}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              Special
                ? Special.map((item, index) => {
                  let itemList = [<Link key={index} style={{ width: 'calc(100% / 5)', padding: '8px', position: 'relative', color: 'black', textDecoration: 'none' }}
                    to={{ pathname: "/FourDHistory", data: this.props.bt4[item] || '----' }} >
                    <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '14px' }}>{characterList[item]}</span>
                    {this.props.bt4[item] || '----' }
                  </Link>]
                  if (index === 10) {
                    itemList.unshift(<div key={'14'} style={{ width: 'calc(100% / 5)', padding: '8px' }}>
                      {' '}
                    </div>)
                  }
                  return itemList
                })
                : [...Array(13)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc americaborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].consolation}
          </div>
        </div>
        <div className="numberborderdspecon" style={{ marginBottom: 30, fontWeight: 'bold' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              consolation
                ? consolation.map((item, index) => <Link key={index} style={{ width: 'calc(100% / 5)', position: 'relative', padding: '8px', color: 'black', textDecoration: 'none' }}
                  to={{ pathname: "/FourDHistory", data: this.props.bt4[item] || '----' }} >
                  <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '14px' }}>{characterList[item]}</span>
                  {this.props.bt4[item] || '----' }
                </Link>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(bt4Card)
