const initialState = {
  currentLanguage: 'cn',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LANGUAGE':
    // debugger
      localStorage.setItem('currentLanguage', action.payload)
      return {
        ...state,
        currentLanguage: action.payload,
      }
    default: return state
  }
}

export default reducer
