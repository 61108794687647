import React, { Component } from 'react';
import { connect } from 'react-redux'
import damacai from '../../Images/damacai.png';
import Pig from '../../Images/Testing/DamacaiZodiacPig.png';
import Horse from '../../Images/Testing/horse.png';
import Ox from '../../Images/Testing/ox.png';
import Goat from '../../Images/Testing/goat.png';
import Dragon from '../../Images/Testing/dragon.png';
import Rabbit from '../../Images/Testing/rabbit.png';
import Snake from '../../Images/Testing/snake.png';
import Tiger from '../../Images/Testing/tiger.png';
import Rat from '../../Images/Testing/Rat.png';
import Dog from '../../Images/Testing/dog.png';
import Monkey from '../../Images/Testing/monkey.png';
import Rooster from '../../Images/Testing/rooster.png';
import { language } from '../../language';
import Moment from 'moment';
import { isMobile } from 'react-device-detect';

class DamacaiJackCard extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    // this.filterPlatform = this.filterPlatform.bind(this);
  }

  renderLogo(platform) {
    if (platform === 'PIG') {
      return <img src={Pig} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'TIGER') {
      return <img src={Tiger} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'SNAKE') {
      return <img src={Snake} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'OX') {
      return <img src={Ox} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'RAT') {
      return <img src={Rat} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'GOAT') {
      return <img src={Goat} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'HORSE') {
      return <img src={Horse} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'DRAGON') {
      return <img src={Dragon} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'RABBIT') {
      return <img src={Rabbit} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'MONKEY') {
      return <img src={Monkey} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'DOG') {
      return <img src={Dog} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    } else if (platform === 'ROOSTER') {
      return <img src={Rooster} alt="Logo" className={`Animallogo ${isMobile && 'smallerAnimallogo'}`}/>
    }
  }

  render() {
    const damacaispecial = [7,8,9,10,11,12,13,14,15,16]
    const damacaiconsolation = [17,18,19,20,21,22,23,24,25,26]
    const onetwothree = [1,2,3]
    const animal = [4,5,6]
    return (
      <div className="sec-title col-sm-12 col-md-4 px-3">
        <div className="damacaiborder" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px' }}>
            <div style={{ width: 'calc(100% / 3)' }}>
              <img src={damacai} alt="Logo" className={`logomagnumimages ${isMobile && 'mobileCardLogo2'}`}/>
            </div>
            <div className="textalignment" style={{ width: 'calc(100% / 3)', color: 'white' }}><b>{language[this.props.currentLanguage].damacai} 3+3D</b></div>
            <div className="textalignment2" style={{ width: 'calc(100% / 3)', color: 'white' }}>
              <div style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>{this.props.dmc6.drawNo || '---/--'}</div>
              <div style={{ fontSize: 12, fontWeight: 'bold' }}>{Moment(this.props.date).format('DD-MMM-YYYY (ddd)')}</div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10, color: 'white' }}>
          <div className="dcc damacaiborderclass " style={{ width: 'calc(100% / 3)', marginLeft: 4, fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].first}
          </div>
          <div className="dcc damacaiborderclass" style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].second}
          </div>
          <div className="dcc damacaiborderclass" style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].third}
          </div>
        </div>
        <div className="numberborder2" style={{ marginBottom: 10 }}>
          <div style={{ display: 'flex', marginBottom: 2, border: '5px', fontWeight: 'bold' }}>
            {
              onetwothree
                ? onetwothree.map((item, index) => <div className="border" key={index} style={{ width: 'calc(100% / 3)' }}>{this.props.dmc6[item] || '--- ---' }</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 3)' }}>----</div>)
            }
          </div>
          <div className="number5Dborderdspecon" style={{ display: 'flex', marginBottom: 2, fontWeight: 'bold'}}>
            {
              animal
                ? animal.map((item, index) => <div className="border" key={index} style={{ width: 'calc(100% / 3)', padding: '10px 2px' }}>
                  {this.renderLogo(this.props.dmc6[item] || '----') }
                  {this.props.dmc6[item] || '----'}
                </div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 3)' }}>----</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc damacaiborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].special}
          </div>
        </div>
        <div className="number5Dborderdspecon" style={{ marginBottom: 10, fontWeight: 'bold', overflow: 'hidden' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              damacaispecial
                ? damacaispecial.map((item, index) => <div className={`border number6Dborderdspecon`} key={index} style={{ width: 'calc(100% / 5)', padding: '10px 2px' }}>{this.props.dmc6[item] || '--- ---' }</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>--- ---</div>)
            }
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: 10 }}>
          <div className = "dcc damacaiborderclass" style={{ width: 'calc(100% / 1)', color: 'white', fontWeight: 'bold' }}>
            {language[this.props.currentLanguage].consolation}
          </div>
        </div>
        <div className="number5Dborderdspecon" style={{ marginBottom: 30, fontWeight: 'bold', overflow: 'hidden' }}>
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            {
              damacaiconsolation
                ? damacaiconsolation.map((item, index) => <div className={`border number6Dborderdspecon`} key={index} style={{ width: 'calc(100% / 5)', padding: '10px 2px' }}>{this.props.dmc6[item] || '--- ---' }</div>)
                : [...Array(10)].map((item, index) => <div key={index} style={{ width: 'calc(100% / 5)' }}>----</div>)
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
  }
}

export default connect(mapStateToProps, null)(DamacaiJackCard)
