import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux'
import reducer from './reducers/reducer'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore(
  reducer, {
    currentLanguage: localStorage.getItem('currentLanguage') || 'cn',
  }
)

ReactDOM.render(<Provider store={store}> <App/> </Provider>, document.getElementById('root'));

reportWebVitals();
