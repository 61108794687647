const language = {
  en: {
    home: 'Ace 4D Live',
    pastResult: 'Past Result',
    dictionary: 'Dictionary',
    fDsD: '5D/6D Jackpot',
    more: 'More: ',
    language: 'Language',
    findNumber: 'Find Number',
    daysOfWeek: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ],
    JEstimated: 'Jackpot Estimated Amount',
    JEstimated2: 'Jackpot Pool',
    Jackpot: 'Jackpot',
    sarawak: 'Sarawak Cashsweep',
    sabah: 'Sabah88',
    sandakan: 'Sandakan',
    GdToto: 'Grand Dragon Lotto',
    america: 'America',
    singapore: 'Singapore',
    toto: 'SportsToto',
    damacai: 'Damacai',
    magnum: 'Magnum',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Four',
    fifth: 'Fifth',
    sixth: 'Six',
    seventh: 'Seven',
    special: 'Special',
    consolation: 'Consolation',
    winningnumber: 'Winning Number',
    specialNumber: 'Special Number',
    StarToto: 'Star Toto',
    PowerToto: 'Power Toto',
    SupremeToto: 'Supreme Toto',
    recentdata: 'Recent data (1 week)',
    PleaseSelectedate: 'Select Date to show past result',
    date: 'Date',
    SearchforKeywordsorNumber: 'Search for keywords or numbers',
    source: 'Source',
    menu: 'Home',
    fourDhistory: '4D History',
    pemutation: 'Pemutation',
    GoldJackpotResult: 'Gold Jackpot Result',
    MagnumLive: 'Magnum Live',
    menu1: 'Menu',
    ContactUs: 'Contact Us',
    NumberDictionary: 'Number Dictionary',
    introduction: '4D Introduction & Payout Structure',
    History: '4D History',
    fourDWinTimes: 'Number of Times Winning 4D',
    StraightNumHisResult: 'Straight Number History Result',
    PemutationNumHisResult: 'Permutation Number History Result',
    Platform: 'Platform', 
    fourD: '4D',
    Prize: 'Prize',
    drawDate: 'Draw Date',
    refresh: 'Refresh',
    refreshed: 'Refreshed',
    type1: 'Result not existed or haven\'t open on this date 😄',
    type2: 'Too many requests! Rest your hand. 😉',
    ErrorOnly4number: 'Warning! Please insert 4-digit number',
    NoResultFound: 'No Result Found !!!',
    specialDate: 'Special Date',
    copyText: 'Copy to ClipBoard',
    CopySuccess: 'Success Copy Data To clipboard',
  },
  cn: {
    home: 'Ace 4D Live',
    pastResult: '过往开彩',
    dictionary: '灵感号码',
    fDsD: '5D/6D Jackpot',
    more: '更多',
    language: '语言: ',
    findNumber: '寻找灵感号码',
    daysOfWeek: [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六',
    ],
    JEstimated: '预计下期万字积宝奖金',
    JEstimated2: '积宝奖金',
    Jackpot: '积宝',
    sarawak: '砂拉越大万',
    sabah: '沙巴万字',
    sandakan: '山打根赛马场',
    GdToto: '豪龙彩票',
    america: '美国',
    singapore: '新加坡',
    toto: '多多',
    damacai: '大马彩',
    magnum: '万能',
    first: '首奖',
    second: '二奖',
    third: '三奖',
    fourth: '四奖',
    fifth: '五奖',
    sixth: '六奖',
    seventh: '七奖',
    special: '特别奖',
    consolation: '安慰奖',
    winningnumber: '中奖号码',
    specialNumber: '特别号码',
    StarToto: '星运多多',
    PowerToto: '至尊多多',
    SupremeToto: '好运多多',
    recentdata: '近期',
    PleaseSelectedate: '请选择日期以显示过去的结果',
    date: '日期',
    SearchforKeywordsorNumber: '搜索关键字或数字',
    source: '来源',
    menu: '主页',
    fourDhistory: '万字数据',
    pemutation: '全保',
    GoldJackpotResult: '黃金万字积宝',
    MagnumLive: '万能天天彩',
    menu1: '菜单',
    ContactUs: '联络我们',
    NumberDictionary: '万字图',
    introduction: '万字介绍与派彩结构',
    History: '萬字數據',
    fourDWinTimes: '万字中奖次数', 
    StraightNumHisResult: '正字数据',
    PemutationNumHisResult: '全保数据',
    Platform: '商标',
    fourD: '4D',
    Prize: '奖',
    drawDate: '开彩日期',
    refresh: '刷新',
    refreshed: '刷新了',
    type1: '此日期结果不存在或尚未打开 😄',
    type2: '请求太多！ 休息一下 😉',
    ErrorOnly4number: '警告 ! 只允许输入四位数字',
    NoResultFound: '没有相关资料 !!!',
    specialDate: '特别期',
    copyText: '拷贝',
    CopySuccess: '成功拷贝数据',
  },
  Malay: {
    home: 'Ace 4D Live',
    pastResult: 'Keputusan Masa Lalu',
    dictionary: 'Kamus',
    fDsD: '5D/6D Jackpot',
    more: 'Lain: ',
    language: 'Bahasa',
    findNumber: 'Cari Nombor',
    daysOfWeek: [
      'Ahad',
      'Isnin',
      'Sel',
      'Rabu',
      'Khamis',
      'Jum',
      'Sabtu',
    ],
    JEstimated: 'Jackpot Estimated Amount',
    JEstimated2: 'Jackpot Pool',
    Jackpot: 'Jackpot',
    sarawak: 'Sarawak Cashsweep',
    sabah: 'Sabah88',
    sandakan: 'Sandakan',
    GdToto: 'Grand Dragon Lotto',
    america: 'Amerika',
    singapore: 'Singapore',
    toto: 'SportsToto',
    damacai: 'Damacai',
    magnum: 'Magnum',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'fourth',
    fifth: 'fifth',
    sixth: 'sixth',
    seventh: 'seventh',
    special: 'Special',
    consolation: 'Consolation',
    winningnumber: 'winning Number',
    specialNumber: 'Special Number',
    StarToto: 'Star Toto',
    PowerToto: 'Power Toto',
    SupremeToto: 'Supreme Toto',
    recentdata: 'Terkini',
    PleaseSelectedate: 'pilih tarikh untuk menunjukkan hasil yang lalu',
    date: 'Tarikh',
    SearchforKeywordsorNumber: 'Cari kata kunci atau nombor',
    source: 'Sumber',
    menu: 'Menu',
    fourDhistory: '4D Sejarah',
    pemutation: 'Susunan',
    GoldJackpotResult: 'Gold Jackpot Result',
    MagnumLive: 'Magnum Live',
    menu1: 'Menu',
    ContactUs: 'Hubungi Kami',
    NumberDictionary: 'Nombor Kamus',
    introduction: 'Struktur Pengenalan & Pembayaran 4D',
    History: '4D Sejarah',
    fourDWinTimes: 'Bilangan Kemenangan 4D',
    StraightNumHisResult: 'Keputusan Sejarah Nombor Lurus ',
    PemutationNumHisResult: 'Keputusan Sejarah Nombor Susunan', 
    Platform: 'Platform',
    fourD: '4D',
    Prize: 'Hadiah',
    drawDate: 'Tarikh Cabutan',
    refresh: 'Muat Semula',
    refreshed: 'Sudah Muat Semula',
    type1: 'Hasil tidak wujud atau belum dibuka pada tarikh ini. 😄',
    type2: 'Terlalu banyak permintaan! Rehatkan tangan anda. 😉',
    ErrorOnly4number: 'Amaran! Hanya empat digit yang dibenarkan',
    NoResultFound: 'Tiada Ada Recod !!!',
    specialDate: 'Tarikh Special',
    copyText: 'Tiru',
    CopySuccess: 'Berjaya Tiru Data',
  },
}

exports.language = language
